import { cloneDeep, keyBy, omit } from "lodash";
import {
  Action,
  RECEIVE_EMAIL_CAMPAIGNS,
  REMOVE_EMAIL_CAMPAIGN,
  UPDATE_EMAIL_CAMPAIGN,
  START_EDITING_EMAIL_CAMPAIGN,
  START_NEW_EMAIL_CAMPAIGN,
  CLEAR_EMAIL_CAMPAIGN,
} from "../actions/email-campaign";
import { StoreState } from "../types/email-campaign";


const initialState: StoreState = {
  // place to store edits on new (unsaved) email campaign
  newEmailCampaign: null,
  // place to store edits on existing (saved) email campaign
  // (the reason this is separate from newEmailCampaign is that
  // the page has two separate forms, one for new, one for existing,
  // each with its own state)
  editingEmailCampaign: null,
  // all the email campaigns that the application has received during its lifetime
  // keyed by id
  emailCampaigns: {},
};

export default function emailCampaign(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_EMAIL_CAMPAIGNS: {
      return {
        ...state,
        emailCampaigns: {
          ...state.emailCampaigns,
          ...keyBy(action.payload, "id"),
        },
      };
    }
    case REMOVE_EMAIL_CAMPAIGN: {
      const removeId = action.payload;
      return {
        ...state,
        emailCampaigns: omit(state.emailCampaigns, removeId),
      };
    }
    case UPDATE_EMAIL_CAMPAIGN:
      return {
        ...state,
        [action.meta.isNew ? "newEmailCampaign" : "editingEmailCampaign"]: action.payload,
      };
    case CLEAR_EMAIL_CAMPAIGN:
      return {
        ...state,
        [action.meta.isNew ? "newEmailCampaign" : "editingEmailCampaign"]: null,
      };
    case START_EDITING_EMAIL_CAMPAIGN: {
      const id = action.payload;
      const emailCampaignToEdit = state.emailCampaigns[id];
      if (!emailCampaignToEdit) {
        throw new Error("Tried to edit email campaign not in store");
      }
      if (state.editingEmailCampaign && state.editingEmailCampaign.id === id) {
        // No-op: already editing.
        // Use update action if you wish to reset state.editingEmailCampaign
        return state;
      }
      return {
        ...state,
        editingEmailCampaign: cloneDeep(emailCampaignToEdit),
      };
    }
    case START_NEW_EMAIL_CAMPAIGN:
      if (state.newEmailCampaign) {
        // alread started, no-op
        return state;
      }
      return {
        ...state,
        newEmailCampaign: {
          templateOptions: {
            include: []
          },
        },
      };
    default:
      return state;
  }
}
