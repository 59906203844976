import { connect, Dispatch } from "react-redux";
import { groupBy, memoize, omit, sortBy } from "lodash";
import { Company, Action, ActionDataCapState } from "@threatminder-system/tm-core";
import { DataCaps } from "../components/data-caps";
import { receiveCompanies } from "../actions/company";
import { receive as receiveActions, changeDataCap } from "../actions/action";
import { fetchApiRoute } from "../helpers/fetch";
import { StoreState } from "../types";


const groupActionsByCompany = memoize(actions => groupBy(actions, "companyId"));
const sortCompaniesByName = memoize(companies => sortBy(companies, "name"));
const addActionsToCompanies = (companies: Company[], actionsByCompanyId: {[id: number]: Action[]}) =>
  companies.map((co: Company) => ({...co, actions: (actionsByCompanyId[co.id] || [])}));

function mapStateToProps(state: StoreState) {
  const { auth, action, company } = state;

  const sortedCompanies = sortCompaniesByName(company.companies);
  const actionsByCompanyId = groupActionsByCompany(action.actions);
  const companiesWithActions = addActionsToCompanies(sortedCompanies, actionsByCompanyId);

  return {
    companiesWithActions,
    actionDataCapEdits: action.dataCapEdits,
    authToken: auth.authToken,
  };
}

async function saveOptimistically(
  execute: Function,
  recover: Function,
  url: string,
  body: string,
  authToken: string,
) {
  // Trigger optimistic render
  execute();
  // Try to save to API
  const response = await fetchApiRoute(url, {
    method: "PUT",
    authToken,
    body,
  });
  // If save was unsuccessful, reset to old value and alert user.
  if (!response.ok) {
    recover();
    window.alert("Unable to save. API sent back error code " + response.status + ".");
  }
}

function mapDispatchToProps(dispatch: Dispatch<any>) {

  const saveCompanyOptimistically = (company: Company, updatedCompany: Company, authToken: string) => {
    saveOptimistically(
      () => dispatch(receiveCompanies({ companies: [updatedCompany] })),
      () => dispatch(receiveCompanies({ companies: [company] })),
      `/api/v1/companies/${company.id}`,
      // Remove actions array before sending down the wire because sometimes
      // companies have a lot of actions and the data payload becomes too big
      JSON.stringify({ companies: [omit(updatedCompany, "actions")] }),
      authToken,
    );
  };

  const saveActionOptimistically = (action: Action, updatedAction: Action, authToken: string) => {
    saveOptimistically(
      () => dispatch(receiveActions({ actions: [updatedAction] })),
      () => dispatch(receiveActions({ actions: [action] })),
      `/api/v1/actions/${action.id}`,
      JSON.stringify({ actions: [updatedAction] }),
      authToken,
    );
  };

  return {
    async onChangeCompanyIsDataCapEnabled(company: Company, isDataCapEnabled: boolean, authToken: string) {
      if (company.isDataCapEnabled === isDataCapEnabled) {
        return;
      }
      const updatedCompany = { ...company, isDataCapEnabled };
      saveCompanyOptimistically(company, updatedCompany, authToken);
    },
    async onChangeActionDataCapState(action: Action, dataCapState: ActionDataCapState, authToken: string) {
      if (action.dataCapState === dataCapState) {
        return;
      }
      const updatedAction = { ...action, dataCapState };
      saveActionOptimistically(action, updatedAction, authToken);
    },
    async onSaveActionDataCap(action: Action, dataCap: number, authToken: string) {
      if (action.dataCap === dataCap) {
        return;
      }
      const updatedAction = { ...action, dataCap };
      saveActionOptimistically(action, updatedAction, authToken);
    },
    onChangeActionDataCap(action: Action, value: string) {
      dispatch(changeDataCap(action, value));
    },
  };
}

export const DataCapsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataCaps);
