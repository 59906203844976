import * as React from "react";
import Link from "redux-first-router-link";
import { uniqBy, groupBy, keyBy, sortBy as _sortBy } from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";
import { PostActionMatch } from "./post-action-match";
import { PostActionMatchQueryRequest } from "../actions/post-action-match";
import { isPostActionMatchValid } from "../helpers/post-action-match";
import { Paginator } from "./paginator";
import SetMultipleActionMatches from "./set-multiple-action-matches";
import {
  PostActionMatch as PostActionMatchType,
  Company,
  Platform,
  Action as ActionType,
  SortByEnum,
  SortByOrderEnum,
  SortByDisplayNames,
} from "@threatminder-system/tm-core";
import { Role } from "../../shared-constants";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./post-action-matches.css";

export type PamPageFilters = {
  sortBy: SortByEnum;
  sortByOrder: SortByOrderEnum;
  page: number;
  companyIds: number[];
  excludeCompany: boolean;
  actionIds: number[];
  excludeAction: boolean;
  selectedPlatformIds: number[];
};

interface Props {
  authToken: string;
  role: Role;
  posts: PostActionMatchType[];
  companies: Company[];
  platforms: Platform[];
  filters: PamPageFilters;
  actions: ActionType[];
  perPage: number;
  total: number;
  hasLoaded: boolean;
  saving: number[];
  onSubmitOne: (
    postActionMatch: PostActionMatchType,
    pq: PostActionMatchQueryRequest
  ) => Promise<void>;
  onSubmitMany: (
    postActionMatches: PostActionMatchType[],
    pq: PostActionMatchQueryRequest
  ) => Promise<void>;
  onChangeOne: (postActionMatch: PostActionMatchType) => void;
  onChangeFilters: (filters: PamPageFilters) => void;
}

export const PostActionMatches: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    authToken,
    role,
    posts,
    companies,
    platforms,
    actions,
    perPage,
    total,
    filters,
    hasLoaded,
    saving,
    onSubmitOne,
    onSubmitMany,
    onChangeOne,
    onChangeFilters,
  } = props;

  const companiesById = keyBy(companies, "id");
  const actionMap: Map<number, ActionType> = new Map<number, ActionType>(
    actions.map((x) => [x.id, x] as [number, ActionType])
  );

  const postQueryRequest: PostActionMatchQueryRequest = {
    authToken,
    page: filters.page,
    sortBy: filters.sortBy,
    sortByOrder: filters.sortByOrder,
    companyId: filters.companyIds,
    excludeCompany: filters.excludeCompany,
    actionId: filters.actionIds,
    excludeAction: filters.excludeAction,
    platformId: filters.selectedPlatformIds,
  };

  const sortBySelector = (
    <div className="form-group">
      <label className="control-label" htmlFor="sortBy-sortByType">
        Sort By
      </label>
      <select
        className="form-control"
        id="sortByType"
        name="sortBy-sortByType"
        onChange={(e) =>
          onChangeFilters({
            ...filters,
            sortBy: e.target.value as SortByEnum,
            page: 1,
          })
        }
        value={filters.sortBy}
      >
        {Object.keys(SortByEnum).map((key) => (
          <option value={key} key={key}>
            {SortByDisplayNames.get(key)}
          </option>
        ))}
      </select>
    </div>
  );

  const sortByOrderSelector = (
    <div className="form-group">
      <label className="control-label" htmlFor="sortByOrder-sortByOrderType">
        Sort Order
      </label>
      <div>
        <button
          className={
            filters.sortByOrder === SortByOrderEnum.ASC
              ? "btn btn-default active"
              : "btn btn-default"
          }
          onClick={() =>
            onChangeFilters({ ...filters, sortByOrder: SortByOrderEnum.ASC })
          }
        >
          <span className="glyphicon glyphicon-sort-by-attributes" />
        </button>
        <button
          className={
            filters.sortByOrder === SortByOrderEnum.DESC
              ? "btn btn-default active"
              : "btn btn-default"
          }
          onClick={() =>
            onChangeFilters({ ...filters, sortByOrder: SortByOrderEnum.DESC })
          }
        >
          <span className="glyphicon glyphicon-sort-by-attributes-alt" />
        </button>
      </div>
    </div>
  );

  const togglePlatform = (platformId: number, checked: boolean) => {
    const selectedPlatformIdsSet = new Set(filters.selectedPlatformIds);
    if (checked) {
      selectedPlatformIdsSet.add(platformId);
    } else {
      selectedPlatformIdsSet.delete(platformId);
    }
    onChangeFilters({
      ...filters,
      selectedPlatformIds: Array.from(selectedPlatformIdsSet),
      page: 1,
    });
  };

  const areAllPlatformsSelected =
    platforms.length > 0 &&
    platforms.length === filters.selectedPlatformIds.length;
  const filterByPlatform = (
    <div className="form-group">
      <label className="control-label" htmlFor="filterBy-filterByType">
        Filter by Platform
      </label>
      <ul className="form-control platform-filter">
        <li className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={areAllPlatformsSelected}
              onChange={() =>
                onChangeFilters({
                  ...filters,
                  selectedPlatformIds: areAllPlatformsSelected
                    ? []
                    : platforms.map(({ id }) => id),
                })
              }
            />
            Select all
          </label>
        </li>
        {platforms.map((platform) => (
          <li key={platform.id} className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={filters.selectedPlatformIds.includes(platform.id)}
                value={platform.id}
                onChange={({ target: { value: platformId, checked } }) => {
                  togglePlatform(Number(platformId), checked);
                }}
              />
              {platform.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );

  const isSavingAll = saving.length === posts.length;
  const isSavingAtLeastOne = saving.length >= 1;

  const matchesById = groupBy(posts, (pam) =>
    filters.sortBy === SortByEnum.postId ? pam.postId : pam.id
  );

  let anyInvalidOnPage = false;

  return (
    <div>
      <div className="page-header">
        <h1>Review Posts</h1>

        {role === Role.UNRESTRICTED && (
          <Link to="/review-posts-history">See history</Link>
        )}
      </div>

      <HelpText />
      <br />

      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <FilterBy<Company>
            collection={_sortBy(companies, "name")}
            exclude={Boolean(filters.excludeCompany)}
            isSelected={(company) => filters.companyIds.includes(company.id)}
            label={(company: Company) => company.name}
            onChangeExclude={(checked) =>
              onChangeFilters({ ...filters, excludeCompany: checked })
            }
            onChangeSelection={(selected) => {
              onChangeFilters({
                ...filters,
                companyIds: selected.map((company: Company) => company.id),
              });
            }}
            title="Company"
          />
          <FilterBy<ActionType>
            collection={_sortBy(actions, "targetName")}
            exclude={Boolean(filters.excludeAction)}
            isSelected={(action) => filters.actionIds.includes(action.id)}
            label={(action: ActionType) => {
              const company = companiesById[action.companyId];
              const companyName = company && company.name;
              return (
                action.targetName + (companyName ? ` (${companyName})` : "")
              );
            }}
            onChangeExclude={(checked) =>
              onChangeFilters({ ...filters, excludeAction: checked })
            }
            onChangeSelection={(selected) => {
              onChangeFilters({
                ...filters,
                actionIds: selected.map((action: ActionType) => action.id),
              });
            }}
            title="Action"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          {filterByPlatform}
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          {sortBySelector}
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          {sortByOrderSelector}
        </div>
      </div>

      <div className="posts-container">
        <div
          className={
            posts.length === 0 && hasLoaded
              ? "is-empty-result-set"
              : "is-empty-result-set hidden"
          }
        >
          <h2>
            <br />
            <br />
            No Posts Found
            <br />
            <small> Try changing your filters or page number</small>
            <br />
            <br />
            <br />
          </h2>
        </div>
        <div
          className={
            !hasLoaded ? "is-empty-result-set" : "is-empty-result-set hidden"
          }
        >
          <h2>
            <br />
            <br />
            Loading...
            <br />
            <br />
            <br />
          </h2>
        </div>

        {
          /*
          When sorting by "postId", group the action matches by post,
          otherwise render as a flat list.
          When grouping, we send the component only one post action match
          per post id, along with a list of all action matches for that
          post id. When not grouping, we send the component each post action
          match once.
          */
          (filters.sortBy === SortByEnum.postId
            ? uniqBy(posts, (pam) => pam.postId)
            : posts
          ).map((postActionMatch) => {
            const actionMatches =
              matchesById[
                filters.sortBy === SortByEnum.postId
                  ? postActionMatch.postId
                  : postActionMatch.id
              ];
            const hasInvalidActionMatch = actionMatches.some(
              (actionMatch) => !isPostActionMatchValid(actionMatch)
            );
            anyInvalidOnPage = anyInvalidOnPage || hasInvalidActionMatch;
            return (
              <PostActionMatch
                key={postActionMatch.id}
                onSubmit={(actionMatches: PostActionMatchType[]) => {
                  return actionMatches.length === 1
                    ? onSubmitOne(actionMatches[0], postQueryRequest)
                    : onSubmitMany(actionMatches, postQueryRequest);
                }}
                onChange={(p: PostActionMatchType) => onChangeOne(p)}
                post={postActionMatch.post}
                actionMatches={actionMatches}
                actionMap={actionMap}
                isSaving={saving.includes(postActionMatch.id)}
                hasInvalidActionMatch={hasInvalidActionMatch}
                companiesById={companiesById}
              />
            );
          })
        }

        {posts.length > 0 && (
          <div className="panel panel-default threatminder-post">
            <div className="panel-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" colSpan={5}>
                      &nbsp;
                    </th>
                    <th scope="col" className="threat-th">
                      <abbr title="High Risk">HR</abbr>
                    </th>
                    <th scope="col" className="x-detected-th">
                      &nbsp;&nbsp;
                    </th>
                    <th scope="col">
                      <abbr title="Sentiment Confirmed">SC</abbr>
                    </th>
                    <th scope="col" className="x-detected-th">
                      &nbsp;&nbsp;
                    </th>
                    <th scope="col">
                      <abbr title="Risk Confirmed">RC</abbr>
                    </th>
                    <th scope="col" className="threat-th">
                      &nbsp;&nbsp;
                    </th>
                    <th scope="col" className="threat-th">
                      <abbr title="Threat Confirmed">TC</abbr>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <SetMultipleActionMatches
                    key={
                      // This is a trick to clear the "save all" form.
                      // The form clears whenever the key changes, and
                      // the key changes whenever the set of posts on the
                      // page changes, which could happen as a result of
                      // navigating forwards or backwards, or by clearing
                      // the page by clicking the "save all" button.
                      posts.reduce((accum, post) => accum + post.id, "")
                    }
                    actionMatches={posts}
                    onChange={(p: PostActionMatchType) => onChangeOne(p)}
                    label="Set for page above"
                  />
                </tbody>
              </table>
              <button
                type="button"
                className="btn btn-default btn-lg pull-right"
                onClick={(evt) => {
                  for (const form of document.forms as any) {
                    if (!form.reportValidity()) {
                      return;
                    }
                  }
                  onSubmitMany(posts, postQueryRequest);
                }}
                disabled={isSavingAtLeastOne || anyInvalidOnPage}
              >
                {
                  // Note: button becomes disabled if any posts are saving
                  // but text only changes if a bulk save (> 1) is going on
                  isSavingAll ? "Saving ..." : "Save all"
                }
              </button>
              {isSavingAll && (
                <div className="saving-caption pull-right">
                  Please be patient
                </div>
              )}
            </div>
            <br />
          </div>
        )}
      </div>

      <Paginator
        page={filters.page}
        perPage={perPage}
        total={total}
        pageType="POST_ACTION_MATCHES"
        getPagePayload={(pageNum) => ({
          page: pageNum,
        })}
        getPageQuery={() => ({
          sortBy: filters.sortBy,
          sortByOrder: filters.sortByOrder,
          actionId: filters.actionIds,
        })}
      />
    </div>
  );
};

type FilterByProps<T> = {
  collection: T[];
  exclude: boolean;
  isSelected: (t: T) => boolean;
  label: (t: T) => string;
  onChangeExclude: (checked: boolean) => void;
  onChangeSelection: (selected: T[]) => void;
  title: string;
};

function FilterBy<T>(props: FilterByProps<T>) {
  const {
    collection,
    exclude,
    isSelected,
    label,
    onChangeExclude,
    onChangeSelection,
    title,
  } = props;
  type TypeaheadOption = T & { label: string };
  const typeaheadOptions: TypeaheadOption[] = collection.map((item) => ({
    ...item,
    label: label(item),
  }));
  return (
    <div>
      <label
        className="control-label"
        htmlFor={`${title}-filter-typeahead-component`}
      >
        Filter by {title}
      </label>
      <Typeahead
        id={`${title}-filter-typeahead-component`}
        placeholder="Search&hellip;"
        multiple={true}
        onChange={onChangeSelection}
        options={typeaheadOptions}
        selected={typeaheadOptions.filter(isSelected)}
      />
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            checked={exclude}
            onChange={(event) => onChangeExclude(event.currentTarget.checked)}
          />
          Exclude
        </label>
      </div>
    </div>
  );
}

class HelpText extends React.Component<{}, { shouldShowHelpText: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      shouldShowHelpText: false,
    };
    this.toggleHelpText = this.toggleHelpText.bind(this);
  }

  toggleHelpText() {
    this.setState((state) => ({
      shouldShowHelpText: !state.shouldShowHelpText,
    }));
  }

  render() {
    const { shouldShowHelpText } = this.state;
    return (
      <div className="pam-help-text">
        <span
          className={`pam-help-text-toggle glyphicon ${
            shouldShowHelpText ? "glyphicon-collapse-down" : "glyphicon-expand"
          }`}
          aria-hidden="true"
          onClick={this.toggleHelpText}
        />
        {shouldShowHelpText && (
          <p>
            Note: The filters below are combined. A post must satisfy all the
            filters together in order to appear in the results below. For
            example, if you filter by Company A and then filter by an action
            that belongs to Company B, you will get an empty set of results.
          </p>
        )}
      </div>
    );
  }
}
