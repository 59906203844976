import { RiskKeywordSets } from "../components/risk-keyword-sets";
import { connect } from "react-redux";
import { StoreState } from "../types";


function mapStateToProps({ riskKeywordSet }: StoreState) {
  return {
    riskKeywordSets: riskKeywordSet.riskKeywordSets,
  };
}

export const RiskKeywordSetsContainer = connect(
  mapStateToProps,
)(RiskKeywordSets);
