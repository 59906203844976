import { Filter } from "@threatminder-system/tm-core";
import { Filters } from "../components/filters";
import { connect, Dispatch } from "react-redux";
import { MouseEvent } from "react";
import { StoreState } from "../types";
import { blankFilter } from "../helpers/filter";
import {
  receive as receiveFilters,
  remove as removeFilter,
  updateFilter,
  startEditingFilter,
} from "../actions/filter";
import { fetchApiRoute } from "../helpers/fetch";


function mapStateToProps(state: StoreState) {
  const { newFilter, editingFilter, filters, page, perPage, total } = state.filter;
  return {
    authToken: state.auth.authToken,
    newFilter,
    editingFilter,
    filters,
    page,
    perPage,
    total,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onUpdateFilter(updatedFilter: Filter) {
      dispatch(updateFilter(updatedFilter, { isNew: !updatedFilter.id }));
    },
    onClickDeleteFilter(event: MouseEvent<HTMLElement>, authToken: string) {
      event.preventDefault();
      const yes = window.confirm("Are you sure you want to delete this filter?");
      if (!yes) {
        return;
      }
      const { currentTarget: { dataset: { id } } } = event;
      fetchApiRoute(`/api/v1/filters/${id}`, {
        method: "DELETE",
        authToken,
      }).then(response => {
        if (response.ok) {
          dispatch(removeFilter(Number(id)));
        } else {
          response.json().then(obj => {
            window.alert(`Could not delete filter:\n ${obj.error}`);
          });
        }
      });
    },
    onClickEditFilter(event: MouseEvent<HTMLElement>) {
      const { currentTarget: { dataset: { id } } } = event;
      dispatch(startEditingFilter(Number(id)));
    },
    onSubmitFilter(event: MouseEvent<HTMLElement>, filter: Filter, authToken: string) {
      event.preventDefault();
      const isNew = !filter.id;
      const body = { filters: [filter] };
      fetchApiRoute(isNew ? `/api/v1/filters` : `/api/v1/filters/${filter.id}`, {
        method: isNew ? "POST" : "PUT",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
          response.json().then(obj => {
            window.alert("Success");
            dispatch(receiveFilters(obj.filters, "MERGE"));
            dispatch(updateFilter(blankFilter(), { isNew }));
          });
        } else {
          response.json().then(obj => {
            window.alert(`Could not save filter:\n ${obj.error}`);
          });
        }
      });
    }
  };
}

export const FiltersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
