import { cloneDeep } from "lodash";
import { Platform } from "@threatminder-system/tm-core";
import {
  Action,
  RECEIVE_PLATFORMS,
  UPDATE_PLATFORM,
  START_EDITING_PLATFORM,
} from "../actions/platform";
import { StoreState } from "../types/platform";
import { receiveMerge } from "../helpers/reducers";


const initialState: StoreState = {
  // place to store edits on existing (saved) platform
  editingPlatform: {} as Platform,
  platforms: [],
};

export default function platform(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_PLATFORMS:
      return {
        ...state,
        platforms: action.meta.mergeMethod === "REPLACE" ?
          action.payload
          : receiveMerge(state.platforms, action),
      };
    case UPDATE_PLATFORM:
      return {
        ...state,
        editingPlatform: action.payload,
      };
    case START_EDITING_PLATFORM: {
      const id = action.payload;
      const platformToEdit = state.platforms.find(platform => platform.id === id);
      if (!platformToEdit) {
        throw new Error("Tried to edit platform not in store");
      }
      if (id === state.editingPlatform.id) {
        // No-op: already editing.
        // Use update action if you wish to reset state.editingPlatform
        return state;
      }
      return {
        ...state,
        editingPlatform: cloneDeep(platformToEdit),
      };
    }
    default:
      return state;
  }
}
