import * as React from "react";
import { EmailCampaign } from "@threatminder-system/tm-core";
import { EmailCampaignForm } from "./email-campaign-form";
import ReactJson from "react-json-view";

import "./email-campaigns.css";

interface Props {
  authToken: string;
  emailCampaigns: EmailCampaign[];
  newEmailCampaign: Partial<EmailCampaign> | null;
  editingEmailCampaign: EmailCampaign | null;
  onUpdateEmailCampaign: (updatedNewEmailCampaign: EmailCampaign) => void;
  onClickDeleteEmailCampaign: (id: number, authToken: string) => void;
  onClickEditEmailCampaign: (event: React.MouseEvent<HTMLElement>) => void;
  onClickResumeEmailCampaign: (id: number, authToken: string) => Promise<void>;
  onClickSuspendEmailCampaign: (id: number, authToken: string) => Promise<void>;
  onClickStartNewEmailCampaign: () => void;
  onSubmitEmailCampaign: (event: React.MouseEvent<HTMLElement>, emailCampaign: Partial<EmailCampaign>, authToken: string) => void;
}

export const EmailCampaigns: React.FunctionComponent<Props> = (props: Props) => {
  const {
    emailCampaigns,
    newEmailCampaign,
    editingEmailCampaign,
    onClickDeleteEmailCampaign,
    onClickEditEmailCampaign,
    onClickResumeEmailCampaign,
    onClickSuspendEmailCampaign,
    onClickStartNewEmailCampaign,
    onUpdateEmailCampaign,
    onSubmitEmailCampaign,
    authToken,
  } = props;

  return (
    <div>
      <div className="page-header">
        <h1>Email Campaigns</h1>
      </div>

      <div className="panel panel-default">
        <div className="panel-body">

          <div
            className="row form-container click-to-edit-user"
            onClick={newEmailCampaign ? null : onClickStartNewEmailCampaign}
          >
            <h4>Add Email Campaign</h4>
            {newEmailCampaign ? (
              <div>
                <EmailCampaignForm
                  emailCampaign={newEmailCampaign}
                  onUpdateEmailCampaign={onUpdateEmailCampaign}
                />
                <SaveButton
                  onClick={event => onSubmitEmailCampaign(event, newEmailCampaign, authToken)}
                />
              </div>
            ) : (
                <div>
                  Click to begin
                </div>
              )}

          </div>

          <div className="list-group">
            <h2>All Email Campaigns</h2>
            <div>Click on an email campaign to edit it</div>
            <br />
            {emailCampaigns.map(
              (emailCampaign) => {
                const {
                  id,
                  name,
                  isSuspended,
                } = emailCampaign;
                const isEditing = editingEmailCampaign && editingEmailCampaign.id === id;
                return (
                  <div
                    key={id}
                    className={`list-group-item click-to-edit-email-campaign ${isEditing && "is-editing-email-campaign"}`}
                    data-id={id}
                    onClick={onClickEditEmailCampaign}
                  >
                    <button
                      className="btn btn-default pull-right"
                      onClick={async (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const { currentTarget } = event;
                        currentTarget.disabled = true;
                        try {
                          if (isSuspended) {
                            await onClickResumeEmailCampaign(id, authToken);
                          } else {
                            await onClickSuspendEmailCampaign(id, authToken);
                          }
                        } finally {
                          currentTarget.disabled = false;
                        }
                      }}
                    >
                      {isSuspended ?
                        <span className="glyphicon glyphicon-play" aria-hidden="true" />
                        : <span className="glyphicon glyphicon-pause" aria-hidden="true" />
                      }
                    </button>
                    <div className="list-group-item-title">{name} [id {id}]</div>
                    <ReactJson src={emailCampaign} />

                    {isEditing && (
                      <div key={id} className="row form-container">
                        <EmailCampaignForm
                          emailCampaign={editingEmailCampaign}
                          onUpdateEmailCampaign={onUpdateEmailCampaign}
                        />
                        <div className="form-group email-campaign-save-row">
                          <DeleteButton
                            onClick={event => {
                              event.preventDefault();
                              onClickDeleteEmailCampaign(id, authToken);
                            }}
                          />
                          <SaveButton
                            onClick={event => onSubmitEmailCampaign(event, editingEmailCampaign, authToken)}
                          />
                        </div>
                      </div>
                    )}

                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};



const SaveButton = (props: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
}) => (
    <button
      className="btn btn-lg btn-primary btn-submit pull-right"
      onClick={props.onClick}
    >
      Save
    </button>
  );

const DeleteButton = (props: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
}) => (
    <button
      className="btn btn-danger btn-xs"
      onClick={props.onClick}
    >
      <span className="glyphicon glyphicon-trash" aria-hidden="true" />
    </button>
  );
