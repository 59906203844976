import { Action as AuthAction } from "../actions/auth";
import { Action as PageAction } from "../actions/page";
import { Login } from "../components";
import { connect, Dispatch } from "react-redux";
import { authenticate } from "../actions/auth";
import { goToPage } from "../actions/page";


function mapDispatchToProps(dispatch: Dispatch<AuthAction | PageAction>) {
  return {
    onSubmit: async (form: HTMLFormElement) => {
      const loginData = {
        username: (form.elements as any)["username"].value,
        password: (form.elements as any)["password"].value
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth`, {
        method: "POST",
        // Not sure why but it wouldn't detect the content-type, so
        // manually overriding here.
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(loginData)
      });
      let token: string;
      if (response.ok) {
        token = (await response.json()).token;
        dispatch(authenticate(token));
        dispatch(goToPage("POST_ACTION_MATCHES"));
      } else {
        // Sorry, charlie.
        dispatch(goToPage("LOGIN"));
      }
    },
  };
}

export const LoginContainer = connect(
  undefined,
  mapDispatchToProps,
)(Login);
