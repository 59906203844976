import * as React from "react";
import ReactJson from "react-json-view";
import { Company as CompanyType } from "@threatminder-system/tm-core";

interface Props {
  authToken: string;
  company: CompanyType;
}

/**
 * Renders the Company component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {CompanyData} props.company - The company data to be displayed.
 * @returns {JSX.Element | null} The rendered Company component.
 */
export const Company: React.FunctionComponent<Props> = (props: Props) => {
  const { company } = props;
  if (!company) {
    return null;
  }
  return (
    <div>
      <div className="page-header">
        <h1>{company.name}</h1>
        <ReactJson src={company} />
      </div>
    </div>
  );
};
