import * as React from "react";
import { difference } from "lodash";
import {
  Company,
  User,
  EmailCampaign,
  EmailCampaignUser,
} from "@threatminder-system/tm-core";
import { User as UserForm, AugmentedUser } from "./user";
import { UserWithEmailCampaigns } from "../types/user";
import "./users.css";
import Link from "redux-first-router-link";
import { Paginator } from "./paginator";
import { updatePerPage } from "../actions/user";

interface Props {
  authToken: string;
  users: UserWithEmailCampaigns[];
  newUser: AugmentedUser | null;
  editingUser: AugmentedUser;
  emailCampaignsById: { [id: number]: EmailCampaign };
  companiesById: { [id: number]: Company };
  userId: string;
  addUserToEmailCampaign: (
    user: UserWithEmailCampaigns,
    emailCampaignId: number,
    authToken: string
  ) => Promise<void>;
  concludeEmailCampaignForUser: (
    emailCampaignId: number,
    userId: number,
    authToken: string
  ) => Promise<void>;
  onUpdateUser: (updatedNewUser: AugmentedUser | null) => void;
  onClickDeleteUser: (
    event: React.MouseEvent<HTMLElement>,
    authToken: string
  ) => void;
  onClickEditUser: (event: React.MouseEvent<HTMLElement>) => void;
  onClickStartNewUser: () => void;
  onSubmitUser: (
    event: React.MouseEvent<HTMLElement>,
    user: User,
    authToken: string
  ) => any;
  onUpdatePerPage: (perPage: number, authToken: string, page: number) => any;
  page: number;
  perPage: number;
  total: number;
}

export const Users: React.FunctionComponent<Props> = (props: Props) => {
  const {
    newUser,
    editingUser,
    emailCampaignsById,
    companiesById = {},
    userId,
    addUserToEmailCampaign,
    concludeEmailCampaignForUser,
    onClickDeleteUser,
    onClickEditUser,
    onClickStartNewUser,
    onUpdateUser,
    onSubmitUser,
    onUpdatePerPage,
    authToken,
    perPage,
    total,
  } = props;
  let { page } = props;

  const handlePerPageChange = (perPage: number) => {
    updatePerPage(perPage);
    if (perPage > total) {
      page = Math.ceil(total / perPage);
    } else if (perPage < total) {
      const perPageDifference = perPage - total; // For calculating the per page difference for dropdown select.
      if (perPageDifference < perPage) {
        page = page - 1;
      }
    }
    onUpdatePerPage(perPage, authToken, page);
  };

  const users =
    userId && props.users.length
      ? [props.users.find((u) => u.id === Number(userId))]
      : props.users;
  return (
    <div>
      <div className="page-header">
        <h1>Users</h1>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div
            className="row form-container click-to-edit-user"
            onClick={newUser ? null : onClickStartNewUser}
          >
            <h4>Add User</h4>
            {newUser ? (
              <div>
                <UserForm
                  user={newUser}
                  companiesById={companiesById}
                  onUpdateUser={onUpdateUser}
                />
                <SaveButton
                  onClick={(event) => onSubmitUser(event, newUser, authToken)}
                />
              </div>
            ) : (
              <div>Click to begin</div>
            )}
          </div>

          <div className="list-group">
            {users.map((user) => {
              const {
                id,
                firstName,
                lastName,
                email,
                isSuperAdmin,
                userCompanyRelationship,
                accountType,
                isTrialEligible,
                emailsAllowed,
              } = user;
              let companyId, companyName, role;
              if (userCompanyRelationship) {
                role = userCompanyRelationship.role;
                companyId = userCompanyRelationship.companyId;
                const company = companiesById[companyId];
                companyName = company && company.name;
              }
              const isEditing = editingUser.id === id;
              return (
                <div
                  key={user.id}
                  className={`list-group-item user-item ${
                    isEditing && "is-editing-user"
                  }`}
                >
                  <div
                    className={"click-to-edit-user"}
                    data-id={id}
                    onClick={onClickEditUser}
                  >
                    <span className="list-group-item-title">{`${firstName} ${lastName}`}</span>
                    <button
                      data-id={id}
                      className="btn btn-danger pull-right"
                      onClick={(event) => onClickDeleteUser(event, authToken)}
                    >
                      <span
                        id="trashIcon"
                        className="glyphicon glyphicon-trash"
                        aria-hidden="true"
                      />
                    </button>
                    <br />
                    <table className="table user-details">
                      <thead>
                        <tr>
                          <th scope="col">User Id </th>
                          <th scope="col">Email</th>
                          <th scope="col">Company</th>
                          <th scope="col">
                            Super <br />
                            admin?
                          </th>
                          <th scope="col">
                            Account
                            <br />
                            Type?
                          </th>
                          <th scope="col">Other</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{id}</th>
                          <td>{email}</td>
                          <td>
                            {companyName ? (
                              <span>
                                <Link to={`/company/${companyId}`}>
                                  {companyName}
                                </Link>{" "}
                                <br />(<em>{role}</em>)
                              </span>
                            ) : (
                              <span>
                                <small>
                                  No company associated with this user
                                </small>
                              </span>
                            )}
                          </td>
                          <td>{String(isSuperAdmin)}</td>
                          <td>{accountType}</td>
                          <td>
                            <em>Emails?</em> {String(emailsAllowed)} <br />
                            <em>Trial eligible?</em> {String(isTrialEligible)}{" "}
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>

                  {isEditing && (
                    <div className="row form-container">
                      <UserForm
                        user={editingUser}
                        companiesById={companiesById}
                        onUpdateUser={onUpdateUser}
                      />
                      <SaveButton
                        onClick={(event) =>
                          onSubmitUser(event, editingUser, authToken)
                        }
                      />
                    </div>
                  )}

                  <EmailCampaignsToUser
                    emailCampaignsById={emailCampaignsById}
                    user={user}
                    authToken={authToken}
                    addUserToEmailCampaign={addUserToEmailCampaign}
                    concludeEmailCampaignForUser={concludeEmailCampaignForUser}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          pageType="USERS"
          getPagePayload={(pageNum) => ({
            page: pageNum,
          })}
          getPageQuery={(pageNum) => ({ page: pageNum, perPage })}
          onPageDataLengthChange={handlePerPageChange}
        />
      </div>
    </div>
  );
};

const SaveButton = (props: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <div className="form-group">
    <button
      className="btn btn-lg btn-primary btn-submit pull-right"
      onClick={props.onClick}
    >
      Save
    </button>
  </div>
);

type EmailCampaignsToUserProps = Pick<
  Props,
  | "emailCampaignsById"
  | "addUserToEmailCampaign"
  | "concludeEmailCampaignForUser"
  | "authToken"
> & {
  user: UserWithEmailCampaigns;
};

const EmailCampaignsToUser = (props: EmailCampaignsToUserProps) => {
  const {
    authToken,
    emailCampaignsById,
    user,
    addUserToEmailCampaign,
    concludeEmailCampaignForUser,
  } = props;
  const unsubscribedEmailCampaignIds = user.emailCampaigns
    .filter((ecu: EmailCampaignUser) => ecu.isConcluded)
    .map((ecu) => ecu.emailCampaignId);
  // Do not allow Portal admin to re/subscribe user to any campaign
  // that user has unsubscribed from
  const subscribableEmailCampaignIds = difference(
    Object.keys(emailCampaignsById).map(Number),
    unsubscribedEmailCampaignIds
  );
  return (
    <div className="user-email-campaign-management">
      {user.emailCampaigns.length > 0 && (
        <div className="panel panel-default">
          <div className="panel-heading">
            Email campaigns user has been added to
          </div>
          <ul className="list-group user-email-campaigns">
            {user.emailCampaigns.map((userEmailCampaignRelationship) => {
              const { id, emailCampaignId, isConcluded, createdAt, updatedAt } =
                userEmailCampaignRelationship;
              const emailCampaign = emailCampaignsById[emailCampaignId];

              if (!emailCampaign) {
                return null;
              }

              const createdAtDate = new Date(createdAt);
              let updatedAtDate;
              let hasBeenUpdated = false;

              if (updatedAt) {
                updatedAtDate = new Date(updatedAt);
                hasBeenUpdated =
                  updatedAtDate.getTime() !== createdAtDate.getTime();
              }

              return (
                <li
                  key={id}
                  className={`
                    list-group-item
                    ${isConcluded ? "disabled" : ""}
                  `}
                >
                  <div className="row">
                    <div className="col-md-5">{emailCampaign.name}</div>
                    <div className="col-md-5 campaign-start-end">
                      <span title={createdAtDate.toUTCString()}>
                        created: {createdAtDate.toLocaleDateString()}
                      </span>
                      <br />
                      {hasBeenUpdated ? (
                        <span title={updatedAtDate.toUTCString()}>
                          updated: {updatedAtDate.toLocaleDateString()}
                        </span>
                      ) : (
                        <br />
                      )}
                    </div>
                    <div className="col-md-2">
                      {isConcluded ? (
                        <span className="badge badge-user-unsubscribed pull-right">
                          Unsubscribed
                        </span>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-default btn-xs pull-right"
                          onClick={() => {
                            const yes = window.confirm(
                              "This cannot be undone."
                            );
                            if (yes) {
                              concludeEmailCampaignForUser(
                                emailCampaignId,
                                user.id,
                                authToken
                              );
                            }
                          }}
                        >
                          Unsubscribe user
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {subscribableEmailCampaignIds.length > 0 && (
        <div className="select-email-campaigns-container">
          <select
            /*
              Deliberately making this an uncontrolled component
              because it feels like overkill to add this state into redux
            */
            className="select-email-campaigns"
            defaultValue=""
            onChange={(event) => {
              const { currentTarget } = event;
              const { value: emailCampaignId } = currentTarget;
              // Note (gab): I have to do this setTimeout trick in order for the
              // form control to display the option that was clicked from the
              // dropdown while the confirm dialog box is displayed.
              window.setTimeout(() => {
                if (
                  emailCampaignId &&
                  window.confirm(
                    "Be careful. Adding a user to a campaign triggers the system to send an email to the user immediately. Are you sure you wish to continue?"
                  )
                ) {
                  addUserToEmailCampaign(
                    user,
                    Number(emailCampaignId),
                    authToken
                  );
                }
                // Reset the value. If you don't do this, then the user is forced
                // to select another option before they can reselect this one.
                currentTarget.value = "";
              }, 200);
            }}
          >
            <option value="" disabled={true}>
              Add user to email campaign
            </option>
            {subscribableEmailCampaignIds.map(
              (id) =>
                emailCampaignsById[id] && (
                  <option key={id} value={id}>
                    {emailCampaignsById[id].name}
                  </option>
                )
            )}
          </select>
        </div>
      )}
    </div>
  );
};
