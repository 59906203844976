import { Dispatch } from "redux";
import { Action as ActionType } from "@threatminder-system/tm-core";
import { fetchApiRoute } from "../helpers/fetch";

export type Action =
  | ReceiveActionsAction
  | RemoveActionAction
  | UpdateActionAction
  | ChangeDataPullStatusAction
  | ChangeDataCapAction
  | StartEditingActionAction;

export const RECEIVE_ACTIONS = "action/receiveMany";
export type RECEIVE_ACTIONS_TYPE = typeof RECEIVE_ACTIONS;

export const REMOVE_ACTION = "action/remove";
export type REMOVE_ACTION_TYPE = typeof REMOVE_ACTION;

export const UPDATE_ACTION = "action/update";
export type UPDATE_ACTION_TYPE = typeof UPDATE_ACTION;

export const CHANGE_DATA_PULL_STATUS = "action/changeDataPullStatus";
export type CHANGE_DATA_PULL_STATUS_TYPE = typeof CHANGE_DATA_PULL_STATUS;

export const CHANGE_DATA_CAP = "action/changeDataCap";
export type CHANGE_DATA_CAP_TYPE = typeof CHANGE_DATA_CAP;

export const START_EDITING_ACTION = "action/startEditing";
export type START_EDITING_ACTION_TYPE = typeof START_EDITING_ACTION;

interface ReceiveActionsAction {
  type: RECEIVE_ACTIONS_TYPE;
  payload: ActionType[];
  meta: {
    page?: number;
    perPage?: number;
    total?: number;
    hasFetchedAll?: boolean;
  };
}

export function receive(
  response: {
    actions: ActionType[];
    page?: number;
    perPage?: number;
    total?: number;
  },
  meta: {
    hasFetchedAll?: boolean;
  } = {}
): ReceiveActionsAction {
  return {
    type: RECEIVE_ACTIONS,
    payload: response.actions,
    meta: {
      page: response.page,
      perPage: response.perPage,
      total: response.total,
      hasFetchedAll: meta.hasFetchedAll,
    },
  };
}

export const fetchActions = async (
  dispatch: Dispatch<any>,
  authToken: string,
  perPage?: number,
  page?: number
) => {
  if (!perPage) {
    await fetchActionsInBatches(dispatch, authToken);
  } else {
    const base = "/api/v1/actions";
    const url = perPage ? base + `?perPage=${perPage}&page=${page}` : base;
    const response = await fetchApiRoute(url, { authToken });
    if (response.ok) {
      let json;
      try {
        json = await response.json();
      } catch (err) {
        console.log("Error parsing response from API for actions.", err);
      }
      dispatch(receive(json, { hasFetchedAll: !perPage }));
    } else {
      console.log(
        "Could not fetch actions. Check network panel. Error response, HTTP code = " +
          response.status +
          "."
      );
    }
  }
};

export const fetchActionsInBatches = async (
  dispatch: Dispatch<any>,
  authToken: string,
  batchSize: number = 5000
) => {
  const base = "/api/v1/actions";

  // Fetch the total count of records
  const countResponse = await fetchApiRoute(`${base}?perPage=1&page=1`, {
    authToken,
  });
  const totalCountJson = await countResponse.json();
  const totalAmount = totalCountJson.total;

  const totalPages = Math.ceil(totalAmount / batchSize);

  // Create an array of promises to fetch each batch
  const fetchPromises = Array.from({ length: totalPages }, (_, index) => {
    const url = `${base}?perPage=${batchSize}&page=${index + 1}`;
    return fetchApiRoute(url, { authToken }).then((response) =>
      response.ok ? response.json() : Promise.reject(response)
    );
  });

  try {
    // Fetch all batches in parallel using Promise.all
    const results = await Promise.all(fetchPromises);

    const finalJson = {
      actions: [] as ActionType[],
      perPage: 100,
      page: 1,
      total: totalAmount,
    };
    // Combine all results into a single array
    results.flat().reduce((acc, val) => {
      acc.actions.push(...val.actions);
      return acc;
    }, finalJson);

    dispatch(receive(finalJson, { hasFetchedAll: true }));
  } catch (err) {
    console.log("Error fetching actions in batches:", err);
  }
};

interface RemoveActionAction {
  type: REMOVE_ACTION_TYPE;
  payload: number;
}

export function remove(actionId: number): RemoveActionAction {
  return {
    type: REMOVE_ACTION,
    payload: actionId,
  };
}

interface UpdateActionAction {
  type: UPDATE_ACTION_TYPE;
  payload: ActionType;
  meta: {
    isNew: boolean;
  };
}

export function updateAction(
  payload: ActionType,
  meta?: { isNew: boolean }
): UpdateActionAction {
  return {
    type: UPDATE_ACTION,
    payload: payload,
    meta: {
      isNew: Boolean(meta && meta.isNew),
    },
  };
}

export type DataPullStatus = {
  actionId: number;
  ok: boolean;
};

interface ChangeDataPullStatusAction {
  type: CHANGE_DATA_PULL_STATUS_TYPE;
  payload: DataPullStatus;
}

export function changeDataPullStatus(
  payload: DataPullStatus
): ChangeDataPullStatusAction {
  return {
    type: CHANGE_DATA_PULL_STATUS,
    payload: payload,
  };
}

interface ChangeDataCapAction {
  type: CHANGE_DATA_CAP_TYPE;
  payload: {
    actionId: number;
    dataCap: string;
  };
}

export function changeDataCap(
  action: ActionType,
  dataCap: string
): ChangeDataCapAction {
  return {
    type: CHANGE_DATA_CAP,
    payload: {
      actionId: action.id,
      dataCap,
    },
  };
}

interface StartEditingActionAction {
  type: START_EDITING_ACTION_TYPE;
  payload: number;
}

export function startEditingAction(payload: number): StartEditingActionAction {
  return {
    type: START_EDITING_ACTION,
    payload,
  };
}
