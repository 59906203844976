export type Action = ReceiveUploadErrorsAction;

export const RECEIVE_UPLOAD_ERRORS = "uploader/receiveErrors";
export type RECEIVE_UPLOAD_ERRORS_TYPE = typeof RECEIVE_UPLOAD_ERRORS;

interface ReceiveUploadErrorsAction {
  type: RECEIVE_UPLOAD_ERRORS_TYPE;
  payload: {
    apiResults: any;
    hasUploaded: boolean;
  };
}

export function receive(apiResults: any): ReceiveUploadErrorsAction {
  return {
    type: RECEIVE_UPLOAD_ERRORS,
    payload: { apiResults: apiResults, hasUploaded: true },
  };
}
