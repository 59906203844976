import { RiskKeywordSet } from "@threatminder-system/tm-core";
import { DbTable } from "./db-table";

interface Props {
  riskKeywordSets: RiskKeywordSet[];
}

export const RiskKeywordSets = (props: Props) => {
  const { riskKeywordSets } = props;
  const rows = toRows(riskKeywordSets);
  return (
    <div>
      <div className="page-header">
        <h1>Risk Keyword Sets</h1>
      </div>
      <DbTable rows={rows} />
    </div>
  );
};

const toRows = (riskKeywordSets: RiskKeywordSet[]) => {
  const rows: string[][] = [];
  const numCols = riskKeywordSets.length;
  const numRows = Math.max(
    ...riskKeywordSets.map((rks) => rks.keywords.length)
  );
  for (let i = 0; i < numRows; i++) {
    rows[i] = [];
    for (let j = 0; j < numCols; j++) {
      rows[i][j] = riskKeywordSets[j].keywords[i];
    }
  }
  const setNames = riskKeywordSets.map((rks) => rks.name);
  return [setNames].concat(rows);
};
