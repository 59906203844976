
export function blankFilter(): any {
  return {
    name: "",
    expression: "",
    options: {
      keywords: []
    },
  };
}
