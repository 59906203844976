import * as React from "react";

import "./basic-object-editor.css";


interface ArrayStringObj {
  [key: string]: string[] | string;
}

interface BasicObjectEditorProps {
  obj: ArrayStringObj;
  onChange: (nextObj: ArrayStringObj) => void;
}

enum TYPE_OPTIONS {
  array = "array",
  string = "string",
}

type KeyTypeValueError = [string, TYPE_OPTIONS, string[], string[]];

interface BasicObjectEditorState {
  rows: KeyTypeValueError[];
}

const DUPLICATE_KEY_ERROR = "Cannot use existing key name";

// NOTE: this is a special kind of UNCONTROLLED React component
//
// Meaning: once you pass in the object to be edited via props,
// you cannot update the component by passing in new props.
//
// Anytime the user interacts with the form, the component's state
// changes, and IF the state change should result in an update
// to the passed-in object, then the component will pass the updated
// object up via props.onChange()
//
// NOTE: even though the component ignores props.obj every subsequent
// time it is passed in, the component still expects the object to be
// passed in whenever it is updated because the component makes every
// update to the object from a copy of the most up-to-date props.obj
export class BasicObjectEditor extends React.Component<BasicObjectEditorProps, BasicObjectEditorState> {
  constructor(props: BasicObjectEditorProps) {
    super(props);
    this.state = {
      rows: this.objToRows(props.obj),
    };
  }

  // converts props to state
  //
  // more specifically, converts props.obj to a data format
  // that's more convenient for maintaining the state of this
  // form component
  objToRows(obj: ArrayStringObj): KeyTypeValueError[] {
    const rows: Array<KeyTypeValueError> = [];
    Object.keys(obj).forEach(key => {
      const val = obj[key];
      if (Array.isArray(val)) {
        rows.push([key, TYPE_OPTIONS.array, val, []]);
      } else {
        rows.push([key, TYPE_OPTIONS.string, [val], []]);
      }
    });
    return rows;
  }

  rowsToObj(rows: KeyTypeValueError[]): ArrayStringObj {
    const obj: ArrayStringObj = {};
    // reverse the rows because where there's a conflict in key name,
    // the older (lower index) key takes precedence
    [...rows].reverse().forEach(([key, dataType, vals]) => {
      obj[key] = dataType === TYPE_OPTIONS.string ? vals[0] : vals;
    });
    return obj;
  }

  // aka: remove key
  deleteRow(rowIndex: number) {
    const newRows = this.state.rows.filter((_, iterIndex) => iterIndex !== rowIndex);

    this.setState({ rows: newRows });
    this.props.onChange(this.rowsToObj(newRows));
  }

  // aka: add key
  addRow = () => {
    const newRows = this.state.rows.concat([
      ["", TYPE_OPTIONS.string, [""], []]
    ]);
    this.setState({ rows: newRows });
  };

  changeKey(rowIndex: number, keyName: string) {
    const isKeyAlreadyUsed = this.state.rows.some(row => row[0] === keyName);
    const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
      return iterIndex === rowIndex ?
        [keyName, row[1], row[2],
          (isKeyAlreadyUsed ? row[3] : row[3].filter(error => error !== DUPLICATE_KEY_ERROR))]
        : row;
    });

    this.setState({ rows: newRows });

    // Cannot use existing key name
    if (isKeyAlreadyUsed) {
      return;
    }

    this.props.onChange(this.rowsToObj(newRows));
  }

  checkForDuplicateKeyName(rowIndex: number) {
    const [keyName, , , errors] = this.state.rows[rowIndex];
    const hasDuplicateKey = this.state.rows.some(
      (row, iterIndex) => iterIndex !== rowIndex && row[0] === keyName
    );
    if (hasDuplicateKey) {
      const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
        return iterIndex === rowIndex ?
          [row[0], row[1], row[2], row[3].concat([DUPLICATE_KEY_ERROR])]
          : row;
      });
      this.setState({ rows: newRows });
    } else if (errors.some(error => error === DUPLICATE_KEY_ERROR)) {
      // Remove the error if it exists
      const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
        return iterIndex === rowIndex ?
          [row[0], row[1], row[2], row[3].filter(error => error !== DUPLICATE_KEY_ERROR)]
          : row;
      });
      this.setState({ rows: newRows });
    }
  }

  changeDataType(rowIndex: number, dataType: TYPE_OPTIONS) {
    const row = this.state.rows[rowIndex];
    const [ , oldDataType, vals] = row;

    // I don't think this is possible, but just in case...
    if (oldDataType === dataType) {
      return;
    }

    if (dataType === TYPE_OPTIONS.string && vals.length > 1) {
      alert("Only when the array has a single value can it be converted to a string.");
      return;
    }

    const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
      return iterIndex === rowIndex ?
        [row[0], dataType, row[2], row[3]]
        : row;
    });

    this.setState({ rows: newRows });
    this.props.onChange(this.rowsToObj(newRows));
  }

  changeValue(rowIndex: number, valIndex: number, newValue: string) {
    const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
      return iterIndex === rowIndex ?
        [row[0], row[1], row[2].map((val, iterIndex) => {
          return iterIndex === valIndex ?
            newValue
            : val;
        }), row[3]]
        : row;
    });

    this.setState({ rows: newRows });
    this.props.onChange(this.rowsToObj(newRows));
  }

  addValue(rowIndex: number) {
    const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
      return iterIndex === rowIndex ?
        [row[0], row[1], [...row[2], ""], row[3]]
        : row;
    });
    this.setState({ rows: newRows });
  }

  deleteValue(rowIndex: number, valIndex: number) {
    const row = this.state.rows[rowIndex];
    const [ , dataType, value] = row;

    // Cannot delete string or single value
    if (dataType === TYPE_OPTIONS.string || value.length === 1) {
      return;
    }

    const newRows = this.state.rows.map((row, iterIndex): KeyTypeValueError => {
      return iterIndex === rowIndex ?
        [row[0], row[1], row[2].filter((_, iterIndex) => iterIndex !== valIndex), row[3]]
        : row;
    });

    this.setState({ rows: newRows });
    this.props.onChange(this.rowsToObj(newRows));
  }

  render() {
    return (
      <div>
        {this.state.rows.map((row, rowIndex) => {
          const [key, dataType, vals, errors] = row;
          const hasDuplicateKeyError = errors.some(error => error === DUPLICATE_KEY_ERROR);
          return (
            <div key={rowIndex} className="object-editor-key-section">
              <div className="object-editor-button-container">
                <button
                  type="button"
                  className="btn-default"
                  onClick={() => this.deleteRow(rowIndex)}
                >
                  - Remove key
                </button>
              </div>
              <div className="object-editor-key-fields">
                <div className="form-group">
                  <label>Key</label>
                  {hasDuplicateKeyError && (
                    <div className="error">
                      Cannot use a key name that has already been used.
                    </div>
                  )}
                  <input
                    name={key}
                    value={key}
                    type="text"
                    onChange={event => this.changeKey(rowIndex, event.currentTarget.value)}
                    onFocus={() => this.checkForDuplicateKeyName(rowIndex)}
                    onBlur={() => this.checkForDuplicateKeyName(rowIndex)}
                    className={`form-control ${hasDuplicateKeyError ? "error" : ""}`}
                  />
                </div>
                <div className="form-group">
                  <label>Data type</label>
                  <select
                    value={dataType}
                    onChange={event =>
                      this.changeDataType(rowIndex, (event.currentTarget.value as TYPE_OPTIONS))
                    }
                    className="form-control"
                  >
                    <option value={TYPE_OPTIONS.array}>Array (of strings)</option>
                    <option
                      value={TYPE_OPTIONS.string}
                      disabled={dataType === TYPE_OPTIONS.array && vals.length > 1}
                    >
                      String
                    </option>
                  </select>
                </div>
                {vals.map((val, valIndex) => (
                  <div key={rowIndex + "," + valIndex} className="object-editor-value-section">
                    {dataType === TYPE_OPTIONS.array && (
                      <div className="object-editor-button-container">
                        <button
                          type="button"
                          className="btn-default"
                          disabled={vals.length === 1}
                          onClick={() => this.deleteValue(rowIndex, valIndex)}
                        >
                          - Remove value
                        </button>
                      </div>
                    )}
                    <div className="form-group">
                      <label>Value</label>
                      <input
                        name={rowIndex + "," + valIndex}
                        value={val}
                        type="text"
                        onChange={event => this.changeValue(rowIndex, valIndex, event.currentTarget.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                ))}
                {dataType === TYPE_OPTIONS.array && (
                  <div>
                    <button
                      type="button"
                      className="btn-default"
                      onClick={() => this.addValue(rowIndex)}
                    >
                      + Add value
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div>
          <button
            type="button"
            onClick={this.addRow}
            className="btn-default"
          >
            + Add key
          </button>
        </div>
      </div>
    );
  }
}
