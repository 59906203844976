import * as React from "react";
import { useState } from "react";
import Creatable from "react-select/lib/Creatable";
import update from "immutability-helper";
import {
  UserCompanyRelationshipRoles,
  AccountType,
  User as UserType,
  Company as CompanyType,
  randomUUID as uuidv4,
} from "@threatminder-system/tm-core";
import GenerateApiKeyPopup from "./generate-api-key-popup";

export type AugmentedUser = UserType & { newCompanyName: string };

interface Props {
  user: AugmentedUser;
  companiesById: { [id: number]: CompanyType };
  onUpdateUser: (updatedUser: AugmentedUser) => void;
}

export const User: React.FunctionComponent<Props> = (props: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const { user, companiesById, onUpdateUser } = props;
  const generateApiKey = () => {
    const apiKey = uuidv4();
    props.onUpdateUser({ ...props.user, apiKey });
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const deleteApiKey = () => {
    props.onUpdateUser({ ...props.user, apiKey: null });
  };
  const isNewUser = !user.id;
  let company;
  const companyId =
    user.userCompanyRelationship && user.userCompanyRelationship.companyId;
  if (companyId) {
    company = companiesById[companyId];
  }

  return (
    <div>
      <div className="form-group">
        <label className="control-label" htmlFor="user-email">
          Email <span className="user-required">(required)</span>
        </label>
        <input
          type="email"
          maxLength={100}
          className="form-control"
          id="user-email"
          required={true}
          value={user.email || ""}
          onChange={(event) => {
            const {
              currentTarget: { value },
            } = event;
            onUpdateUser({ ...user, email: value });
          }}
        />
      </div>

      {isNewUser && (
        <div className="form-group">
          <label className="control-label" htmlFor="user-password">
            Password <span className="user-required">(required)</span>
          </label>
          <input
            type="text"
            maxLength={100}
            className="form-control"
            id="user-password"
            required={true}
            value={user.password || ""}
            onChange={(event) => {
              const {
                currentTarget: { value },
              } = event;
              onUpdateUser({ ...user, password: value });
            }}
          />
        </div>
      )}
      <div className="user-api-key">
        <div className="form-group">
          <label className="control-label" htmlFor="user-api-key">
            Api Key
          </label>
          <input
            type={"text"}
            disabled={true}
            maxLength={100}
            className="form-control"
            id="user-api-key"
            value={user.apiKey || ""}
            onChange={(event) => {
              const { value } = event.target;
              onUpdateUser({ ...user, apiKey: value });
            }}
          />
        </div>
        <div className="update-api-btn-cls">
          <div className="form-group generate-btn-cls ">
            <button
              onClick={generateApiKey}
              title={isNewUser ? "Generate API Key" : "Edit API Key"}
              className="btn btn-md btn-success btn-submit pull-right"
            >
              {isNewUser ? (
                "Generate API Key "
              ) : (
                <span
                  id="EditIcon"
                  className="glyphicon glyphicon-edit"
                  aria-hidden="true"
                />
              )}
            </button>
            {showPopup && (
              <GenerateApiKeyPopup
                apiKey={user.apiKey}
                onClose={handleClosePopup}
              />
            )}
          </div>
          {!isNewUser && (
            <div className="form-group generate-btn-cls">
              <button
                disabled={!user.apiKey}
                className="btn btn-danger pull-right"
                onClick={deleteApiKey}
                title="Delete API Key"
              >
                <span
                  id="trashIcon"
                  className="glyphicon glyphicon-trash"
                  aria-hidden="true"
                />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="user-first-name">
          First Name
        </label>
        <input
          type="text"
          maxLength={50}
          className="form-control"
          id="user-first-name"
          required={true}
          value={user.firstName || ""}
          onChange={(event) => {
            const {
              currentTarget: { value },
            } = event;
            onUpdateUser({ ...user, firstName: value });
          }}
        />
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="user-last-name">
          Last Name
        </label>
        <input
          type="text"
          maxLength={50}
          className="form-control"
          id="user-last-name"
          required={true}
          value={user.lastName || ""}
          onChange={(event) => {
            const {
              currentTarget: { value },
            } = event;
            onUpdateUser({ ...user, lastName: value });
          }}
        />
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="user-is-super-admin">
          Is Super Admin?
        </label>
        <input
          type="checkbox"
          className="form-control"
          id="user-is-super-admin"
          required={true}
          checked={Boolean(user.isSuperAdmin)}
          onChange={(event) => {
            const {
              currentTarget: { checked },
            } = event;
            onUpdateUser({ ...user, isSuperAdmin: checked });
          }}
        />
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="user-company">
          Company
        </label>
        <Creatable
          /*
            About placeholder + defaultValue -
            For a new user, we want the UI to indicate (via placeholder)
            that if no company is selected from the dropdown, the company
            name will default to the new user's email address.
            For an existing user, the dropdown should simply have the
            existing company selected (via defaultValue) as the default
            value.
          */
          placeholder={isNewUser ? user.email : ""}
          defaultValue={
            !isNewUser && company
              ? { value: company.id, label: company.name }
              : null
          }
          isClearable={true}
          options={Object.values(companiesById).map((c) => ({
            value: c.id,
            label: c.name,
          }))}
          onChange={(option: any) => {
            if (option === null) {
              onUpdateUser(
                update(user, {
                  newCompanyName: { $set: "" },
                  userCompanyRelationship: {
                    companyId: { $set: null },
                  },
                })
              );
            } else if (typeof option.value === "number") {
              onUpdateUser(
                update(user, {
                  newCompanyName: { $set: "" },
                  userCompanyRelationship: {
                    companyId: { $set: Number(option.value) },
                  },
                })
              );
            } else if (typeof option.value === "string") {
              onUpdateUser(
                update(user, {
                  newCompanyName: { $set: option.value },
                  userCompanyRelationship: {
                    companyId: { $set: null },
                  },
                })
              );
            }
          }}
        />
      </div>

      {user.userCompanyRelationship ? (
        <div>
          <div className="form-group">
            <label className="control-label" htmlFor="user-role">
              User Role
            </label>
            <select
              className="form-control"
              id="user-role"
              name="user-role"
              required={true}
              value={user.userCompanyRelationship.role || ""}
              onChange={(event) => {
                const value = event.currentTarget
                  .value as UserCompanyRelationshipRoles;
                onUpdateUser(
                  update(user, {
                    userCompanyRelationship: { role: { $set: value } },
                  })
                );
              }}
            >
              <option value={UserCompanyRelationshipRoles.Owner}>Owner</option>
              <option value={UserCompanyRelationshipRoles.User}>User</option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="user-emails-allowed">
              Emails Allowed
            </label>
            <input
              type="checkbox"
              className="form-control"
              id="user-emails-allowed"
              required={true}
              checked={Boolean(user.emailsAllowed)}
              onChange={(event) => {
                const {
                  currentTarget: { checked },
                } = event;
                onUpdateUser({ ...user, emailsAllowed: checked });
              }}
            />
          </div>
        </div>
      ) : (
        <span>
          <hr />
          <br />
          <small>No company associated with this user.</small>
        </span>
      )}

      <div className="form-group">
        <label className="control-label" htmlFor="user-role">
          Account Type
        </label>
        <select
          className="form-control"
          id="user-account-type"
          name="user-account-type"
          required={true}
          value={user.accountType || ""}
          onChange={(event) => {
            const {
              currentTarget: { value },
            } = event;
            onUpdateUser({ ...user, accountType: value as AccountType });
          }}
        >
          <option value={AccountType.Normal}>Normal</option>
          <option value={AccountType.Enterprise}>Enterprise</option>
          <option value={AccountType.AUS}>AUS</option>
          <option value={AccountType.AusTrial}>Aus Trial</option>
        </select>
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="user-is-trial-eligible">
          Is Trial Eligible?
        </label>
        <input
          type="checkbox"
          className="form-control"
          id="user-is-trial-eligible"
          required={true}
          checked={Boolean(user.isTrialEligible)}
          onChange={(event) => {
            const {
              currentTarget: { checked },
            } = event;
            onUpdateUser({ ...user, isTrialEligible: checked });
          }}
        />
      </div>
    </div>
  );
};
