import * as React from "react";
import Link from "redux-first-router-link";
import { useState } from "react";
import "./paginator.css";

interface Props {
  page: number;
  perPage: number;
  total: number;
  pageType: string;
  getPagePayload?: (pageNum: number) => object;
  getPageQuery: (pageNum: number) => object;
  onPageDataLengthChange?: (perPage: number) => void;
}

export const Paginator: React.FunctionComponent<Props> = (props: Props) => {
  const {
    page,
    perPage,
    total,
    pageType,
    getPagePayload = () => ({}),
    getPageQuery,
    onPageDataLengthChange,
  } = props;

  const [perPageSelection, setPerPageSelection] = useState(perPage);
  const perPageValue = [25, 50, 100];
  const noOfPages = Math.ceil(total / (pageType === "USERS" ? perPageSelection : perPage));
  const maxPageLength = 10;

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    setPerPageSelection(value);
    if (onPageDataLengthChange) {
      onPageDataLengthChange(value);
    }
  };

  const makePageLink = (pageNum: number) => (
    <Link
      to={{
        type: pageType,
        payload: getPagePayload(pageNum),
        query: getPageQuery(pageNum),
      }}
    >
      {pageNum}
    </Link>
  );

  const backButton = (
    <li>
      <Link
        to={{
          type: pageType,
          payload: getPagePayload(page > 1 ? page - 1 : page),
          query: getPageQuery(page > 1 ? page - 1 : page),
        }}
      >
        <span className={page > 1 ? "glyphicon glyphicon-chevron-left" : "glyphicon glyphicon-stop"} />
      </Link>
    </li>
  );

  const nextButton = (
    <li className="next pull-right">
      <Link
        to={{
          type: pageType,
          payload: getPagePayload(page < noOfPages ? page + 1 : page),
          query: getPageQuery(page < noOfPages ? page + 1 : page),
        }}
      >
        <span className={page < noOfPages ? "glyphicon glyphicon-chevron-right" : "glyphicon glyphicon-stop"} />
      </Link>
    </li>
  );

  const renderPageNumbers = () => {
    const pageNumbers: (number | string)[] = [];
    let startPage = 1;
    let endPage = Math.min(noOfPages, maxPageLength);
    if (page > maxPageLength && noOfPages > maxPageLength) {
      startPage = page - (maxPageLength - 1);
      endPage = Math.min(page, noOfPages);
    }

    if (startPage > 1) {
      pageNumbers.push(1);
      pageNumbers.push("ellipsis");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (noOfPages > maxPageLength && page < noOfPages - 2) {
      pageNumbers.push("ellipsis");
      pageNumbers.push(noOfPages);
    }

    return pageNumbers.map((pageNum, index) => {
      if (pageNum === "ellipsis") {
        return <li style={{marginTop: "10px", color: "white"}}  key={index}>...</li>;
      } else {
        return (
          <li
            className={pageNum === page ? "page-number current" : "page-number"}
            key={index}
          >
            {makePageLink(pageNum as number)}
          </li>
        );
      }
    });
  };

  return (
    <div className={total === 0 ? "paginator no-results" : "paginator"}>
      <ul>
        {backButton}
        {pageType === "USERS" && (
          <li style={{ float: "right" }}>
            <div className="dropdown">
              Show{" "}
              <select
                value={perPageSelection}
                onChange={(e) => handlePerPageChange(e)}
              >
                {perPageValue.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>{" "}
              users per page
            </div>
          </li>
        )}
        {renderPageNumbers()}
        {nextButton}
      </ul>
    </div>
  );
};

