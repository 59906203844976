import { redirect, NOT_FOUND } from "redux-first-router";
import { Dispatch } from "react-redux";
import { StoreState } from "./types";
import { receive as receiveFilters } from "./actions/filter";
import { receiveCompanies, fetchCompanies, fetchAllCompaniesOnce } from "./actions/company";
import { fetchActions } from "./actions/action";
import { receive as receiveEmailCampaigns } from "./actions/email-campaign";
import { receive as receiveDatasets } from "./actions/dataset";
import { receive as receivePlatforms } from "./actions/platform";
import { fetchPostActionMatches } from "./actions/post-action-match";
import {
  receivePortalUsers,
  receivePostActionMatchesHistory,
} from "./actions/post-action-matches-history";
import { receiveUsers } from "./actions/user";
import { receiveLocalSocialAccounts } from "./actions/local-social-accounts";
import { fetchApiRoute } from "./helpers/fetch";
import {
  Platform,
  SortByEnum,
  SortByOrderEnum,
  Dataset
} from "@threatminder-system/tm-core";

const PER_PAGE_DEFAULT = 5;
const PAGE_DEFAULT = 1;

const routesMap = {
  INDEX: {
    path: "/",
    thunk: (dispatch: Dispatch<any>, getState: () => StoreState) => {
      if (getState().auth.authToken) {
        dispatch(redirect({ type: "POST_ACTION_MATCHES" }));
      } else {
        dispatch(redirect({ type: "LOGIN" }));
      }
    },
  },
  LOGIN: {
    path: "/login",
  },
  UPLOAD: {
    path: "/upload",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      let datasets: Dataset[] = getState().dataset.datasets;

      if (datasets.length === 0) {
        const datasetsResponse = await fetchApiRoute(
          `/api/v1/datasets?limit=50`,
          {authToken: getState().auth.authToken});
        if (datasetsResponse.ok) {
          const datasetJson = await datasetsResponse.json();
          datasets = datasetJson.datasets;
        }
      }
      dispatch(receiveDatasets(datasets));
    },
  },
  EMAIL_CAMPAIGNS: {
    path: "/email-campaigns",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const url = `/api/v1/email-campaigns`;
      const response = await fetchApiRoute(url, {authToken: getState().auth.authToken});
      if (response.ok) {
        const json = await response.json();
        dispatch(receiveEmailCampaigns(json));
      }
    },
  },
  ACTIONS: {
    path: "/actions/:id?",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const { location, action, auth } = getState();
      const { perPage = PER_PAGE_DEFAULT, page = PAGE_DEFAULT } = location.query || ({} as any);
      const { id } = location.payload as any;
      const { actions } = action;
      const { authToken } = auth;

      fetchAllCompaniesOnce(dispatch, authToken);

      if (id && !actions[id]) {
        // Fetch them all
        fetchActions(dispatch, authToken);
      } else {
        // Fetch only the current page
        fetchActions(dispatch, authToken, perPage, page);
      }
    },
  },
  FILTERS: {
    path: "/filters",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const {
        perPage = PER_PAGE_DEFAULT,
        page = PAGE_DEFAULT
      } = getState().location.query || {} as any;
      const url = `/api/v1/filters?perPage=${perPage}&page=${page}`;
      const response = await fetchApiRoute(url, {authToken: getState().auth.authToken});
      if (response.ok) {
        const json = await response.json();
        dispatch(receiveFilters(json));
      }
    },
  },
  COMPANIES: {
    path: "/companies",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const { location, auth: { authToken } } = getState();
      const { perPage = PER_PAGE_DEFAULT, page = PAGE_DEFAULT } = location.query || ({} as any);
      await fetchCompanies(dispatch, authToken, perPage, page);
    },
  },
  COMPANY: {
    path: "/company/:id",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const {
        company: { companies },
        location: {
          payload
        },
      } = getState();
      const id: string = (payload as any).id;
      const company = companies[Number(id)];

      // If we don't already have the company in memory,
      // we try to fetch it from the API.
      // If the API 404s, we return the "Not found" page.
      if (!company) {
        const response = await fetchApiRoute(`/api/v1/companies/${id}`, {authToken: getState().auth.authToken});
        if (response.ok) {
          const json = await response.json();
          dispatch(receiveCompanies(json));
        } else if (response.status === 404) {
          dispatch({ type: NOT_FOUND });
        }
      }
    },
  },
  DATA_CAPS: {
    path: "/data-caps",
    thunk: (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const { auth: { authToken }, action: { hasFetchedAll } } = getState();
      // Fetch data dependencies
      fetchAllCompaniesOnce(dispatch, authToken);
      // Only fetch actions if they haven't already been fetched
      if (!hasFetchedAll) {
        fetchActions(dispatch, authToken);
      }
    },
  },
  PLATFORMS: {
    path: "/platforms",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      await fetchPlatforms(dispatch, getState);
    }
  },

  "REDIRECT/POST_ACTION_MATCHES": {
    path: "/posts/:page?",
  },
  POST_ACTION_MATCHES: {
    path: "/review-posts/:page?",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const { authToken } = getState().auth;
      fetchAllCompaniesOnce(dispatch, authToken);

      const page = (getState().location.payload as any).page ?? 1;
      const pageQuery = getState().location.query as any;
      if (!pageQuery) {
        dispatch(redirect({
          type: "POST_ACTION_MATCHES",
          payload: { page },
          query: {
            sortBy: SortByEnum.postId,
            sortByOrder: SortByOrderEnum.DESC,
          }
        }));
        return;
      }

      const {
        sortBy = SortByEnum.postId,
        sortByOrder = SortByOrderEnum.DESC,
        companyId = [],
        excludeCompany,
        actionId = [],
        excludeAction,
        platformId: selectedPlatformIds = []
      } = pageQuery;

      const dependencies = [];

      // Fetch actions (if not already fetched)
      if (!getState().action.hasFetchedAll) {
        // fetch all actions by not providing { page, perPage } query string params
        dependencies.push(fetchActions(dispatch, authToken));
      }

      // Fetch platforms (if not already fetched)
      if (!getState().platform.platforms.length) {
        dependencies.push(fetchPlatforms(dispatch, getState));
      }

      await Promise.all(dependencies);
      await fetchPostActionMatches(dispatch, {
        authToken,
        page: page,
        sortBy: sortBy,
        sortByOrder: sortByOrder,
        companyId: companyId,
        excludeCompany: excludeCompany,
        actionId: actionId,
        excludeAction: excludeAction,
        platformId: selectedPlatformIds,
      });
    },
  },
  POST_ACTION_MATCHES_HISTORY: {
    path: "/review-posts-history",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const state = getState();
      const { auth: { authToken } } = state;
      const query = (getState().location.query) as { userId?: string } | void;

      const response = await fetchApiRoute("/auth/users", {
        authToken,
      });
      if (response.ok) {
        const payload = await response.json();
        dispatch(receivePortalUsers(payload));
      }

      const userId = query && query.userId;
      if (userId) {
        let apiPath = `/post-action-matches-history?userId=${userId}`;
        const { page, perPage } = query as any;
        if (page) {
          apiPath += `&page=${page}`;
        }
        if (perPage) {
          apiPath += `&perPage=${perPage}`;
        }
        const response = await fetchApiRoute(apiPath, {
          authToken,
        });
        if (response.ok) {
          const payload = await response.json();
          dispatch(receivePostActionMatchesHistory(payload));
        }
      }
    }
  },
  // This path was made more generic: sync-sheets
  SYNC_RISK_KEYWORD_SETS: {
    path: "/risk-keyword-sets",
    thunk: (dispatch: Dispatch<any>) => {
      dispatch(redirect({ type: "SYNC_SHEETS" }));
    },
  },
  SYNC_SHEETS: {
    path: "/sync-sheets",
  },
  RISK_KEYWORD_SETS_SYNCED: {
    path: "/risk-keyword-sets-synced",
  },
  LOCAL_SOCIAL_ACCOUNTS: {
    path: "/local-social-accounts",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const response = await fetchApiRoute("/api/v1/local-social-accounts", {authToken: getState().auth.authToken});
      if (response.ok) {
        const body = await response.json();
        dispatch(receiveLocalSocialAccounts(body));
      } else {
        alert("Problem fetching local social accounts. Check server logs. Try refreshing.");
      }
    },
  },
  USERS: {
    path: "/users/:id?",
    thunk: async (dispatch: Dispatch<any>, getState: () => StoreState) => {
      const authToken = getState().auth.authToken;
      const dependencies = Promise.all([
        fetchAllCompaniesOnce(dispatch, authToken),
        fetchUsers(dispatch, getState ),
        fetchApiRoute("/api/v1/email-campaigns", { authToken }).then(async (response) => {
          if (response.ok) {
            const json = await response.json();
            dispatch(receiveEmailCampaigns(json));
          }
        })
      ]);
      await dependencies;
    },
  },
};

export const fetchUsers = async (
  dispatch: Dispatch<any>,
  getState: () => StoreState
) => {
  const perPage = getState().user.perPage;
  const page = (getState().location.payload as any).page ?? 1;
  const response = await fetchApiRoute(`/api/v1/users?page=${page}&perPage=${perPage}`, {authToken: getState().auth.authToken});
  if (response.ok) {
    dispatch(receiveUsers( await response.json()));
  }
};

const fetchPlatforms = async (
  dispatch: Dispatch<any>,
  getState: () => StoreState,
) => {
  const response = await fetchApiRoute("/api/v1/platforms", {authToken: getState().auth.authToken});
  const { platforms }: { platforms: Platform[] } = await response.json();
  dispatch(receivePlatforms(platforms));
};

export default routesMap;