import { Company } from "@threatminder-system/tm-core";
import { once } from "lodash";
import { Dispatch } from "react-redux";
import { fetchApiRoute } from "../helpers/fetch";

export type Action =
  | ReceiveCompaniesAction
  | StartEditingCompanyAction
  | ReceiveNewCompanyIdAction;

export const RECEIVE_COMPANIES = "company/receiveMany";
export type RECEIVE_COMPANIES_TYPE = typeof RECEIVE_COMPANIES;

export const RECEIVE_NEW_COMPANY_ID = "company/receiveNewCompanyId";
export type RECEIVE_NEW_COMPANY_ID_TYPE = typeof RECEIVE_NEW_COMPANY_ID;

export const START_EDITING_COMPANY = "company/startEditing";
export type START_EDITING_COMPANY_TYPE = typeof START_EDITING_COMPANY;

interface ReceiveCompaniesAction {
  type: RECEIVE_COMPANIES_TYPE;
  payload: Company[];
  meta: {
    page: number;
    perPage: number;
    total: number;
  };
}

export function receiveCompanies(response: {
  companies: Company[];
  page?: number;
  perPage?: number;
  total?: number;
}): ReceiveCompaniesAction {
  return {
    type: RECEIVE_COMPANIES,
    payload: response.companies,
    meta: {
      page: response.page,
      perPage: response.perPage,
      total: response.total,
    },
  };
}

interface ReceiveNewCompanyIdAction {
  type: RECEIVE_NEW_COMPANY_ID_TYPE;
  payload: number;
}

export function receiveNewCompanyId(id: number): ReceiveNewCompanyIdAction {
  return {
    type: RECEIVE_NEW_COMPANY_ID,
    payload: id,
  };
}

export const fetchCompanies = async (
  dispatch: Dispatch<any>,
  authToken: string,
  perPage?: number, // 25, 50, etc.
  page?: number // 1, 2, 3, etc.
) => {
  const base = "/api/v1/companies";
  let url = base;
  if (perPage && page) {
    url = base + `?perPage=${perPage}&page=${page}`;
  }
  const response = await fetchApiRoute(url, { authToken });
  if (response.ok) {
    const json = await response.json();
    dispatch(receiveCompanies(json));
  }
};

export const fetchAllCompaniesOnce = once(
  (dispatch: Dispatch<any>, authToken: string) => {
    return fetchCompanies(dispatch, authToken);
  }
);

interface StartEditingCompanyAction {
  type: START_EDITING_COMPANY_TYPE;
  payload: number | undefined;
}

export function startEditingCompany(
  payload?: number
): StartEditingCompanyAction {
  return {
    type: START_EDITING_COMPANY,
    payload,
  };
}
