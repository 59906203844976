import { Platform } from "@threatminder-system/tm-core";

export type Action =
  | ReceivePlatformsAction
  | UpdatePlatformAction
  | StartEditingPlatformAction;

export const RECEIVE_PLATFORMS = "platform/receiveMany";
export type RECEIVE_PLATFORMS_TYPE = typeof RECEIVE_PLATFORMS;

export const UPDATE_PLATFORM = "platform/update";
export type UPDATE_PLATFORM_TYPE = typeof UPDATE_PLATFORM;

export const START_EDITING_PLATFORM = "platform/startEditing";
export type START_EDITING_PLATFORM_TYPE = typeof START_EDITING_PLATFORM;

type MergeMethod = "MERGE" | "REPLACE";

interface ReceivePlatformsAction {
  type: RECEIVE_PLATFORMS_TYPE;
  payload: Platform[];
  meta: {
    mergeMethod: MergeMethod;
  };
}

export function receive(
  platforms: Platform[],
  mergeMethod: MergeMethod = "REPLACE"
): ReceivePlatformsAction {
  return {
    type: RECEIVE_PLATFORMS,
    payload: platforms,
    meta: {
      mergeMethod,
    },
  };
}

interface UpdatePlatformAction {
  type: UPDATE_PLATFORM_TYPE;
  payload: Platform;
  meta: {
    isNew: boolean;
  };
}

export function updatePlatform(
  payload: Platform,
  meta?: { isNew: boolean }
): UpdatePlatformAction {
  return {
    type: UPDATE_PLATFORM,
    payload,
    meta: {
      isNew: Boolean(meta && meta.isNew),
    },
  };
}

interface StartEditingPlatformAction {
  type: START_EDITING_PLATFORM_TYPE;
  payload: Number;
}

export function startEditingPlatform(
  payload: Number
): StartEditingPlatformAction {
  return {
    type: START_EDITING_PLATFORM,
    payload,
  };
}
