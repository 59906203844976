import * as React from "react";
import { getDistance, convertDistance } from "geolib";
import {
  Post as PostType,
  PostActionMatch as PostActionMatchType,
  PostCoordinateSource,
  Action as ActionType,
  Company,
} from "@threatminder-system/tm-core";
import { isPostActionMatchValid } from "../helpers/post-action-match";
import ActionMatchRow from "./action-match-row";
import SetMultipleActionMatches from "./set-multiple-action-matches";

import "./post-action-match.css";

// Note (gab): I'm not sure why I had to import this npm module this way,
// but using the import statement recommended on the module's GitHub page
// did not work (the imported variable was undefined at runtime):
//
//     import Highlighter from "react-highlight-words"
//
const Highlighter = require("react-highlight-words");

const hasPostBeenGeocodedByGoogle = (post: PostType) =>
  post.coordinateSource === PostCoordinateSource.GoogleGeocodingApi;

interface Props {
  onSubmit: (postActionMatch: PostActionMatchType[]) => Promise<void>;
  onChange: (postActionMatch: PostActionMatchType) => void;
  post: PostType;
  actionMatches: PostActionMatchType[];
  actionMap: Map<number, ActionType>;
  isSaving: boolean;
  hasInvalidActionMatch: boolean;
  companiesById: { [id: number]: Company };
}

interface State {
  overflowState: "clipped" | "expanded" | "fits" | void;
}

export class PostActionMatch extends React.Component<Props> {
  postBody: HTMLElement;
  state: State = {
    overflowState: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.shouldDisableSave()) {
      return false;
    }
    // notify parent component
    this.props.onSubmit(this.props.actionMatches);
  }

  componentDidMount() {
    if (this.postBody) {
      this.setState({
        overflowState:
          this.postBody.scrollHeight > this.postBody.clientHeight
            ? "clipped"
            : "fits",
      });
    }
  }

  shouldDisableSave() {
    const { isSaving, hasInvalidActionMatch } = this.props;
    return isSaving || hasInvalidActionMatch;
  }

  render() {
    const { actionMap, post, actionMatches, isSaving, companiesById } =
      this.props;

    // Collect threat words across all action matches for this post
    const threatWords = actionMatches.reduce(
      (wordsFromAllMatchedActions, actionMatch) => {
        const wordsFromThisAction =
          actionMatch.auditOptions.riskKeywordsMatched;
        return wordsFromAllMatchedActions.concat(wordsFromThisAction);
      },
      []
    );

    // Collect action names across all action matches for this post
    const actionTargetNames = actionMatches.map(
      (actionMatch) => actionMap.get(actionMatch.actionId).targetName
    );

    // Make a normalized version of action names for later comparison
    const actionTargetNamesNormalized = actionTargetNames.map((name) =>
      name.replace(/\W/g, "").toLowerCase()
    );

    const thePost = (
      <div className="panel-body">
        <h5>
          {post.title} [post id&nbsp;{post.id}]
        </h5>
        {hasPostBeenGeocodedByGoogle(post) && (
          <div>
            Detected location:{" "}
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${post.coordinates[0]},${post.coordinates[1]}`}
              target="_blank"
              rel="noreferrer"
            >
              {post.coordinates[0]}, {post.coordinates[1]}
            </a>
          </div>
        )}
        <div>Timestamp: {post.createdAt}</div>
        <small>
          <a
            className="post-source-url"
            target="_blank"
            rel="noopener noreferrer"
            href={post.sourceUrl}
          >
            {post.sourceUrl}
          </a>
        </small>
        <div
          className={`post-body ${this.state.overflowState}`}
          ref={(el) => (this.postBody = el)}
          onClick={() => {
            if (this.state.overflowState === "expanded") {
              this.setState({ overflowState: "clipped" });
            } else if (this.state.overflowState === "clipped") {
              this.setState({ overflowState: "expanded" });
            }
          }}
        >
          <Highlighter
            autoEscape={true} /* escape any special regexp chars */
            textToHighlight={post.content}
            searchWords={threatWords.concat(actionTargetNames)}
            highlightTag={({
              children,
              highlightIndex,
              className,
              ...rest
            }: any) => {
              const text = children;
              if (typeof text !== "string") {
                throw new Error("Highlight tag received non-text children");
              }
              if (
                actionTargetNamesNormalized.includes(
                  text.replace(/\W/g, "").toLowerCase()
                )
              ) {
                return (
                  <mark className="action-name-in-post" {...rest}>
                    {children}
                  </mark>
                );
              } else {
                return (
                  <mark className="threat-word-in-post" {...rest}>
                    {children}
                  </mark>
                );
              }
            }}
          />
          <div className="expand-curtain">Click to expand</div>
        </div>
      </div>
    );

    const theMatchesFromActionToPost = (
      <form className="panel-footer form-inline" onSubmit={this.onSubmit}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" colSpan={2}>
                Action, Co., User
              </th>
              <th scope="col" colSpan={3}>
                Audit info
              </th>
              <th scope="col" className="threat-th">
                <abbr title="High Risk">HR</abbr>
              </th>
              <th scope="col" className="x-detected-th">
                <abbr title="Sentiment Detected">SD</abbr>
              </th>
              <th scope="col">
                <abbr title="Sentiment Confirmed">SC</abbr>
              </th>
              <th scope="col" className="x-detected-th">
                <abbr title="Risk Detected">RD</abbr>
              </th>
              <th scope="col">
                <abbr title="Risk Confirmed">RC</abbr>
              </th>
              <th scope="col" className="threat-th">
                <abbr title="Threat Detected">TD</abbr>
              </th>
              <th scope="col" className="threat-th">
                <abbr title="Threat Confirmed">TC</abbr>
              </th>
            </tr>
          </thead>
          <tbody>
            {actionMatches.length > 1 && (
              <SetMultipleActionMatches
                actionMatches={actionMatches}
                onChange={this.props.onChange}
                label="Set for group below"
              />
            )}
            {actionMatches.map((postActionMatch: PostActionMatchType) => {
              const action = actionMap.get(postActionMatch.actionId);
              const row = [
                <ActionMatchRow
                  key={postActionMatch.id}
                  onChange={(p: PostActionMatchType) => this.props.onChange(p)}
                  action={action}
                  postActionMatch={postActionMatch}
                  companiesById={companiesById}
                />,
              ];
              if (action.isGeoFenced && hasPostBeenGeocodedByGoogle(post)) {
                const distanceInMeters = getDistance(
                  {
                    latitude: action.geoFenceCenter[0],
                    longitude: action.geoFenceCenter[1],
                  },
                  {
                    latitude: post.coordinates[0],
                    longitude: post.coordinates[1],
                  }
                );
                if (distanceInMeters > 0) {
                  const distanceInMiles = convertDistance(
                    distanceInMeters,
                    "mi"
                  );
                  const distanceEl = (
                    <div
                      key={postActionMatch.id + "-distance"}
                      className={`alert alert-${
                        distanceInMiles < 2 ? "danger" : "info"
                      } action-match-row-addon`}
                    >
                      Approximate distance from monitored location:{" "}
                      {distanceInMiles.toFixed(1)} miles
                    </div>
                  );
                  row.push(distanceEl);
                }
              }
              if (!isPostActionMatchValid(postActionMatch)) {
                const warning = (
                  <div
                    key={postActionMatch.id + "-warning"}
                    className="alert alert-warning action-match-row-addon"
                  >
                    If risk is 50 or above, you must lower the risk or check the
                    threat confirmed box. If you lower the risk below 50, you
                    must uncheck the threat confirmed box.
                  </div>
                );
                row.push(warning);
              }

              return row;
            })}
          </tbody>
        </table>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <button
              className="btn btn-default pull-right"
              disabled={this.shouldDisableSave()}
            >
              {isSaving ? "Saving ..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    );

    return (
      <div className="panel panel-default threatminder-post">
        {thePost}
        {theMatchesFromActionToPost}
      </div>
    );
  }
}
