
export function blankAction(): any {
  return {
    name: "",
    expression: "",
    options: {
      keywords: []
    },
  };
}

export function keywordsToValue(keywords: string[] = []): string {
  return keywords.join(",");
}

export function valueToKeywords(value: string): string[] {
  return value.split(",");
}
