import {
  Action,
  RECEIVE_RISK_KEYWORD_SETS,
} from "../actions/risk-keyword-sets";
import { StoreState } from "../types/risk-keyword-set";


const initialState: StoreState = {
  riskKeywordSets: [],
};

export default function platform(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_RISK_KEYWORD_SETS:
      return {
        ...state,
        riskKeywordSets: action.payload,
      };
    default:
      return state;
  }
}
