import { Upload } from "../components/upload";
import { connect, Dispatch } from "react-redux";
import { StoreState } from "../types";
import { receive } from "../actions/uploader";
import { fetchApiRoute } from "../helpers/fetch";

function mapStateToProps(state: StoreState) {
  return {
    authToken: state.auth.authToken,
    datasets: state.dataset.datasets,
    apiResults: state.uploader.apiResults,
    hasUploaded: state.uploader.hasUploaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onUploadDataSet: async (authToken: string, files: any): Promise<void> => {
      const formData = new FormData();
      files.forEach((f: any) => {
        formData.append("spreadsheet", f);
      });

      const apiResults = await fetchApiRoute(`/api/v1/datasets/upload`, {
        method: "POST",
        authToken,
        body: formData,
      });

      dispatch(receive(await apiResults.json()));
    }
  };
}

export const UploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload);
