import * as React from "react";
import Dropzone from "react-dropzone";
import { Dataset } from "@threatminder-system/tm-core";
import { ActionCsvUpload } from "./action-csv-upload";
import { CrawlerServiceCsvUpload } from "./crawler-service-csv-upload";

import "./upload.css";

interface Props {
  authToken: string;
  datasets: Dataset[];
  apiResults: any;
  hasUploaded: boolean;
  onUploadDataSet: (at: string, f: any) => Promise<void>;
}

interface State {
  hasSubmitted: boolean;
  accepted: Array<any>;
  rejected: Array<any>;
}

export class Upload extends React.Component<Props> {
  state: State = {
    hasSubmitted: false,
    accepted: [],
    rejected: [],
  };

  constructor(props: Props) {
    super(props);
    this.onUploadDataSet = this.onUploadDataSet.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  onUploadDataSet(authToken: string, a: any, r: any) {
    this.setState({
      accepted: a,
      rejected: r,
      hasSubmitted: true,
    });
    if (a.length !== 0) {
      this.props.onUploadDataSet(authToken, a);
    }
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    const { authToken, datasets, apiResults, hasUploaded } = this.props;

    return (
      <div>
        <ActionCsvUpload {...this.props} />

        {/* Domain Crawler (aka Scans) CSV Uploader/Importer */}
        <br />
        <div className="page-header">
          <h1>Upload Scans CSV</h1>
          <p>Create new scans in the system by uploading them as a csv file.</p>
          <p>
            This is the equivalent of using the Create Scan form at{" "}
            <a href="https://app.threatminder.com/home/scan/create">
              https://app.threatminder.com/home/scan/create
            </a>
            .
          </p>
          <p>
            There is a{" "}
            <a href="https://docs.google.com/spreadsheets/d/1bqNhGe7YKXHsSFhwjk7YKqY04RfHkXcsRL_NcLRc25c/edit?usp=sharing">
              scan csv upload template
            </a>{" "}
            on Google Sheets. This shows you exactly how the csv must be
            formatted in order to be accepted by the uploader.
          </p>
        </div>
        <div className="row">
          <CrawlerServiceCsvUpload authToken={authToken} />
        </div>
        <br />

        <div className="page-header">
          <h1>Upload Spreadsheet</h1>
        </div>
        <div className="row">
          <div
            className={
              this.state.hasSubmitted ? "dropzone finished" : "dropzone"
            }
          >
            <Dropzone
              accept=".csv"
              onDrop={(a: any, r: any) => {
                this.onUploadDataSet(authToken, a, r);
              }}
              disabled={this.state.hasSubmitted}
            >
              <h3 className="upload-instructions">
                <span className="glyphicon glyphicon-file btn-lg" />
                Drop files here to upload.
                <br />
                <span className="glyphicon glyphicon-hand-up btn-lg" />
                Or click to select files.
              </h3>
              <aside className={this.state.hasSubmitted ? "" : "hidden"}>
                <h3
                  className={this.state.accepted.length === 0 ? "hidden" : ""}
                >
                  Accepted Files
                  <br />
                  <small>Uploading spreadsheet for analysis.</small>
                </h3>
                <ul>
                  {this.state.accepted.map((f) => (
                    <li key={f.name}>
                      {f.name} - {f.size / 1000} kb
                    </li>
                  ))}
                </ul>
                <h3
                  className={this.state.rejected.length === 0 ? "hidden" : ""}
                >
                  Rejected Files
                  <br />
                  <small>
                    Files not uploaded. Please ensure all files are in a csv
                    format with the correct extensions.
                  </small>
                </h3>
                <ul>
                  {this.state.rejected.map((f) => (
                    <li key={f.name}>
                      {f.name} - {f.size} bytes
                    </li>
                  ))}
                </ul>
              </aside>

              <div
                className={
                  this.state.hasSubmitted
                    ? "api-results-container"
                    : "api-results-container hidden"
                }
              >
                <hr />
                <div
                  className={
                    hasUploaded
                      ? "spinner-container hidden"
                      : "spinner-container"
                  }
                >
                  <i className="fa fa-spinner fa-spin" />
                </div>
                <div
                  className={hasUploaded ? "api-results" : "api-results hidden"}
                >
                  <h3>Upload Results</h3>
                  <div
                    className={
                      typeof apiResults.error == "string" ? "" : "hidden"
                    }
                  >
                    <h4>
                      {typeof apiResults.error == "string"
                        ? apiResults.error
                        : ""}
                    </h4>
                  </div>
                  <div
                    className={
                      typeof apiResults.error == "string" ? "hidden" : ""
                    }
                  >
                    <h4>Errors</h4>
                    <ul>
                      {typeof apiResults.error == "string" ? (
                        <h4>{apiResults.error}</h4>
                      ) : (
                        apiResults.error.map((f: any) => (
                          <li key={f.name}>
                            {f.name}
                            <br />
                            <code>{f.error}</code>
                          </li>
                        ))
                      )}
                    </ul>
                    <h4>Successful Uploads</h4>
                    <ul>
                      {apiResults.success ? (
                        apiResults.success.map((f: any) => (
                          <li key={f.name}>{f.name}</li>
                        ))
                      ) : (
                        <br />
                      )}
                    </ul>
                  </div>
                </div>
                <br />
                <button type="button" onClick={this.refreshPage}>
                  {" "}
                  <span>Refresh page</span>{" "}
                </button>
              </div>
            </Dropzone>
          </div>
        </div>

        <div className="row">
          <h2>Datasets</h2>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Dataset Id</th>
                <th scope="col">Name</th>
                <th scope="col">Created At</th>
                <th scope="col">Total Posts</th>
                <th scope="col">
                  Posts
                  <br />
                  Inserted
                </th>
                <th scope="col">
                  Is Successful
                  <br />
                  Upload?
                </th>
              </tr>
            </thead>
            <tbody>
              {datasets.map((ds: Dataset) => (
                <tr key={ds.id}>
                  <th>{ds.id}</th>
                  <td>{ds.name}</td>
                  <td>{ds.createdAt}</td>
                  <td>{ds.totalPosts}</td>
                  <td>{ds.totalPostsInserted}</td>
                  <td>
                    {typeof ds.isUploadSuccess === "boolean"
                      ? ds.isUploadSuccess.toString()
                      : ds.isUploadSuccess}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
