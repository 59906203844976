import * as React from "react";
import { Platform } from "@threatminder-system/tm-core";
import { Platform as PlatformForm } from "./platform";

import "./platforms.css";

interface Props {
  authToken: string;
  platforms: Platform[];
  editingPlatform: Platform;
  onUpdatePlatform: (updatedNewPlatform: Platform) => void;
  onClickEditPlatform: (event: React.MouseEvent<HTMLElement>) => void;
  onSubmitPlatform: (
    event: React.MouseEvent<HTMLElement>,
    platform: Platform,
    authToken: string
  ) => void;
}

export const Platforms: React.FunctionComponent<Props> = (props: Props) => {
  const {
    platforms,
    editingPlatform,
    onClickEditPlatform,
    onUpdatePlatform,
    onSubmitPlatform,
    authToken,
  } = props;

  return (
    <div>
      <div className="page-header">
        <h1>Platforms</h1>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="list-group">
            <h2>All platforms</h2>
            {platforms.map((platform) => {
              const { id, name, url } = platform;
              const isEditing = editingPlatform.id === id;
              return (
                <div
                  key={id}
                  className={`list-group-item platform-item click-to-edit-platform ${
                    isEditing && "is-editing-platform"
                  }`}
                  data-id={id}
                  onClick={onClickEditPlatform}
                >
                  <span className="list-group-item-title">{name}</span>
                  {/*                    <button
                      data-id={id}
                      className="btn btn-danger pull-right"
                      onClick={event => onClickDeletePlatform(event, authToken)}
                    >
                      <span id="trashIcon" className="glyphicon glyphicon-trash" aria-hidden="true" />
                    </button>*/}
                  <br />
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Platform Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Url</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{id}</th>
                        <td>{name}</td>
                        <td>{url}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  {isEditing && (
                    <div className="row form-container">
                      <PlatformForm
                        platform={editingPlatform}
                        onUpdatePlatform={(_event, updatedPlatform) =>
                          onUpdatePlatform(updatedPlatform)
                        }
                      />
                      <SaveButton
                        onClick={(event) =>
                          onSubmitPlatform(event, editingPlatform, authToken)
                        }
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const SaveButton = (props: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <div className="form-group">
    <button
      className="btn btn-lg btn-primary btn-submit pull-right"
      onClick={props.onClick}
    >
      Save
    </button>
  </div>
);
