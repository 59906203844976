import { StoreState } from "../types/local-social-accounts";

export type Action = ReceiveLocalSocialAccountsAction;

export const RECEIVE_LOCAL_SOCIAL_ACCOUNTS = "localSocialAccounts/receiveMany";
export type RECEIVE_LOCAL_SOCIAL_ACCOUNTS_TYPE =
  typeof RECEIVE_LOCAL_SOCIAL_ACCOUNTS;

interface ReceiveLocalSocialAccountsAction {
  type: RECEIVE_LOCAL_SOCIAL_ACCOUNTS_TYPE;
  payload: StoreState;
}

export function receiveLocalSocialAccounts(
  apiResponseData: StoreState
): ReceiveLocalSocialAccountsAction {
  return {
    type: RECEIVE_LOCAL_SOCIAL_ACCOUNTS,
    payload: apiResponseData,
  };
}
