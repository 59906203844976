import * as React from "react";
import {
  Action as ActionModel,
  ActionTargetType,
  ActionInvestigationType,
  ActionDataCapState,
} from "@threatminder-system/tm-core";
import { valueToKeywords, keywordsToValue } from "../helpers/action";

interface ActionProps {
  action: ActionModel;
  onUpdateAction: (action: ActionModel) => void;
}

export class Action extends React.Component<ActionProps, ActionModel> {
  constructor(props: any) {
    super(props);
    this.state = props.action;
    this.updateAction = this.updateAction.bind(this);
  }

  private updateAction(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    // Because we named the inputs to match their corresponding values in state, it's
    // easy to update the state.
    const control: HTMLInputElement = e.currentTarget as HTMLInputElement;
    const nextState = { ...this.state };

    switch (control.name) {
      case "geoFenceCenterLatitude":
        nextState.geoFenceCenter[0] =
          control.value === "" ? null : Number(control.value);
        break;
      case "geoFenceCenterLongitude":
        nextState.geoFenceCenter[1] =
          control.value === "" ? null : Number(control.value);
        break;
      case "keywords":
        nextState.options.keywords = valueToKeywords(control.value);
        break;
      case "options.note":
        nextState.options.note = control.value;
        break;
      default:
        switch (control.type) {
          case "number":
            (nextState as any)[control.name] =
              control.value === "" ? null : Number(control.value);
            break;
          case "checkbox":
            (nextState as any)[control.name] = control.checked;
            break;
          default:
            (nextState as any)[control.name] = control.value;
        }
    }

    if (control.name === "isGeoFenced") {
      if (control.checked) {
        nextState.geoFenceSize = 10;
        nextState.geoFenceCenter = [0, 0];
      } else {
        nextState.geoFenceSize = null;
        nextState.geoFenceCenter = null;
      }
    }

    this.setState(nextState);
    this.props.onUpdateAction(nextState);
  }

  render() {
    const { action } = this.props;
    // console.log("Form render. action =", action); // useful for checking action.options updates
    return (
      <form>
        {/* Target Name */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="targetName">
            {" "}
            targetName
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="targetName"
              type="text"
              placeholder="A descriptive name"
              name="targetName"
              onChange={this.updateAction}
              value={action.targetName}
            />
          </div>
        </div>

        {/* Target Type */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="targetType">
            {" "}
            targetType
          </label>
          <div className="col-sm-9">
            <select
              className="form-control"
              id="targetType"
              name="targetType"
              onChange={this.updateAction}
              value={action.targetType}
            >
              {Object.keys(ActionTargetType as any).map((key) => (
                <option key={key} value={(ActionTargetType as any)[key]}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Investigation Type */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="investigationType">
            {" "}
            investigationType
          </label>
          <div className="col-sm-9">
            <select
              className="form-control"
              id="investigationType"
              name="investigationType"
              onChange={this.updateAction}
              value={action.investigationType}
            >
              {Object.keys(ActionInvestigationType).map((key) => (
                <option key={key} value={(ActionInvestigationType as any)[key]}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Other Investigation Type */}
        {action.investigationType === ActionInvestigationType.Other && (
          <div className="row form-group">
            <label
              className="col-sm-3 control-label"
              htmlFor="investigationTypeValue"
            >
              {" "}
              investigationTypeValue
            </label>
            <br />
            <div className="col-sm-9">
              <input
                className="form-control"
                type="text"
                id="investigationTypeValue"
                name="investigationTypeValue"
                onChange={this.updateAction}
                value={action.investigationTypeValue}
              />
            </div>
          </div>
        )}

        {/* Data cap state (default means defer to company's on/off) */}
        <div className="row form-group">
          <label className="col-sm-3 control-label"> dataCapState</label>
          <div className="col-sm-9">
            <div className="radio">
              <label className="radio">
                <input
                  className="form-control"
                  type="radio"
                  id="dataCapState.default"
                  name="dataCapState"
                  onChange={this.updateAction}
                  value={ActionDataCapState.Default}
                  checked={action.dataCapState === ActionDataCapState.Default}
                />
                Default - data cap for this action is turned on/off at company
                level
              </label>
            </div>
            <div className="radio">
              <label className="radio">
                <input
                  className="form-control"
                  type="radio"
                  id="dataCapState.off"
                  name="dataCapState"
                  onChange={this.updateAction}
                  value={ActionDataCapState.Off}
                  checked={action.dataCapState === ActionDataCapState.Off}
                />
                Off - deactivate data cap for this action, even if company has
                enabled data caps
              </label>
            </div>
            <div className="radio">
              <label className="radio">
                <input
                  className="form-control"
                  type="radio"
                  id="dataCapState.on"
                  name="dataCapState"
                  onChange={this.updateAction}
                  value={ActionDataCapState.On}
                  checked={action.dataCapState === ActionDataCapState.On}
                />
                On - activate data cap for this action, even if company has
                disabled data caps
              </label>
            </div>
          </div>
        </div>

        {/* Data cap */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="dataCap">
            {" "}
            dataCap
          </label>
          <div className="col-sm-9">
            <p>Number of post matches allowed per month:</p>
            <input
              className="form-control"
              type="number"
              step="1"
              id="dataCap"
              name="dataCap"
              onChange={this.updateAction}
              value={action.dataCap === null ? "" : action.dataCap}
            />
          </div>
        </div>

        {/* Is Geofenced? */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="isGeoFenced">
            {" "}
            isGeoFenced
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              type="checkbox"
              id="isGeoFenced"
              name="isGeoFenced"
              onChange={this.updateAction}
              checked={action.isGeoFenced}
            />
          </div>
        </div>

        {/* Geofence Size */}
        {(action.geoFenceSize || action.isGeoFenced) && (
          <div className="row form-group">
            <label className="col-sm-3 control-label" htmlFor="geoFenceSize">
              {" "}
              geoFenceSize (km)
            </label>
            <div className="col-sm-9">
              <input
                className="form-control"
                type="number"
                step="1"
                id="geoFenceSize"
                name="geoFenceSize"
                onChange={this.updateAction}
                value={action.geoFenceSize === null ? "" : action.geoFenceSize}
              />
            </div>
          </div>
        )}

        {/* Geofence Center */}
        {(action.geoFenceCenter || action.isGeoFenced) && (
          <div className="row form-group">
            <label
              className="col-sm-3 control-label"
              htmlFor="geoFenceCenterLatitude"
            >
              {" "}
              geoFenceCenter
            </label>
            <br />
            <div className="col-sm-9">
              <label>
                Latitude
                <input
                  className="form-control"
                  type="number"
                  step="1"
                  id="geoFenceCenterLatitude"
                  name="geoFenceCenterLatitude"
                  onChange={this.updateAction}
                  value={
                    action.geoFenceCenter[0] === null
                      ? ""
                      : action.geoFenceCenter[0]
                  }
                />
              </label>
              <label>
                Longitude
                <input
                  className="form-control"
                  type="number"
                  step="1"
                  id="geoFenceCenterLongitude"
                  name="geoFenceCenterLongitude"
                  onChange={this.updateAction}
                  value={
                    action.geoFenceCenter[1] === null
                      ? ""
                      : action.geoFenceCenter[1]
                  }
                />
              </label>
            </div>
          </div>
        )}

        {/* Keywords */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="keywords">
            {" "}
            keywords
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="keywords"
              type="text"
              placeholder="Enter keywords, comma-separated"
              name="keywords"
              onChange={this.updateAction}
              value={keywordsToValue(
                action?.options?.keywords?.length ? action.options.keywords : []
              )}
            />
          </div>
        </div>

        {/* Notes, directives for humans */}
        <div className="row form-group">
          <label className="col-sm-3 control-label" htmlFor="options.note">
            {" "}
            note
          </label>
          <div className="col-sm-9">
            <textarea
              className="form-control"
              id="options.note"
              placeholder="Enter notes (space will be preserved)"
              name="options.note"
              onChange={this.updateAction}
              value={((action.options && action.options.note) as string) || ""}
            />
          </div>
        </div>
      </form>
    );
  }
}
