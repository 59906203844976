import { PostActionMatchesHistory } from "../components/post-action-matches-history";
import { connect, Dispatch } from "react-redux";
import { StoreState } from "../types";
import { fetchApiRoute } from "../helpers/fetch";
import {
  receivePostActionMatchesHistory,
} from "../actions/post-action-matches-history";


function mapStateToProps({ auth, location: { query }, postActionMatchesHistory }: StoreState) {
  return {
    authToken: auth.authToken,
    selectedUserId: query && (query as any).userId,
    ...postActionMatchesHistory,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    selectUser(query: { userId: string, page: number, perPage: number }, authToken: string) {
      dispatch({
        type: "POST_ACTION_MATCHES_HISTORY",
        query,
      });
      const { userId, page, perPage } = query;
      fetchApiRoute(
        `/post-action-matches-history?userId=${userId}&page=${page}&perPage=${perPage}`, {
        authToken
      }).then(response => {
        if (response.ok) {
          return response.json().then(payload => {
            dispatch(receivePostActionMatchesHistory(payload));
          });
        }
      });
    }
  };
}

export const PostActionMatchesHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostActionMatchesHistory);
