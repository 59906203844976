import { PostActionMatch } from "@threatminder-system/tm-core";


export function isPostActionMatchValid(postActionMatch: PostActionMatch) {
  const { riskDetected, riskConfirmed, isConfirmedThreat } = postActionMatch;
  const riskConfirmedNotBlank = !isNaN(parseInt(riskConfirmed as any));

  // If the detected risk is 50 or above, then Portal admin must
  // either lower the risk score or check the box that confirms
  // this is a threat
  if (riskDetected >= 50) {
    if (!(
        // either check the box
        isConfirmedThreat ||
        // or lower the risk
        (riskConfirmedNotBlank && riskConfirmed < 50)
      )) {
      return false;
    }
  }

  // If Portal admin has confirmed that the post is a threat,
  // she cannot set the risk score below 50
  if (isConfirmedThreat) {
    if (riskConfirmedNotBlank && riskConfirmed < 50) {
      return false;
    }
  }

  // If Portal admin sets the risk score at 50 or above, she must
  // check the box that confirms this post as a threat
  if (riskConfirmedNotBlank && riskConfirmed >= 50) {
    if (!isConfirmedThreat) {
      return false;
    }
  }

  return true;
}
