import { getRole } from "../helpers/auth";
import { Role } from "../../shared-constants";

export type Action = AuthenticateAction | UnauthenticateAction;

export const AUTHENTICATE = "auth/authenticate";
export type AUTHENTICATE_TYPE = typeof AUTHENTICATE;

export interface AuthenticateAction {
  type: AUTHENTICATE_TYPE;
  payload: {
    authToken: string;
    role: Role;
  };
}

export function authenticate(token: string): AuthenticateAction {
  localStorage.setItem("authToken", token);
  return {
    type: AUTHENTICATE,
    payload: {
      authToken: token,
      role: getRole(token),
    },
  };
}

export const UNAUTHENTICATE = "auth/unauthenticate";
export type UNAUTHENTICATE_TYPE = typeof UNAUTHENTICATE;

export interface UnauthenticateAction {
  type: UNAUTHENTICATE_TYPE;
}

export function unauthenticate(): UnauthenticateAction {
  localStorage.removeItem("authToken");
  return {
    type: UNAUTHENTICATE,
  };
}
