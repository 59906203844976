import { Company } from "../components/company";
import { connect } from "react-redux";
import { StoreState } from "../types";


function mapStateToProps(state: StoreState) {
  const companyId = Number((state.location.payload as any).id);
  return {
    authToken: state.auth.authToken,
    company: state.company.companies[companyId],
  };
}

export const CompanyContainer = connect(
  mapStateToProps,
)(Company);
