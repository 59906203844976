import * as React from "react";
import Dropzone from "react-dropzone";
import { fetchApiRoute } from "../helpers/fetch";
import Link from "redux-first-router-link";
import ReactJson from "react-json-view";

import "./upload.css";

interface Props {
  authToken: string;
  onUploadDataSet: (at: string, f: any) => Promise<void>;
}

interface State {
  // before server response
  hasSubmitted: boolean;
  accepted: Array<any>;
  rejected: Array<any>;

  // after server response
  // has the server upload endpoint returned a response?
  hasServerResponse: boolean;
  // did the upload endpoint return an error?
  errorResponse: any;
  successResponse: any;
}

export class ActionCsvUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onUpload = this.onUpload.bind(this);
    this.reset = this.reset.bind(this);
    this.state = this.initState();
  }

  initState(): State {
    return {
      hasSubmitted: false,
      hasServerResponse: false,
      accepted: [],
      rejected: [],
      errorResponse: null,
      successResponse: null,
    };
  }

  onUpload(a: any, r: any) {
    const { authToken } = this.props;
    this.setState({
      accepted: a,
      rejected: r,
      hasSubmitted: true,
    });
    if (a.length !== 0) {
      this.upload(authToken, a);
    }
  }

  async upload(authToken: string, files: any) {
    const formData = new FormData();
    files.forEach((f: any) => {
      formData.append("spreadsheet", f);
    });

    const response = await fetchApiRoute(`/api/v1/actions/upload`, {
      method: "POST",
      authToken,
      body: formData,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      this.setState({ errorResponse });
      console.log(errorResponse);
    } else {
      this.setState({ successResponse: await response.json() });
    }

    this.setState({
      hasServerResponse: true,
    });
  }

  reset() {
    this.setState(this.initState());
  }

  render() {
    const { hasServerResponse, errorResponse, successResponse } = this.state;
    return (
      <div>
        <div className="page-header">
          <h1>Upload Actions CSV</h1>
          <p>
            Create new actions in the system by uploading them as a csv file.
          </p>
        </div>
        <div className="row">
          <div
            className={
              this.state.hasSubmitted ? "dropzone finished" : "dropzone"
            }
          >
            <Dropzone
              accept=".csv"
              onDrop={(a: any, r: any) => {
                this.onUpload(a, r);
              }}
              disabled={this.state.hasSubmitted}
            >
              <h3 className="upload-instructions">
                <span className="glyphicon glyphicon-file btn-lg" />
                Drop files here to upload.
                <br />
                <span className="glyphicon glyphicon-hand-up btn-lg" />
                Or click to select files.
              </h3>

              <aside className={this.state.hasSubmitted ? "" : "hidden"}>
                <h3
                  className={this.state.accepted.length === 0 ? "hidden" : ""}
                >
                  Files sent to server
                </h3>
                <ul>
                  {this.state.accepted.map((f) => (
                    <li key={f.name}>
                      {f.name} - {f.size / 1000} kb
                    </li>
                  ))}
                </ul>
                <h3
                  className={this.state.rejected.length === 0 ? "hidden" : ""}
                >
                  Files not sent to server
                  <br />
                  <small>
                    Files not uploaded. Please ensure all files are in a csv
                    format with the correct extensions.
                  </small>
                </h3>
                <ul>
                  {this.state.rejected.map((f) => (
                    <li key={f.name}>
                      {f.name} - {f.size} bytes
                    </li>
                  ))}
                </ul>
              </aside>

              <div
                className={
                  this.state.hasSubmitted
                    ? "api-results-container"
                    : "api-results-container hidden"
                }
              >
                <hr />
                <h2>Server response</h2>
                <div
                  className={
                    hasServerResponse
                      ? "spinner-container hidden"
                      : "spinner-container"
                  }
                >
                  <i className="fa fa-spinner fa-spin" />
                </div>
                <div
                  className={
                    hasServerResponse ? "api-results" : "api-results hidden"
                  }
                >
                  {errorResponse ? (
                    <div>
                      <h3 className="error">Error</h3>
                      <div className="error">
                        <p>
                          There was an error uploading the file. Double check
                          the file. Here is what the server returned:
                        </p>
                        <pre className="json">
                          {JSON.stringify(errorResponse)}
                        </pre>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3>Upload results</h3>

                      {successResponse && successResponse.errors.length && (
                        <div>
                          <h4>Errors</h4>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Line number</th>
                                <th>Error message</th>
                              </tr>
                            </thead>
                            <tbody>
                              {successResponse.errors.map(
                                (err: any, index: number) => (
                                  <tr key={index}>
                                    <td>{err.uploadFileLineNumber}</td>
                                    <td>
                                      <pre className="json">
                                        {JSON.stringify(err)}
                                      </pre>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}

                      {successResponse && successResponse.actions.length && (
                        <>
                          <h4>
                            Success
                            <br />
                            <small>
                              The following actions were saved to the database:
                            </small>
                          </h4>
                          <br />
                          {successResponse.actions.map((action: any) => (
                            <ReactJson key={action.id} src={action} />
                          ))}
                          <br />
                          <div>
                            You can also go to{" "}
                            <Link to="/actions">Actions</Link> to see the
                            actions you just uploaded.
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <br />
                <button type="button" onClick={this.reset}>
                  {" "}
                  <span>Start over</span>{" "}
                </button>
              </div>
            </Dropzone>
          </div>
        </div>
      </div>
    );
  }
}
