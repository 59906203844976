import {
  Action,
  RECEIVE_POST_ACTION_MATCHES,
  CHANGE_POST_ACTION_MATCH,
  MARK_SAVING,
  UNMARK_SAVING,
} from "../actions/post-action-match";
import { StoreState } from "../types/post-action-match";
import { SortByEnum, SortByOrderEnum } from "@threatminder-system/tm-core";

const initialState: StoreState = {
  postActionMatches: [],
  page: 1,
  perPage: 10,
  total: 0,
  sortBy: SortByEnum.createdAt,
  sortByOrder: SortByOrderEnum.DESC,
  companyIds: [],
  excludeCompany: false,
  actionIds: [],
  // Boolean for how to filter by action: include vs exclude
  excludeAction: false,
  hasLoaded: false,
  selectedPlatformIds: [],
  // ids of PAMs being saved
  saving: [],
};

export default function auth(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_POST_ACTION_MATCHES:
      return {
        ...state,
        ...action.payload,
        hasLoaded: true,
      };

    case CHANGE_POST_ACTION_MATCH:
      const post = state.postActionMatches.find(p => p.id === action.payload.id);
      const index = state.postActionMatches.indexOf(post);
      const posts = state.postActionMatches.map((p, i) => {
        if (i === index) {
          return action.payload;
        } else {
          return p;
        }
      });
      return { ...state, postActionMatches: posts };

    case MARK_SAVING: {
      const ids = new Set(state.saving);
      action.payload.map(id => ids.add(id));
      return {
        ...state,
        saving: Array.from(ids),
      };
    }

    case UNMARK_SAVING: {
      const ids = new Set(state.saving);
      action.payload.map(id => ids.delete(id));
      return {
        ...state,
        saving: Array.from(ids),
      };
    }

    default:
      return state;
  }
}
