import * as React from "react";
import ReactTooltip from "react-tooltip";
import Link from "redux-first-router-link";
import {
  PostActionMatch as PostActionMatchType,
  Action as ActionType,
  Company,
} from "@threatminder-system/tm-core";

import "./action-match-row.css";

const validateOnChangeConfirmed = (event: React.FormEvent<HTMLInputElement>) => {
  const { min, max, value } = event.currentTarget;
  // Prevent values that violate min/max constraints
  if (
    (min != null && Number(value) < Number(min)) ||
    (max != null && Number(value) > Number(max))
  ) {
    return false;
  }
  return true;
};

interface ConfirmedProps {
  value: string | number;
  onChange: (event: React.FormEvent<HTMLInputElement>) => any;
}

export const SentimentConfirmed = ({
  value,
  onChange,
}: ConfirmedProps) => {
  return (
    <input
      id="sentiment-confirmed-score-field"
      name="sentimentConfirmed"
      className="form-control"
      type="number"
      step=".01"
      min="-100"
      max="100"
      value={value}
      onChange={event => {
        if (!validateOnChangeConfirmed(event)) {
          return;
        }
        onChange(event);
      }}
    />
  );
};

export const RiskConfirmed = ({
  value,
  onChange,
}: ConfirmedProps) => {
  return (
    <input
      id="risk-confirmed-score-field"
      name="riskConfirmed"
      className="form-control"
      type="number"
      step=".01"
      min="0"
      max="100"
      value={value}
      onChange={event => {
        if (!validateOnChangeConfirmed(event)) {
          return;
        }
        onChange(event);
      }}
    />
  );
};


interface ActionMatchRowProps {
  onChange: (postActionMatch: PostActionMatchType) => void;
  action: ActionType;
  postActionMatch: PostActionMatchType;
  companiesById: {[id: number]: Company};
}

export default class ActionMatchRow extends React.Component<ActionMatchRowProps> {
  constructor(props: ActionMatchRowProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    const postActionMatch: PostActionMatchType = {
      ...this.props.postActionMatch,
      [name]: value === "" ? null : value
    };
    // notify parent component
    this.props.onChange(postActionMatch);
  }

  _onToggle(fieldName: "isHighRisk" | "isConfirmedThreat", checked: boolean) {
    const postActionMatch: PostActionMatchType = {
      ...this.props.postActionMatch,
      [fieldName]: checked,
    };
    // notify parent component
    this.props.onChange(postActionMatch);
  }

  onToggleIsHighRisk(checked: boolean) {
    this._onToggle("isHighRisk", checked);
  }

  onToggleIsThreat(checked: boolean) {
    this._onToggle("isConfirmedThreat", checked);
  }

  render() {
    const { action, postActionMatch, companiesById } = this.props;
    const { id: actionId, targetName: actionTargetName } = action;
    const { auditOptions } = postActionMatch;
    const company = companiesById[action.companyId];
    const actionNote = action.options.note;
    const companyNote = company.note;
    const { user } = (action as any);
    return (
      <tr>
        <td colSpan={2}>
          <strong data-tip={Boolean(actionNote)} data-for={`action-note-${action.id}`}>
            <Link to={`/actions/${action.id}`}>
              {actionTargetName} [<span title="action id">{actionId}</span>]
            </Link>
            {actionNote && " 📝"}
          </strong>
          {actionNote && (
            <ReactTooltip id={`action-note-${action.id}`}>
              <div className="note">
                {actionNote}
              </div>
            </ReactTooltip>
          )},
          <br />
          <span data-tip={Boolean(companyNote)} data-for={`company-note-${company.id}`}>
            <Link to={`/company/${company.id}`}>
              {company.name} [<span title="company id">{company.id}</span>]
            </Link>
            {companyNote && " 📝"}
            {user && ","}
          </span>
          {companyNote && (
            <ReactTooltip id={`company-note-${company.id}`}>
              <div className="note">
                {companyNote}
              </div>
            </ReactTooltip>
          )}
          {user && (
            <div>
              <Link to={`/users/${user.id}`}>
                {String(user.firstName)}{" "}
                {String(user.lastName)}{" "}
                [{user.id}]
              </Link>
            </div>
          )}
        </td>
        <td colSpan={3}>
          <div>
            <samp>
              {auditOptions.reason || "Could not find reason for audit."}
            </samp>
          </div>
          <div>
            <samp>
              {auditOptions.riskKeywordSetName || "Could not find risk keyword set name."}
            </samp>
          </div>
          <div>
            {(auditOptions.riskKeywordsMatched &&
              auditOptions.riskKeywordsMatched.length > 0) ? (
                <ul className="list-inline">
                  {(auditOptions.riskKeywordsMatched as string[]).map((keyword: string) =>
                    <li key={keyword}><code>{keyword}</code></li>
                  )}
                </ul>
              ) : <samp>Could not find matching risk keywords.</samp>
            }
          </div>
        </td>
        <td>
          <input
            type="checkbox"
            id="is-high-risk-checkbox"
            name="isHighRisk"
            className="form-control"
            onChange={(e) => this.onToggleIsHighRisk(e.currentTarget.checked)}
            checked={Boolean(postActionMatch.isHighRisk)}
          />
        </td>
        <td>{postActionMatch.sentimentDetected}</td>
        <td>
          <SentimentConfirmed
            value={
              postActionMatch.sentimentConfirmed === null ?
                ""
                : postActionMatch.sentimentConfirmed
            }
            onChange={this.onChange}
          />
        </td>
        <td>{postActionMatch.riskDetected}</td>
        <td>
          <RiskConfirmed
            value={
              postActionMatch.riskConfirmed === null ?
                ""
                : postActionMatch.riskConfirmed
            }
            onChange={this.onChange}
          />
        </td>
        <td>{postActionMatch.isDetectedThreat ?
          <span className="threat-detected-value" title="True">T</span>
          : <span className="threat-detected-value" title="False">F</span>}
        </td>
        <td>
          <input
            type="checkbox"
            id="is-threat-checkbox"
            name="isThreat"
            className="form-control"
            onChange={(e) => this.onToggleIsThreat(e.currentTarget.checked)}
            checked={Boolean(postActionMatch.isConfirmedThreat)}
          />
        </td>
      </tr>
    );
  }
}
