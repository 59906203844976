import { Dataset } from "@threatminder-system/tm-core";

export type Action = ReceiveDatasetsAction;

export const RECEIVE_DATASETS = "datasets/receiveMany";
export type RECEIVE_DATASETS_TYPE = typeof RECEIVE_DATASETS;

interface ReceiveDatasetsAction {
  type: RECEIVE_DATASETS_TYPE;
  payload: {
    datasets: Dataset[];
  };
}

export function receive(datasets: Dataset[]): ReceiveDatasetsAction {
  return {
    type: RECEIVE_DATASETS,
    payload: { datasets: datasets },
  };
}
