import "./db-table.css";


type Props = {
  rows: string[][],
  shouldShowTitle?: boolean,
};

export const DbTable = ({ rows, shouldShowTitle = true }: Props) => {
  const [head, ...body] = rows;

  return (
    <div>
      <div className="panel panel-default contains-table-hack">
        <div className="panel-body">

          {shouldShowTitle && (
            <>
              <h2 className="text-success">Database Table</h2>
              <p>The table below shows what is now in the database.</p>
            </>
          )}

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{/* 0. Leave blank. This is the top cell of a row count col */}</th>
                {head.map(h => (
                  <th key={h} scope="col">{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {body.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {row.map((v, colIndex) => (
                    <td key={colIndex}>{v}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
