import * as React from "react";
import { connect, Dispatch } from "react-redux";
import { NavbarContainer as Navbar } from "./containers/navbar";
import { LoginContainer as Login } from "./containers/login";
import { ActionsContainer as Actions } from "./containers/actions";
import { FiltersContainer as Filters } from "./containers/filters";
import { CompaniesContainer as Companies } from "./containers/companies";
import { CompanyContainer as Company } from "./containers/company";
import { DataCapsContainer as DataCaps } from "./containers/data-caps";
import { EmailCampaignsContainer as EmailCampaigns } from "./containers/email-campaigns";
import { PlatformsContainer as Platforms } from "./containers/platforms";
import { PostActionMatchesContainer as PostActionMatches } from "./containers/post-action-matches";
import { PostActionMatchesHistoryContainer as PostActionMatchesHistory } from "./containers/post-action-matches-history";
import { LocalSocialAccountsContainer as LocalSocialAccounts } from "./containers/local-social-accounts";
import { RiskKeywordSetsContainer as RiskKeywordSets } from "./containers/risk-keyword-sets";
import { SyncSheetsContainer as SyncSheets } from "./containers/sync-sheets";
import { UsersContainer as Users } from "./containers/users";
import { UploadContainer as Upload } from "./containers/upload";
import { StoreState } from "./types/index";

interface Props {
  authToken: string;
  page: string;
  dispatch: Dispatch<any>;
}

// TODO: fix this annotation
// NOTE: when adding a new page, don't forget to update reducers/page
const componentMap: any = {
  Actions,
  Filters,
  Companies,
  Company,
  DataCaps,
  EmailCampaigns,
  Platforms,
  PostActionMatches,
  PostActionMatchesHistory,
  LocalSocialAccounts,
  Login,
  RiskKeywordSets,
  SyncSheets,
  Users,
  Upload,
  NotFound: () => <div>Could not find that page</div>,
};

const App: React.StatelessComponent<Props> = (props: Props) => {
  const { authToken, page } = props;
  const Page = componentMap[page];

  let inner;

  if (!authToken) {
    inner = <Login />;
  } else if (Page) {
    inner = <Page />;
  }

  return (

    <div className="App">
      {Page !== Login &&
        <Navbar displayOnlyHeader={!Page || page === "NotFound"} />
      }

      <div className="container">
        {inner}
      </div>

      <footer className="footer">
        <div className="container">
          <hr />
          <span className="text-muted">
            Copyright © 2017 ThreatMinder
                  </span>
        </div>
      </footer>

    </div>

  );
};

function mapStateToProps(state: StoreState) {
  return {
    authToken: state.auth.authToken,
    page: state.page,
  };
}

export default connect(mapStateToProps)(App);
