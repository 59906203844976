import { StoreState } from "../types/post-action-matches-history";

export type Action =
  | ReceivePostActionMatchesHistoryAction
  | ReceivePortalUsersAction;

export const RECEIVE_POST_ACTION_MATCHES_HISTORY =
  "postActionMatchesHistory/receiveMany";
export type RECEIVE_POST_ACTION_MATCHES_HISTORY_TYPE =
  typeof RECEIVE_POST_ACTION_MATCHES_HISTORY;

export const RECEIVE_PORTAL_USERS =
  "postActionMatchesHistory/receivePortalUsers";
export type RECEIVE_PORTAL_USERS_TYPE = typeof RECEIVE_PORTAL_USERS;

export interface PostActionMatchesHistoryQueryResponse {
  postActionMatchUpdates: [];
  page: number;
  perPage: number;
  total: number;
}

interface ReceivePostActionMatchesHistoryAction {
  type: RECEIVE_POST_ACTION_MATCHES_HISTORY_TYPE;
  payload: PostActionMatchesHistoryQueryResponse;
}

export function receivePostActionMatchesHistory(
  response: PostActionMatchesHistoryQueryResponse
): ReceivePostActionMatchesHistoryAction {
  return {
    type: RECEIVE_POST_ACTION_MATCHES_HISTORY,
    payload: response,
  };
}

interface ReceivePortalUsersAction {
  type: RECEIVE_PORTAL_USERS_TYPE;
  payload: StoreState["portalUsers"];
}

export function receivePortalUsers(
  portalUsers: StoreState["portalUsers"]
): ReceivePortalUsersAction {
  return {
    type: RECEIVE_PORTAL_USERS,
    payload: portalUsers,
  };
}
