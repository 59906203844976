import { Action } from "@threatminder-system/tm-core";
import { Actions } from "../components/actions";
import { connect, Dispatch } from "react-redux";
import { MouseEvent } from "react";
import { StoreState } from "../types";
import { blankAction } from "../helpers/action";
import {
  receive as receiveActions,
  updateAction,
  changeDataPullStatus,
  startEditingAction,
} from "../actions/action";
import { fetchApiRoute } from "../helpers/fetch";


function mapStateToProps({ auth, action, company, location }: StoreState) {
  const actionId = (location.payload as any).id;
  const singleAction = action.actions[actionId];
  return {
    authToken: auth.authToken,
    newAction: action.newAction,
    editingAction: action.editingAction,
    actions: singleAction ? [singleAction] : action.forPage,
    dataPullStatus: action.dataPullStatus,
    companies: company.companies,
    page: action.page,
    perPage: action.perPage,
    total: action.total,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onUpdateAction(updatedAction: Action) {
      dispatch(updateAction(updatedAction, { isNew: !updatedAction.id }));
    },
    onClickDeleteAction(event: MouseEvent<HTMLElement>, authToken: string) {
      event.preventDefault();
      const yes = window.confirm("Are you sure you want to delete this action?");
      if (!yes) {
        return;
      }
      const { currentTarget: { dataset: { id } } } = event;
      fetchApiRoute(`/api/v1/actions/${id}`, {
        method: "DELETE",
        authToken,
      }).then(response => {
        if (response.ok) {
          // Deleting an action can cascade to deletions of other models,
          // so it's best to reload the whole application to ensure it is
          // synced with the server state
          window.location.reload();
        } else {
          response.json().then(obj => {
            window.alert(`Could not delete action:\n ${obj.error}`);
          });
        }
      });
    },
    onClickEditAction(event: MouseEvent<HTMLElement>) {
      const { currentTarget: { dataset: { id } } } = event;
      dispatch(startEditingAction(Number(id)));
    },
    onClickPullAction(event: MouseEvent<HTMLElement>, authToken: string) {
      event.preventDefault();
      event.stopPropagation();
      const yes = window.confirm("Are you sure you want to issue a data pull for this action?");
      if (!yes) {
        return;
      }
      const { currentTarget: { dataset: { id } } } = event;
      fetchApiRoute(`/api/v1/webhose-ondemand?actionId=${id}`, {
        method: "POST",
        authToken,
      }).then(response => {
        dispatch(changeDataPullStatus({
          actionId: Number(id),
          ok: response.ok,
        }));
      });
    },
    onSubmitAction(event: MouseEvent<HTMLElement>, action: Action, authToken: string) {
      event.preventDefault();
      const isNew = !action.id;
      if (action?.options?.requiredKeywords?.length) {
        action.options.requiredKeywords = action.options.requiredKeywords
          .map(kw => kw.trim())
          .filter(kw => kw !== "");
      }
      if (action?.options?.keywords?.length) {
        action.options.keywords = action.options.keywords
          .map(kw => kw.trim())
          .filter(kw => kw !== "");
      }
      const body = { actions: [action] };
      fetchApiRoute(isNew ? `/api/v1/actions` : `/api/v1/actions/${action.id}`, {
        method: isNew ? "POST" : "PUT",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
          response.json().then(obj => {
            window.alert("Success");
            dispatch(receiveActions(obj));
            dispatch(updateAction(blankAction(), { isNew }));
          });
        } else {
          response.json().then(obj => {
            window.alert(`Could not save action:\n ${obj.error}`);
          });
        }
      });
    }
  };
}

export const ActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Actions);
