import { Platform } from "@threatminder-system/tm-core";
import { Platforms } from "../components/platforms";
import { connect, Dispatch } from "react-redux";
import { MouseEvent } from "react";
import { StoreState } from "../types";
import {
  receive as receivePlatforms,
  updatePlatform,
  startEditingPlatform,
} from "../actions/platform";
import { fetchApiRoute } from "../helpers/fetch";


function mapStateToProps({ auth, platform }: StoreState) {
  return {
    authToken: auth.authToken,
    editingPlatform: platform.editingPlatform,
    platforms: platform.platforms,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onUpdatePlatform(updatedPlatform: Platform) {
      dispatch(updatePlatform(updatedPlatform, { isNew: !updatedPlatform.id }));
    },
    onClickEditPlatform(event: MouseEvent<HTMLElement>) {
      const { currentTarget: { dataset: { id } } } = event;
      dispatch(startEditingPlatform(Number(id)));
    },
    onSubmitPlatform(event: MouseEvent<HTMLElement>, platform: Platform, authToken: string) {
      event.preventDefault();
      const isNew = !platform.id;
      const body = { platforms: [platform] };
      fetchApiRoute(isNew ? `/api/v1/platforms` : `/api/v1/platforms/${platform.id}`, {
        method: isNew ? "POST" : "PUT",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
          response.json().then(obj => {
            window.alert("Success");
            dispatch(receivePlatforms(obj.platforms, "MERGE"));
            dispatch(updatePlatform({} as Platform));
          });
        } else {
          response.json().then(obj => {
            window.alert(`Could not save platform:\n ${obj.error}`);
          });
        }
      });
    }
  };
}

export const PlatformsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Platforms);
