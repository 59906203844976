import * as React from "react";
import Link from "redux-first-router-link";
import { Filter } from "@threatminder-system/tm-core";
import { Filter as FilterForm } from "./filter";
import { Paginator } from "./paginator";
import ReactJson from "react-json-view";

import "./filters.css";

interface Props {
  authToken: string;
  filters: Filter[];
  page: number;
  perPage: number;
  total: number;
  newFilter: Filter;
  editingFilter: Filter;
  onUpdateFilter: (updatedNewFilter: Filter) => void;
  onClickDeleteFilter: (event: React.MouseEvent<HTMLElement>, authToken: string) => void;
  onClickEditFilter: (event: React.MouseEvent<HTMLElement>) => void;
  onSubmitFilter: (event: React.MouseEvent<HTMLElement>, filter: Filter, authToken: string) => void;
}

export const Filters: React.FunctionComponent<Props> = (props: Props) => {
  const {
    filters,
    page,
    perPage,
    total,
    editingFilter,
    onClickDeleteFilter,
    onClickEditFilter,
    onUpdateFilter,
    onSubmitFilter,
    authToken,
  } = props;

  return (
    <div>
      <div className="page-header">
        <h1>Filters</h1>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">

          {/*          <div className="row form-container">
            <h2>Create new filter</h2>
            <FilterForm
              filter={newFilter}
              onUpdateFilter={onUpdateFilter}
            />
            <SaveButton onClick={event => onSubmitFilter(event, newFilter, authToken)} />
          </div>*/}

          <div className="list-group">
            <h2>All filters</h2>
            <div>Click on a filter to edit it</div>
            <br />
            {filters.map(
              (filter) => {
                const { id, name, isActive, createdAt } = filter;
                const isEditing = editingFilter.id === id;
                return (
                  <div
                    key={id}
                    className={`list-group-item click-to-edit-filter ${isEditing && "is-editing-filter"}`}
                    data-id={id}
                    onClick={onClickEditFilter}
                  >
                    {name && <span className="list-group-item-title">{name}</span>}
                    <button
                      data-id={id}
                      className="btn btn-danger pull-right"
                      onClick={event => onClickDeleteFilter(event, authToken)}
                    >
                      <span id="trashIcon" className="glyphicon glyphicon-trash" aria-hidden="true" />
                    </button>
                    <div><Link to={`/actions/${filter.actionId}`}>Link to action id {filter.actionId}</Link></div>
                    <div>Created at: {createdAt}</div>
                    <div>Is active? {String(isActive)}</div>
                    <div>Options</div>
                    <ReactJson src={filter.options} />
                    {isEditing && (
                      <div key={id} className="row form-container">
                        <FilterForm
                          filter={editingFilter}
                          onUpdateFilter={onUpdateFilter}
                        />
                        <SaveButton
                          onClick={event => onSubmitFilter(event, editingFilter, authToken)}
                        />
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>

        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          pageType="FILTERS"
          getPageQuery={pageNum => ({
            page: pageNum,
            perPage: perPage,
          })}
        />
      </div>
    </div>
  );
};



const SaveButton = (props: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
}) => (
    <div className="form-group">
      <button
        className="btn btn-lg btn-primary btn-submit pull-right"
        onClick={props.onClick}
      >
        Save
      </button>
    </div>
  );
