import * as ReactDOM from "react-dom";

import App from "./ui/app";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./ui/configure-store";

import "./ui/index.css";


const history = createBrowserHistory();
const authToken = localStorage.getItem("authToken") || undefined;
const { store } = configureStore(history, {
  auth: { authToken },
});

ReactDOM.render(
  (
    <Provider store={store}>
      <App />
    </Provider>
  ),
  document.getElementById("root") as HTMLElement
);
