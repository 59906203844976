import * as React from "react";
import { Platform as PlatformType } from "@threatminder-system/tm-core";
import {
  PLATFORM_NAME_MAX_LENGTH,
  PLATFORM_URL_MAX_LENGTH,
} from "../../shared-constants";

interface Props {
  platform: PlatformType;
  onUpdatePlatform: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, updatedPlatform: PlatformType) => void;
}

export const Platform: React.FunctionComponent<Props> = (props: Props) => {
  const {
    platform,
    onUpdatePlatform,
  } = props;
  return (
    <div>

      <div className="form-group">
        <label className="control-label" htmlFor="platform-name">Platform Name</label>
        <input
          type="text"
          maxLength={PLATFORM_NAME_MAX_LENGTH}
          className="form-control"
          id="platform-name"
          required={true}
          value={platform.name || ""}
          onChange={event => {
            const { currentTarget: { value } } = event;
            onUpdatePlatform(event, { ...platform, name: value });
          }}
        />
      </div>

      <div className="form-group">
        <label className="control-label" htmlFor="platform-name">Platform Url</label>
        <input
          type="text"
          maxLength={PLATFORM_URL_MAX_LENGTH}
          className="form-control"
          id="platform-url"
          required={true}
          value={platform.url || ""}
          onChange={event => {
            const { currentTarget: { value } } = event;
            onUpdatePlatform(event, { ...platform, url: value });
          }}
        />
      </div>

    </div>
  );
};
