import * as React from "react";
import { Action, Company, ActionDataCapState } from "@threatminder-system/tm-core";
import { FixedSizeList as List } from "react-window";
import { StoreState as ActionStoreState } from "../types/action";

import "./data-caps.css";


type CompanyWithAction = Company & { actions: Action[] };
interface Props {
  companiesWithActions: CompanyWithAction[];
  actionDataCapEdits: ActionStoreState["dataCapEdits"];
  authToken: string;
  onChangeCompanyIsDataCapEnabled: (company: Company, isDataCapEnabled: boolean, authToken: string) => Promise<void>;
  onChangeActionDataCapState: (action: Action, value: string, authToken: string) => Promise<void>;
  onChangeActionDataCap: (action: Action, value: string) => void;
  onSaveActionDataCap: (action: Action, value: number, authToken: string) => Promise<void>;
}

export const DataCaps: React.FunctionComponent<Props> = (props: Props) => {
  const {
    companiesWithActions,
    actionDataCapEdits,
    authToken,
    onChangeCompanyIsDataCapEnabled,
    onChangeActionDataCapState,
    onChangeActionDataCap,
    onSaveActionDataCap,
  } = props;

  const [expandedCompanyIndex, setExpandedCompanyIndex] = React.useState<number>(-1);

  const handleCompanyExpansion = (index: number) => {
    if (index === expandedCompanyIndex) {
      setExpandedCompanyIndex(-1);
      return;
    }
    setExpandedCompanyIndex(index);
  };

  return (
    <div className="data-caps-container">
      <div className="page-header">
        <h1>Data Caps</h1>
        <p className="help-block">&nbsp;Click on company name to expand.</p>
      </div>
      <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
        {companiesWithActions.map((company, index) => {
          const { id, actions, name, note, isDataCapEnabled } = company;
          return (
            <div key={id} className={`panel ${isDataCapEnabled ? "" : "panel-default"}`}>
              <div className="panel-heading clearfix" role="tab" id={`heading${id}`}>
                <form className="form-inline pull-right">
                  <div className="form-group">
                    <label className="checkbox-inline">
                      <input
                        id={`checkbox-data-cap-company-${id}`}
                        className="form-control"
                        type="checkbox"
                        checked={isDataCapEnabled}
                        onChange={({ currentTarget: { checked: isDataCapEnabled } }) =>
                          onChangeCompanyIsDataCapEnabled(company, isDataCapEnabled, authToken)
                        }
                      />{isDataCapEnabled ? "Default = on" : "Default = off"}
                    </label>
                  </div>
                </form>
                <h4 className="panel-title">
                  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href={`#collapse${id}`} aria-expanded="false" aria-controls={`collapse${id}`} onClick={(e) => handleCompanyExpansion(index)}>
                    {name} [<span title="company id">{id}</span>]
                  </a>
                  <small> {note}</small>
                </h4>
              </div>
              <div id={`collapse${id}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`heading${id}`}>
                {!actions.length ?
                  <div className="panel-body">No actions for this company.</div> :
                  expandedCompanyIndex !== -1 ? (
                    <List className="list-group" height={500} width="100%" itemCount={companiesWithActions[expandedCompanyIndex].actions.length} itemSize={50} overscanCount={5}>
                      {({ index, style }) => (
                        <ActionItem
                          key={companiesWithActions[expandedCompanyIndex].actions[index].id}
                          action={companiesWithActions[expandedCompanyIndex].actions[index]}
                          isDataCapActive={isActionDataCapActive(companiesWithActions[expandedCompanyIndex].actions[index], company)}
                          dataCapEdit={actionDataCapEdits[companiesWithActions[expandedCompanyIndex].actions[index].id]}
                          authToken={authToken}
                          onChangeActionDataCap={onChangeActionDataCap}
                          onChangeActionDataCapState={onChangeActionDataCapState}
                          onSaveActionDataCap={onSaveActionDataCap}
                          style={style}
                        />
                      )}
                    </List>
                  ) : null}
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
};


function isActionDataCapActive(action: Action, company: Company): boolean {
  if (action.dataCapState === ActionDataCapState.Default) {
    return company.isDataCapEnabled;
  } else {
    return action.dataCapState === ActionDataCapState.On;
  }
}


type ActionItemProps = {
  action: Action,
  isDataCapActive: boolean,
  dataCapEdit: string,
  style: React.CSSProperties,
} & Pick<Props,
  | "authToken"
  | "onChangeActionDataCap"
  | "onChangeActionDataCapState"
  | "onSaveActionDataCap"
>;

function ActionItem(props: ActionItemProps) {
  const {
    action,
    isDataCapActive,
    dataCapEdit,
    authToken,
    onChangeActionDataCap,
    onChangeActionDataCapState,
    onSaveActionDataCap,
    style,
  } = props;
  const hasNewDataCapValue = dataCapEdit !== void dataCapEdit;
  const newDataCapValue = dataCapEdit;
  const showSaveButton = hasNewDataCapValue && newDataCapValue !== "" &&
    Number(newDataCapValue) !== action.dataCap;
  return (
    <li
      style={style}
      className={`list-group-item clearfix ${isDataCapActive ? "" : "disabled"}`}
    >
      {action.targetName}
      <form
        className="form-inline pull-right"
        onSubmit={event => {
          event.preventDefault();
          const value = newDataCapValue;
          if (value !== "" && !isNaN(value as any)) {
            onSaveActionDataCap(action, Number(value), authToken);
          }
        }}
      >
        <div className="form-group">
          <label htmlFor={`data-cap-${action.id}`}>Data cap</label>{" "}
          <span className="input-group">
            <input
              type="number"
              className="form-control data-cap-input"
              id={`data-cap-${action.id}`}
              value={hasNewDataCapValue ? newDataCapValue : action.dataCap}
              onChange={({ currentTarget: { value } }) => onChangeActionDataCap(action, value)}
            />
            <span className={"input-group-btn" + (showSaveButton ? "" : " invisible")}>
              <button
                className="btn btn-default"
              >
                Save
              </button>
            </span>
          </span>
        </div>{" "}
        <div className="form-group">
          <label className="radio-inline">
            <input
              type="radio"
              className="form-control"
              checked={action.dataCapState === ActionDataCapState.On}
              value={ActionDataCapState.On}
              onChange={({ currentTarget: { value } }) => onChangeActionDataCapState(action, value, authToken)}
            />
            On
          </label>
          <label className="radio-inline">
            <input
              type="radio"
              className="form-control"
              value={ActionDataCapState.Off}
              checked={action.dataCapState === ActionDataCapState.Off}
              onChange={({ currentTarget: { value } }) => onChangeActionDataCapState(action, value, authToken)}
            />
            Off
          </label>
          <label className="radio-inline">
            <input
              type="radio"
              className="form-control"
              value={ActionDataCapState.Default}
              checked={action.dataCapState === ActionDataCapState.Default}
              onChange={({ currentTarget: { value } }) => onChangeActionDataCapState(action, value, authToken)}
            />
            Use Default
          </label>
        </div>

      </form>
    </li>
  );
}
