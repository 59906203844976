import {
  Action,
  RECEIVE_LOCAL_SOCIAL_ACCOUNTS,
} from "../actions/local-social-accounts";
import { StoreState } from "../types/local-social-accounts";


const initialState: StoreState = {
  localSocialAccounts: [],
  removed: [],
  droppedRows: [],
};

export default function platform(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_LOCAL_SOCIAL_ACCOUNTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
