import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { connectRoutes, redirect, NOT_FOUND } from "redux-first-router";
import matchPath from "rudy-match-path";
import * as queryString from "query-string";
import { History } from "history";
import { Dispatch } from "react-redux";
import { authenticate } from "./actions/auth";
import routesMap from "./routes-map";
import * as reducers from "./reducers";
import { StoreState } from "./types";
import { Role } from "../shared-constants";
import { isAuthTokenStillInEffect } from "./helpers/auth";
import { fetchApiRoute } from "./helpers/fetch";

const UNRESTRICTED_PAGES = [
  "INDEX",
  "LOGIN",
  "POST_ACTION_MATCHES",
  NOT_FOUND,
];

const configureStore = (history: History, preLoadedState?: Partial<StoreState>) => {
  const options: any = {
    querySerializer: queryString,
    createHistory: () => { return history; },
    onBeforeChange: (dispatch: Dispatch<any>, getState: any, { action }: any) => {
      const {
        auth: { authToken, role },
      } = getState();

      const redirectPrefix = "REDIRECT/";
      if (action.type.startsWith(redirectPrefix)) {
        const typeToRedirect = action.type.slice(redirectPrefix.length);
        dispatch(redirect({ ...action, type: typeToRedirect }));
        return;
      }

      // If auth token is expired, redirect user to login page
      if (
        !isAuthTokenStillInEffect(authToken) &&
        action.type !== "LOGIN" // IMPORTANT! don't get into infinite redirect loop
      ) {
        dispatch(redirect({ type: "LOGIN" }));
        return;
      }

      // If user is trying to go to restricted page but does not have unrestricted
      // access, redirect to 404
      if (role !== Role.UNRESTRICTED && !UNRESTRICTED_PAGES.includes(action.type)) {
        dispatch(redirect({ type: NOT_FOUND }));
        return;
      }
    },
    notFoundPath: null,
    initialDispatch: false, // called below
  };

  const { reducer, middleware, enhancer, thunk, initialDispatch } = connectRoutes(
    routesMap, {
      ...options,
    }
  );

  const rootReducer = combineReducers({ ...reducers, location: reducer } as any);
  const middlewares = applyMiddleware(middleware);
  const enhancers = compose(enhancer, middlewares);

  const store = createStore(rootReducer, preLoadedState, enhancers as any);
  const state = store.getState();
  const { authToken } = (state as any).auth;

  // If we're at /login page, don't check the auth token
  const atLogin = matchPath(history.location.pathname, { path: routesMap.LOGIN.path, exact: true });
  if (atLogin) {
    initialDispatch();
  } else {
    // otherwise, check auth token
    // if no auth token, redirect to login page
    if (!authToken) {
      history.replace(routesMap.LOGIN.path);
      initialDispatch();
    } else {
      // otherwise, check with server to see if auth token is valid
      fetchApiRoute("/api/v1/auth/is-valid", { authToken }).then(response => {
        // if auth token is valid, carry on
        if (response.ok) {
          store.dispatch(authenticate(authToken));
          initialDispatch();
        }
        // (if not valid, fetchApiRoute() will redirect to login)
      });
    }
  }

  return { store, thunk };
};

export default configureStore;
