export interface Action {
  type?: string;
}

export function goHome() {
  return {
    type: "HOME",
  };
}

// NOTE (gab): because action.type here is not prefixed -- i.e.,
// action.type = 'PAGE_NAME' vs action.type = 'page/PAGE_NAME' --
// whenever we dispatch this action, it's also seen and handled
// by redux-first-router
export function goToPage(type: string, payload?: Object, meta?: Object) {
  return {
    type,
    payload,
    meta
  };
}
