import { Action as RFRAction } from "redux-first-router";
import {
  Action,
  RECEIVE_POST_ACTION_MATCHES_HISTORY,
  RECEIVE_PORTAL_USERS,
} from "../actions/post-action-matches-history";
import { StoreState } from "../types/post-action-matches-history";


const initialState: StoreState = {
  postActionMatchUpdates: [],
  page: 1,
  perPage: 25,
  total: 0,
  isDataFetched: false,
  portalUsers: [],
};

export default function auth(state: StoreState = initialState, action: Action | RFRAction): StoreState {
  switch (action.type) {
    case RECEIVE_POST_ACTION_MATCHES_HISTORY:
      return {
        ...state,
        ...action.payload,
        isDataFetched: true,
      };

    case RECEIVE_PORTAL_USERS:
      return {
        ...state,
        portalUsers: action.payload as StoreState["portalUsers"],
      };

    // This intercepts a dispatched route action
    case "POST_ACTION_MATCHES_HISTORY": {
      type Query = { userId: string, page: string, perPage: string };
      const query = (action as RFRAction).meta.query as Query | void;
      const prevQuery = (action as RFRAction).meta.location.prev.query as Query | void;

      const userId = query && query.userId;
      const prevUserId = prevQuery && prevQuery.userId;

      const pageQuery = {
        page: (query && query.page) ? Number(query.page) : initialState.page,
        perPage: (query && query.perPage) ? Number(query.perPage) : initialState.perPage,
      };

      if (userId === prevUserId) {
        return {
          ...state,
          ...pageQuery,
        };
      } else {
        const portalUsers = state.portalUsers;
        // reset
        return {
          ...state,
          ...initialState,
          ...pageQuery,
          portalUsers,
        };
      }
    }

    default:
      return state;
  }
}
