import { LocalSocialAccounts } from "../components/local-social-accounts";
import { connect } from "react-redux";
import { StoreState } from "../types";


function mapStateToProps({ localSocialAccounts }: StoreState) {
  return {
    localSocialAccounts: localSocialAccounts.localSocialAccounts,
    removed: localSocialAccounts.removed,
    droppedRows: localSocialAccounts.droppedRows,
  };
}

export const LocalSocialAccountsContainer = connect(
  mapStateToProps,
)(LocalSocialAccounts);
