import * as React from "react";
import { Filter as FilterModel } from "@threatminder-system/tm-core";
import { BasicObjectEditor } from "./basic-object-editor";


interface FilterProps {
  filter: FilterModel;
  onUpdateFilter: (filter: FilterModel) => void;
}

export class Filter extends React.Component<FilterProps, FilterModel> {

  constructor(props: any) {
    super(props);
    this.state = props.filter;
    this.updateFilter = this.updateFilter.bind(this);
  }

  private updateFilter(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    // Because we named the inputs to match their corresponding values in state, it's
    // easy to update the state.
    const control: HTMLInputElement = e.currentTarget as HTMLInputElement;
    const nextState = {
      ...this.state,
      [control.name]: control.type === "checkbox" ? control.checked : control.value,
    };
    this.setState(nextState);
    this.props.onUpdateFilter(nextState);
  }

  render() {
    const { filter } = this.props;
    // console.log("Form render. filter =", filter); // useful for checking filter.options updates
    return (
      <form>

        {/* Filter Name */}
        <div className="row form-group">
          <label
            className="col-sm-2 control-label"
            htmlFor="name"
          > Name
          </label>
          <div className="col-sm-10">
            <input
              className="form-control"
              id="name"
              type="text"
              placeholder="A descriptive name"
              name="name"
              onChange={this.updateFilter}
              value={filter.name}
            />
          </div>
        </div>

        {/* Filter options */}
        <div className="row form-group">
          <label
            className="col-sm-2 control-label"
          > Options
          </label>
          <div className="col-sm-10">
            <BasicObjectEditor
              obj={filter.options}
              onChange={nextOptions =>
                this.props.onUpdateFilter({ ...filter, options: nextOptions })
              }
            />
          </div>
        </div>

        {/* Filter isActive */}
        <div className="row form-group">
          <label className="col-sm-2 control-label">
            <input
              name="isActive"
              checked={filter.isActive}
              onChange={this.updateFilter}
              type="checkbox"
            />
            Is Active
          </label>
        </div>

      </form>
    );
  }
}
