import {
  User,
  UserCompanyRelationshipRoles,
  AccountType,
} from "@threatminder-system/tm-core";
import { UserWithEmailCampaigns } from "../types/user";

export type Action =
  | ReceiveUsersAction
  | RemoveUserAction
  | UpdateUserAction
  | StartEditingUserAction
  | StartNewUserAction
  | ConcludeEmailCampaignAction
  | UpdatePerPageAction;

export const RECEIVE_USERS = "user/receiveMany";
export type RECEIVE_USERS_TYPE = typeof RECEIVE_USERS;

export const REMOVE_USER = "user/remove";
export type REMOVE_USER_TYPE = typeof REMOVE_USER;

export const UPDATE_USER = "user/update";
export type UPDATE_USER_TYPE = typeof UPDATE_USER;

export const START_EDITING_USER = "user/startEditing";
export type START_EDITING_USER_TYPE = typeof START_EDITING_USER;

export const START_NEW_USER = "user/startNew";
export type START_NEW_USER_TYPE = typeof START_NEW_USER;

export const CONCLUDE_EMAIL_CAMPAIGN = "user/concludeEmailCampaign";
export type CONCLUDE_EMAIL_CAMPAIGN_TYPE = typeof CONCLUDE_EMAIL_CAMPAIGN;

export const UPDATE_PER_PAGE = "user/updatePerPage";
export type UPDATE_PER_PAGE_TYPE = typeof UPDATE_PER_PAGE;

export interface UpdatePerPageAction {
  type: UPDATE_PER_PAGE_TYPE;
  perPage: number;
}

export const updatePerPage = (perPage: number): UpdatePerPageAction => ({
  type: UPDATE_PER_PAGE,
  perPage: perPage,
});

interface ReceiveUsersAction {
  type: RECEIVE_USERS_TYPE;
  payload: UserWithEmailCampaigns[];
  meta: {
    page: number;
    perPage: number;
    total: number;
  };
}

interface RemoveUserAction {
  type: REMOVE_USER_TYPE;
  payload: number;
}

export function receiveUsers(response: {
  users: UserWithEmailCampaigns[];
  page?: number;
  perPage?: number;
  total?: number;
}): ReceiveUsersAction {
  return {
    type: RECEIVE_USERS,
    payload: response.users,
    meta: {
      page: response.page,
      perPage: response.perPage,
      total: response.total,
    },
  };
}

export function removeUser(userId: number): RemoveUserAction {
  return {
    type: REMOVE_USER,
    payload: userId,
  };
}

interface UpdateUserAction {
  type: UPDATE_USER_TYPE;
  payload: User;
  meta: {
    isNew: boolean;
  };
}

export function updateUser(
  payload: User,
  meta?: { isNew: boolean }
): UpdateUserAction {
  return {
    type: UPDATE_USER,
    payload,
    meta: {
      isNew: Boolean(meta && meta.isNew),
    },
  };
}

interface StartEditingUserAction {
  type: START_EDITING_USER_TYPE;
  payload: number;
}

export function startEditingUser(payload: number): StartEditingUserAction {
  return {
    type: START_EDITING_USER,
    payload,
  };
}

function makeNewUser() {
  return {
    userCompanyRelationship: {
      role: UserCompanyRelationshipRoles.Owner,
    },
    accountType: AccountType.Normal,
    isTrialEligible: true,
    emailsAllowed: false,
  } as User;
}

interface StartNewUserAction {
  type: START_NEW_USER_TYPE;
  payload: User;
}

export function startNewUser(): StartNewUserAction {
  return {
    type: START_NEW_USER,
    payload: makeNewUser(),
  };
}

interface ConcludeEmailCampaignAction {
  type: CONCLUDE_EMAIL_CAMPAIGN_TYPE;
  payload: {
    campaignId: number;
    userId: number;
  };
}

export function concludeEmailCampaign(campaignId: number, userId: number) {
  return {
    type: CONCLUDE_EMAIL_CAMPAIGN,
    payload: { campaignId, userId },
  };
}
