import { Action, RECEIVE_UPLOAD_ERRORS } from "../actions/uploader";
import { StoreState } from "../types/uploader";

const initialState: StoreState = {
  apiResults: { error: [], success: [] },
  hasUploaded: false,
};

export default function auth(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_UPLOAD_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
