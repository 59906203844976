import * as React from "react";
import Redux from "react-redux";
import Link from "redux-first-router-link";
import { Action, unauthenticate } from "../actions/auth";
import { Role } from "../../shared-constants";


type Props = {
  displayOnlyHeader: boolean,
  dispatch: Redux.Dispatch<Action>,
  role: Role,
};

export const Navbar: React.FunctionComponent<Props> = (props: Props) => {
  const { dispatch, displayOnlyHeader, role } = props;

  const closeNav = () => {
    const nav = window.document.getElementById("threatminder-navbar-collapse-1");
    nav.classList.remove("in");
  };

  return (
    <nav className="navbar navbar-inverse">
      <div className="container-fluid">
        <div className="navbar-header">
          {!displayOnlyHeader && (
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#threatminder-navbar-collapse-1" aria-expanded="false">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          )}
          <a className="navbar-brand" href="/">ThreatMinder Portal</a>
        </div>
        {!displayOnlyHeader && (
          <div className="collapse navbar-collapse" id="threatminder-navbar-collapse-1">
            <ul className="nav navbar-nav">
              {role === Role.UNRESTRICTED && (
                <>
                  <li>
                    <Link to="/actions" onClick={closeNav}>Actions</Link>
                  </li>
                  <li>
                    <Link to="/companies" onClick={closeNav}>Companies</Link>
                  </li>
                  <li>
                    <Link to="/data-caps" onClick={closeNav}>Data Caps</Link>
                  </li>
                  <li>
                    <Link to="/email-campaigns" onClick={closeNav}>Email Campaigns</Link>
                  </li>
                  <li>
                    <Link to="/filters" onClick={closeNav}>Filters</Link>
                  </li>
                  <li>
                    <Link to="/platforms" onClick={closeNav}>Platforms</Link>
                  </li>
                </>
              )}

              <li>
                <Link to="/review-posts" onClick={closeNav}>Review Posts</Link>
              </li>

              {role === Role.UNRESTRICTED && (
                <>
                  <li>
                    <Link to="/sync-sheets" onClick={closeNav}>Sync Sheets</Link>
                  </li>
                  <li>
                    <Link to="/upload" onClick={closeNav}>Upload</Link>
                  </li>
                  <li>
                    <Link to="/users" onClick={closeNav}>Users</Link>
                  </li>
                </>
              )}
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  href="/login"
                  onClick={() => {
                    dispatch(unauthenticate());
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>/* .collapse */
        )}
      </div>{/* .container-fluid */}
    </nav>
  );
};
