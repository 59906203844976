import { RiskKeywordSet } from "@threatminder-system/tm-core";

export type Action = ReceiveRiskKeywordSetsAction;

export const RECEIVE_RISK_KEYWORD_SETS = "riskKeywordSet/receiveMany";
export type RECEIVE_RISK_KEYWORD_SETS_TYPE = typeof RECEIVE_RISK_KEYWORD_SETS;

interface ReceiveRiskKeywordSetsAction {
  type: RECEIVE_RISK_KEYWORD_SETS_TYPE;
  payload: RiskKeywordSet[];
}

export function receiveRiskKeywordSets(
  riskKeywordSets: RiskKeywordSet[]
): ReceiveRiskKeywordSetsAction {
  return {
    type: RECEIVE_RISK_KEYWORD_SETS,
    payload: riskKeywordSets,
  };
}
