import { Paginator } from "./paginator";
import { StoreState } from "../types/post-action-matches-history";

import "./post-action-matches-history.css";

type Props = {
  authToken: string;
  postActionMatchUpdates: [];
  page: number;
  perPage: number;
  total: number;
  isDataFetched: boolean;
  portalUsers: StoreState["portalUsers"];
  selectedUserId: string;
  selectUser: (
    query: { userId: string; page: number; perPage: number },
    authToken: string
  ) => void;
};

export function PostActionMatchesHistory(props: Props) {
  const {
    authToken,
    postActionMatchUpdates,
    page,
    perPage,
    total,
    isDataFetched,
    portalUsers,
    selectedUserId = "",
    selectUser,
  } = props;
  return (
    <div>
      <div className="page-header">
        <h1>Review Posts History</h1>
      </div>

      <div className="panel panel-default contains-table-hack">
        <div className="panel-body">
          <p>
            This page shows changes made to post-action matches through Portal.
            (Changes through other sites or APIs not shown here.)
          </p>

          <select
            value={selectedUserId}
            onChange={(event) =>
              selectUser(
                { userId: event.currentTarget.value, page: 1, perPage },
                authToken
              )
            }
          >
            <option value="" disabled={true} hidden={true}>
              Select user
            </option>
            {portalUsers.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username}
              </option>
            ))}
          </select>

          <br />
          <br />

          {isDataFetched ? (
            postActionMatchUpdates.length ? (
              <PostActionMatchHistoryTable
                postActionMatchUpdates={postActionMatchUpdates}
                portalUsers={portalUsers}
              />
            ) : (
              <div>No history for this user</div>
            )
          ) : (
            selectedUserId && <div>Waiting for data...</div>
          )}
        </div>
      </div>
      <Paginator
        page={page}
        perPage={perPage}
        total={total}
        pageType="POST_ACTION_MATCHES_HISTORY"
        getPageQuery={(n) => ({
          page: n,
          perPage,
          userId: selectedUserId,
        })}
      />
    </div>
  );
}

function PostActionMatchHistoryTable(props: any) {
  const { postActionMatchUpdates } = props;

  if (!postActionMatchUpdates.length) {
    return;
  }

  const primaryColumns = [
    "id",
    "postActionMatchId",
    "changedBy",
    "changedOn",
    "isHighRisk",
    "sentimentDetected",
    "sentimentConfirmed",
    "riskDetected",
    "riskConfirmed",
    "isDetectedThreat",
    "isConfirmedThreat",
    "isAutomatedConfirmedThreat",
    "riskPercentage",
    "sentiment",
    "needsAudit",
  ];

  const secondaryColumns = Object.keys(postActionMatchUpdates[0])
    .filter((key) => !primaryColumns.includes(key))
    .sort();

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col">
            {/* 0. Leave blank. This is the top cell of a row count col */}
          </th>
          <th scope="col">Post action match id</th>
          <th scope="col">Changed on</th>
          <th scope="col">High risk</th>
          <th scope="col">Sentiment detected /&nbsp;confirmed</th>
          <th scope="col">Risk detected /&nbsp;confirmed</th>
          <th scope="col">Threat detected /&nbsp;confirmed</th>
          <th scope="col">Automated confirmed threat</th>
          <th scope="col">Risk percentage</th>
          <th scope="col">Sentiment</th>
          <th scope="col">Needs audit</th>
        </tr>
      </thead>
      <tbody>
        {postActionMatchUpdates.map((pamh: any, index: number) => [
          // for this row, primary columns, meaning ones likely to have been modified
          <tr key={pamh.id} id={`post-action-match-update-id-${pamh.id}`}>
            <td>{index + 1}</td>
            <td>
              <samp>{pamh.postActionMatchId}</samp>
            </td>
            <td>
              <samp>{pamh.changedOn.split("T").join(" ")}</samp>
            </td>
            <td>
              <samp>{pamh.isHighRisk.toString()}</samp>
            </td>
            <td>
              <samp>{pamh.sentimentDetected}</samp>
              <br />/
              {pamh.sentimentConfirmed != null ? (
                <samp> {pamh.sentimentConfirmed}</samp>
              ) : (
                <span> &ndash;</span>
              )}
            </td>
            <td>
              <samp>{pamh.riskDetected}</samp>
              <br />/
              {pamh.riskConfirmed != null ? (
                <samp> {pamh.riskConfirmed}</samp>
              ) : (
                <span> &ndash;</span>
              )}
            </td>
            <td>
              <samp>{pamh.isDetectedThreat.toString()}</samp>
              <br />/
              {pamh.isConfirmedThreat.toString() ? (
                <samp> {pamh.isConfirmedThreat.toString()}</samp>
              ) : (
                <span> &ndash;</span>
              )}
            </td>
            <td>
              <samp>{pamh.isAutomatedConfirmedThreat.toString()}</samp>
            </td>
            <td>
              <samp>{pamh.riskPercentage}</samp>
            </td>
            <td>
              <samp>{pamh.sentiment}</samp>
            </td>
            <td>
              <samp>{pamh.needsAudit.toString()}</samp>
            </td>
          </tr>,
          // dump all secondary columns into a kind of footer row
          <tr key={pamh.id + "-secondary"}>
            <td />
            <td colSpan={100}>
              Other properties, unlikely changed:
              <ul className="post-action-match-update-secondary-columns">
                {secondaryColumns
                  .filter((colName) => colName !== "auditOptions")
                  .map((colName) => (
                    <li key={colName}>
                      <var>{colName}</var>:{" "}
                      <samp>{JSON.stringify(pamh[colName])}</samp>
                    </li>
                  ))}
                <li>
                  <br />
                  <var>auditOptions</var>{" "}
                  <samp>{JSON.stringify(pamh.auditOptions)}</samp>
                </li>
              </ul>
            </td>
          </tr>,
        ])}
      </tbody>
    </table>
  );
}
