import * as React from "react";
import {
  EmailCampaign as EmailCampaignModel,
} from "@threatminder-system/tm-core";
import { getDate } from "../helpers/email-campaign";
import { keywordsToValue, valueToKeywords } from "../helpers/action";

interface EmailCampaignProps {
  emailCampaign: Partial<EmailCampaignModel>;
  onUpdateEmailCampaign: (emailCampaign: EmailCampaignModel) => void;
}

export class EmailCampaignForm extends React.Component<EmailCampaignProps, EmailCampaignModel> {

  constructor(props: any) {
    super(props);
    this.state = props.emailCampaign;
    this.updateEmailCampaign = this.updateEmailCampaign.bind(this);
  }

  private updateEmailCampaign(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    // Because we named the inputs to match their corresponding values in state, it's
    // easy to update the state.
    const control: HTMLInputElement = e.currentTarget as HTMLInputElement;
    const nextState = { ...this.state };

    switch (control.name) {
      case "emailDayOfWeek":
        (nextState as any).emailDayOfWeek = control.value === "" ? null : Number(control.value);
        break;
      case "template-options":
        nextState.templateOptions = {
          include: valueToKeywords(control.value)
        };
        break;
      default:
        switch (control.type) {
          case "number":
            (nextState as any)[control.name] = control.value === "" ? null : Number(control.value);
            break;
          case "checkbox":
            (nextState as any)[control.name] = control.checked;
            break;
          default:
            (nextState as any)[control.name] = control.value;
        }
    }

    this.setState(nextState);
    this.props.onUpdateEmailCampaign(nextState);
  }

  render() {
    const { emailCampaign } = this.props;
    return (
      <div>

        {/* Name */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="name"
          > Name (required)
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="name"
              type="text"
              placeholder="A descriptive name"
              name="name"
              onChange={this.updateEmailCampaign}
              value={emailCampaign.name || ""}
            />
          </div>
        </div>

        {/* Start date */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="startDate"
          > Start date (required)
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="startDate"
              type="date"
              name="startDate"
              onChange={this.updateEmailCampaign}
              value={emailCampaign.startDate ? getDate(emailCampaign.startDate as any) : ""}
            />
          </div>
        </div>

        {/* End date */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="endDate"
          > End date (required)
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="endDate"
              type="date"
              name="endDate"
              onChange={this.updateEmailCampaign}
              value={emailCampaign.endDate ? getDate((emailCampaign.endDate as any)) : ""}
            />
          </div>
        </div>

        {/* Campaign length */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="campaignLength"
          > Campaign length
          </label>
          <div className="col-sm-9">
            <p>Number of days the campaign lasts for each user from the day they started the campaign.</p>
            <p>If 0 or unset, then there is no per user campaign length.</p>
            <input
              className="form-control"
              id="campaignLength"
              type="number"
              min={0}
              name="campaignLength"
              onChange={this.updateEmailCampaign}
              value={emailCampaign.hasOwnProperty("campaignLength") ? emailCampaign.campaignLength : ""}
            />
          </div>
        </div>

        {/* Email day of month */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="emailDayOfMonth"
          > Day of month (1 - 31) to send email
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="emailDayOfMonth"
              name="emailDayOfMonth"
              type="number"
              min={1}
              max={31}
              onChange={this.updateEmailCampaign}
              value={(emailCampaign as any).emailDayOfMonth || ""}
            />
          </div>
        </div>

        {/* Email day of week */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="emailDayOfWeek"
          > Day of week to send email
          </label>
          <div className="col-sm-9">
            <select
              className="form-control"
              id="emailDayOfWeek"
              name="emailDayOfWeek"
              required={true}
              value={(emailCampaign as any).hasOwnProperty("emailDayOfWeek") ? (emailCampaign as any).emailDayOfWeek : ""}
              onChange={this.updateEmailCampaign}
            >
              <option value="" />
              <option value={0}>Monday</option>
              <option value={1}>Tuesday</option>
              <option value={2}>Wednesday</option>
              <option value={3}>Thursday</option>
              <option value={4}>Friday</option>
              <option value={5}>Saturday</option>
              <option value={6}>Sunday</option>
            </select>
          </div>
        </div>

        {/* Days between emails */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="daysBetweenEmails"
          > Days between emails
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="daysBetweenEmails"
              type="number"
              min={1}
              name="daysBetweenEmails"
              onChange={this.updateEmailCampaign}
              value={emailCampaign.daysBetweenEmails || ""}
            />
          </div>
        </div>

        {/* Postmark Template ID */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="template"
          > Postmark Template ID (number) (required)
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="template"
              type="number"
              name="template"
              onChange={this.updateEmailCampaign}
              value={emailCampaign.template || ""}
            />
          </div>
        </div>

        {/* Postmark template options */}
        <div className="row form-group">
          <label
            className="col-sm-3 control-label"
            htmlFor="template-options"
          > Postmark template options
          </label>
          <div className="col-sm-9">
            <input
              className="form-control"
              id="template-options"
              type="text"
              placeholder="Enter keywords, comma-separated"
              name="template-options"
              onChange={this.updateEmailCampaign}
              value={keywordsToValue(emailCampaign.templateOptions.include)}
            />
          </div>
        </div>

      </div>
    );
  }
}
