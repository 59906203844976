import { LocalSocialAccounts as LocalSocialAccountsType } from "@threatminder-system/tm-core";
import { DbTable } from "./db-table";


interface Props {
  localSocialAccounts: LocalSocialAccountsType[];
  removed: LocalSocialAccountsType[];
  droppedRows: string[][];
}

export const LocalSocialAccounts = (props: Props) => {
  const { localSocialAccounts, removed, droppedRows } = props;
  const rows = toRows(localSocialAccounts);
  return (
    <div>
      <div className="page-header">
        <h1>Local Social Accounts</h1>
      </div>

      {droppedRows.length > 0 && (
        <div className="panel contains-table-hack">
          <div className="panel-body">
            <h2 className="text-warning">Not synced</h2>
            <p>These rows in the spreadsheet have errors and could not be synced with the database.</p>
            <table className="table table-bordered">
              <tbody>
                {droppedRows.map((row, i) => (
                  <tr key={i}>
                    {row.map((val, j) => (
                      <td key={j} className="warning">{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {removed.length > 0 && (
        <div className="panel contains-table-hack">
          <div className="panel-body">
            <h2 className="text-danger">Removed</h2>
            <p>The following rows were removed from the database.</p>
            <table className="table table-bordered">
              <tbody>
                {toRows(removed).map((row, i) => (
                  <tr key={i}>
                    {row.map((val, j) => (
                      <td key={j} className="danger">{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <DbTable rows={rows} />
    </div>
  );
};

const toRows = (localSocialAccounts: LocalSocialAccountsType[]) => {
  const header = ["City", "State", "City Center", "Social Accounts"];
  const rows = localSocialAccounts.map(lsa => {
    return [
      lsa.city,
      lsa.state,
      lsa.cityCenter != null ? lsa.cityCenter.join(",") : "",
      lsa.socialAccounts.join(", "),
    ];
  });
  return [header, ...rows];
};
