import { Role } from "../../shared-constants";

/**
 * Returns true if token's expiration time is still in the future.
 * Returns false otherwise, or if token cannot be parsed.
 * @param token The JWT token (a base64-encoded string with three parts separated by dots)
 */
export function isAuthTokenStillInEffect(token: string): boolean {
  let expiration;
  try {
    const data = getDataFromToken(token);
    expiration = data.exp;
  } catch (err) {
    return false;
  }
  if (typeof expiration !== "number") {
    return false;
  }
  // expiration is in seconds (not ms) since epoch, so convert now() ms into seconds
  const now = Math.floor(Date.now() / 1000);
  return now < expiration;
}

export function getRole(token: string): Role | undefined {
  try {
    const { role } = getDataFromToken(token);
    if (Object.values(Role).includes(role)) {
      return role;
    }
  } catch (err) {
    return;
  }
}

function getDataFromToken(token: string) {
  const parts = token.split(".");
  return JSON.parse(atob(parts[1]));
}
