/**
 * Reducer function for managing the title state.
 *
 * @param state - The current title state.
 * @param action - The action object that contains the type and payload.
 * @returns The updated title state.
 */
const titleReducer = (state = "ThreatMinder Portal", action: any = {}) => {
  switch (action.type) {
    case "HOME":
      return "ThreatMinder Portal";
    case "LOGIN":
      return "Login | ThreatMinder Portal";
    case "ACTIONS":
      return "Actions | ThreatMinder Portal";
    case "FILTERS":
      return "Filters | ThreatMinder Portal";
    case "COMPANIES":
      return "Companies | ThreatMinder Portal";
    case "COMPANY":
      return `Company ${action.payload.id} | ThreatMinder Portal`;
    case "EMAIL_CAMPAIGNS":
      return "Email Campaigns | ThreatMinder Portal";
    case "PLATFORMS":
      return "Platforms | ThreatMinder Portal";
    case "POST_ACTION_MATCHES":
      return "Stream Viewer | ThreatMinder Portal";
    case "SYNC_SHEETS":
      return "Sync Google Sheets to Database | ThreatMinder Portal";
    case "USERS":
      return "Users | ThreatMinder Portal";
    default:
      return state;
  }
};

export default titleReducer;