import { cloneDeep, keyBy } from "lodash";
import {
  Action,
  RECEIVE_COMPANIES,
  START_EDITING_COMPANY,
  RECEIVE_NEW_COMPANY_ID
} from "../actions/company";
import { StoreState } from "../types/company";

const initialState: StoreState = {
  // We hold onto all companies that have been fetched here
  companies: {},
  editingCompany: null,
  // pagination - the companies to display on a specific page at /companies
  forPage: [],
  page: 0,
  perPage: 1,
  total: 0,
  // place where we store ids of companies created in Portal UI
  // so that we display them on the first page outside the pagination
  // system -- this is just for better UI
  newCompanyIds: [],
};

export default function auth(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    // @ts-ignore
    case "COMPANIES":
      // Reset form when navigating to page
      return {
        ...state,
        editingCompany: null,
      };
    case RECEIVE_COMPANIES:
      const responseIsPaginated = Boolean(action.meta.perPage);
      const { page, perPage, total } = responseIsPaginated ? action.meta : state;
      return {
        ...state,
        companies: {
          ...state.companies,
          ...keyBy(action.payload, "id"),
        },
        forPage: responseIsPaginated ? action.payload : state.forPage,
        page,
        perPage,
        total,
      };
    case RECEIVE_NEW_COMPANY_ID:
      return {
        ...state,
        newCompanyIds: [action.payload, ...state.newCompanyIds],
      };
    case START_EDITING_COMPANY: {
      const id = action.payload;
      const companyToEdit = state.companies[id];
      if (!companyToEdit) {
        // Clear existing
        return {
          ...state,
          editingCompany: null,
        };
      }
      if (
        state.editingCompany &&
        state.editingCompany.id === id
      ) {
        // No-op: already editing.
        return state;
      }
      return {
        ...state,
        editingCompany: cloneDeep(companyToEdit),
      };
    }
    default:
      return state;
  }
}
