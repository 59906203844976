import { SyncSheets } from "../components/sync-sheets";
import { connect, Dispatch } from "react-redux";
import { FormEvent } from "react";
import { StoreState } from "../types";
import { goToPage } from "../actions/page";
import { receiveLocalSocialAccounts } from "../actions/local-social-accounts";
import { receiveRiskKeywordSets } from "../actions/risk-keyword-sets";
import { fetchApiRoute } from "../helpers/fetch";


function mapStateToProps({ auth }: StoreState) {
  return {
    authToken: auth.authToken,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    async onSubmit(event: FormEvent<HTMLFormElement>, authToken: string, syncPath: string) {
      event.preventDefault();
      const form: any = event.currentTarget;
      const spreadsheetId = form.elements.spreadsheetId.value;
      const range = form.elements.range.value;
      const response = await fetchApiRoute(`${syncPath}?spreadsheetId=${spreadsheetId}&range=${encodeURIComponent(range)}`, {
        method: "POST",
        authToken,
      });

      if (response.ok) {
        const body = await response.json();
        if (body.error) {
          window.alert(`Could not sync:\n ${body.error}`);
        } else if (body.riskKeywordSets) {
          dispatch(receiveRiskKeywordSets(body.riskKeywordSets));
          dispatch(goToPage("RISK_KEYWORD_SETS_SYNCED"));
        } else if (body.localSocialAccounts) {
          dispatch(receiveLocalSocialAccounts(body));
          dispatch(goToPage("LOCAL_SOCIAL_ACCOUNTS"));
        }
      } else {
        window.alert("Problem with server response. See server logs.");
      }
    }
  };
}

export const SyncSheetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SyncSheets);
