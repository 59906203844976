import { cloneDeep, keyBy } from "lodash";
import update from "immutability-helper";
import { User } from "@threatminder-system/tm-core";
import {
  Action,
  RECEIVE_USERS,
  REMOVE_USER,
  UPDATE_USER,
  START_EDITING_USER,
  START_NEW_USER,
  CONCLUDE_EMAIL_CAMPAIGN,
  UPDATE_PER_PAGE,
} from "../actions/user";
import { StoreState } from "../types/user";

const initialState: StoreState = {
  // place to store edits on new (unsaved) user
  newUser: null,
  // place to store edits on existing (saved) user
  editingUser: {} as User,
  // we store all the users we've ever fetched by id
  users: {},
  // pagination - the users to display on a specific page at /users
  forPage: [],
  page: 0,
  perPage: 25,
  total: 0,
};

export default function user(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_USERS:
      const responseIsPaginated = Boolean(action.meta.perPage);
      const { page, perPage, total } = responseIsPaginated ? action.meta : state;
      return {
        ...state,
        users: {
          ...state.users,
          ...keyBy(action.payload, "id"),
        },
        forPage: responseIsPaginated ? action.payload : state.forPage,
        page,
        perPage,
        total,
      };
    case REMOVE_USER: {
      const removeId = action.payload;
      return update(state, {
        users: { $unset: [removeId] },
      });
    }
    case UPDATE_USER:
      return {
        ...state,
        [action.meta.isNew ? "newUser" : "editingUser"]: action.payload,
      };
   case UPDATE_PER_PAGE: {
      const { perPage } = action;
      return {
        ...state,
        perPage,
      };
    }
    case START_EDITING_USER: {
      const id = action.payload;
      const userToEdit = state.users[id];
      if (!userToEdit) {
        throw new Error("Tried to edit user not in store");
      }
      if (id === state.editingUser.id) {
        // No-op: already editing.
        // Use update action if you wish to reset state.editingUser
        return state;
      }
      return {
        ...state,
        editingUser: cloneDeep(userToEdit),
      };
    }

    case START_NEW_USER:
      if (state.newUser) {
        // alread started, no-op
        return state;
      }
      return {
        ...state,
        newUser: action.payload,
      };
    case CONCLUDE_EMAIL_CAMPAIGN:
      const { campaignId, userId } = action.payload;
      const campaignIndex = state.users[userId].emailCampaigns
        .findIndex(({emailCampaignId}) => emailCampaignId === campaignId);
      return update(state, {
        users: {
          [userId]: {
            emailCampaigns: {
              [campaignIndex]: {
                isConcluded: {$set: true}
              }
            }
          }
        }
      });
    default:
      return state;
  }
}
