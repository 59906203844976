import * as React from "react";
import { PostActionMatch } from "@threatminder-system/tm-core";
import { SentimentConfirmed, RiskConfirmed } from "./action-match-row";

interface Props {
  onChange: (postActionMatch: PostActionMatch) => void;
  actionMatches: PostActionMatch[];
  label: string;
}

interface State {
  isHighRisk: boolean;
  sentimentConfirmed: string;
  riskConfirmed: string;
  isConfirmedThreat: boolean;
}

// This component takes a list of post action matches and renders a form
// to set values on all of them at once (sentimentConfirmed, riskConfirmed,
// and isConfirmedRisk)
export default class SetMultipleActionMatches extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      isHighRisk: false,
      sentimentConfirmed: "",
      riskConfirmed: "",
      isConfirmedThreat: false,
    };
  }

  onChange(event: React.FormEvent<HTMLInputElement>) {
    type ExpectedNames = "sentimentConfirmed" | "riskConfirmed";
    const { actionMatches } = this.props;
    const { name, value } = event.currentTarget;
    const newState: Pick<State, ExpectedNames> = {
      [name]: value,
    } as { [name in ExpectedNames]: string };
    this.setState(newState);
    actionMatches.forEach((actionMatch) => {
      const postActionMatch: PostActionMatch = {
        ...actionMatch,
        [name]: value === "" ? null : value,
      };
      // notify parent component
      this.props.onChange(postActionMatch);
    });
  }

  _onToggle<ExpectedNames extends "isHighRisk" | "isConfirmedThreat">(
    fieldName: ExpectedNames,
    checked: boolean
  ) {
    const { actionMatches } = this.props;
    const newState: Pick<State, ExpectedNames> = {
      [fieldName]: checked,
    } as { [fieldName in ExpectedNames]: boolean };
    this.setState(newState);
    actionMatches.forEach((actionMatch) => {
      const postActionMatch: PostActionMatch = {
        ...actionMatch,
        [fieldName]: checked,
      };
      this.props.onChange(postActionMatch);
    });
  }

  onToggleIsHighRisk(checked: boolean) {
    this._onToggle("isHighRisk", checked);
  }

  onToggleIsThreat(checked: boolean) {
    this._onToggle("isConfirmedThreat", checked);
  }

  render() {
    return (
      <tr>
        <td colSpan={5}>{this.props.label}</td>
        <td>
          {/* High Risk */}
          <input
            type="checkbox"
            className="form-control"
            checked={this.state.isHighRisk}
            onChange={(evt) =>
              this.onToggleIsHighRisk(evt.currentTarget.checked)
            }
          />
        </td>
        <td>
          {/*Sentiment detected*/}
          &nbsp;
        </td>
        <td>
          {/* Sentiment confirmed */}
          <SentimentConfirmed
            value={this.state.sentimentConfirmed}
            onChange={this.onChange}
          />
        </td>
        <td>
          {/*Risk detected*/}
          &nbsp;
        </td>
        <td>
          {/* Risk confirmed */}
          <RiskConfirmed
            value={this.state.riskConfirmed}
            onChange={this.onChange}
          />
        </td>
        <td>
          {/* Threat detected */}
          &nbsp;
        </td>
        <td>
          {/* Threat confirmed */}
          <input
            type="checkbox"
            className="form-control"
            checked={this.state.isConfirmedThreat}
            onChange={(evt) => this.onToggleIsThreat(evt.currentTarget.checked)}
          />
        </td>
      </tr>
    );
  }
}
