import { stringify } from "query-string";

import { Dispatch } from "react-redux";
import {
  PostActionMatch,
  SortByEnum,
  SortByOrderEnum,
} from "@threatminder-system/tm-core";
import { fetchApiRoute } from "../helpers/fetch";

export type Action =
  | ReceivePostActionMatchesAction
  | ChangePostActionMatchAction
  | MarkSavingPostActionMatchesAction
  | UnmarkSavingPostActionMatchesAction;

export const RECEIVE_POST_ACTION_MATCHES = "postActionMatches/receiveMany";
export type RECEIVE_POST_ACTION_MATCHES_TYPE =
  typeof RECEIVE_POST_ACTION_MATCHES;

export const MARK_SAVING = "postActionMatches/markSaving";
export type MARK_SAVING_TYPE = typeof MARK_SAVING;

export const UNMARK_SAVING = "postActionMatches/unmarkSaving";
export type UNMARK_SAVING_TYPE = typeof UNMARK_SAVING;

export interface PostActionMatchQueryResponse {
  postActionMatches: PostActionMatch[];
  sortBy: SortByEnum;
  sortByOrder: SortByOrderEnum;
  page: number;
  perPage: number;
  total: number;
  companyId: number[];
  excludeCompany: boolean;
  actionId: number[];
  excludeAction: boolean;
  platformId: number[];
}

export interface PostActionMatchQueryRequest {
  authToken: string;
  sortBy: SortByEnum;
  sortByOrder: SortByOrderEnum;
  page: number;
  companyId: number[];
  excludeCompany: boolean;
  actionId: number[];
  excludeAction: boolean;
  platformId: number[];
}

interface ReceivePostActionMatchesAction {
  type: RECEIVE_POST_ACTION_MATCHES_TYPE;
  payload: PostActionMatchQueryResponse;
}

export function receive(
  response: PostActionMatchQueryResponse
): ReceivePostActionMatchesAction {
  return {
    type: RECEIVE_POST_ACTION_MATCHES,
    payload: response,
  };
}

export const CHANGE_POST_ACTION_MATCH = "postActionMatch/change";
export type CHANGE_POST_ACTION_MATCH_TYPE = typeof CHANGE_POST_ACTION_MATCH;

interface ChangePostActionMatchAction {
  type: CHANGE_POST_ACTION_MATCH_TYPE;
  payload: PostActionMatch;
}

export function change(
  postActionMatch: PostActionMatch
): ChangePostActionMatchAction {
  return {
    type: CHANGE_POST_ACTION_MATCH,
    payload: postActionMatch,
  };
}

// Helper function for fetching postActionMatches.
// TODO make async action creator https://redux.js.org/advanced/async-actions
export async function fetchPostActionMatches(
  dispatch: Dispatch<any>,
  pq: PostActionMatchQueryRequest
) {
  fetchApiRoute(
    `/api/v1/post-action-matches?page=${pq.page}&sortBy=${pq.sortBy}&sortByOrder=${pq.sortByOrder}` +
      `&excludeAction=${pq.excludeAction}&${stringify({
        actionId: pq.actionId,
      })}` +
      `&excludeCompany=${pq.excludeCompany}&${stringify({
        companyId: pq.companyId,
      })}` +
      `&${stringify({ platformId: pq.platformId })}`,
    {
      authToken: pq.authToken,
    }
  )
    .then(
      (response) => response.json(),
      // Do not use catch, because that will also catch
      // any errors in the dispatch and resulting render,
      // causing a loop of 'Unexpected batch number' errors.
      // https://github.com/facebook/react/issues/6895
      (error) => console.log("An error occurred.", error)
    )
    .then((json) =>
      // Here, we update the app state with the results of the API call.
      dispatch(receive(json))
    );
}

interface MarkSavingPostActionMatchesAction {
  type: MARK_SAVING_TYPE;
  payload: number[];
}

export function markSaving(ids: number[]): MarkSavingPostActionMatchesAction {
  return {
    type: MARK_SAVING,
    payload: ids,
  };
}

interface UnmarkSavingPostActionMatchesAction {
  type: UNMARK_SAVING_TYPE;
  payload: number[];
}

export function unmarkSaving(
  ids: number[]
): UnmarkSavingPostActionMatchesAction {
  return {
    type: UNMARK_SAVING,
    payload: ids,
  };
}
