import routes from "../routes-map";

type FetchOptions = {
  [key: string]: any
};

const versionPrefix = "/api/v1";
const startsWithVersionPrefix = new RegExp("^" + versionPrefix);

export const fetchApiRoute = (route: string, _options: FetchOptions) => {
  const { authToken, ...options } = _options;

  // Using `new` here works even if options.headers is undefined or
  // is already of type `Headers`
  const headers = new Headers(options.headers);

  if (
    !headers.has("Content-type") &&
    // If the body is FormData then the Fetch API will use the multipart/form-data content-type
    !(options.body instanceof FormData)
  ) {
    headers.set("Content-type", "application/json");
  }

  if (!headers.has("Authorization")) {
    headers.set("Authorization", `Bearer ${authToken}`);
  }

  options.headers = headers;

  route = (startsWithVersionPrefix.test(route) || startsWithVersionPrefix.test("/" + route)) ?
    route
    : (versionPrefix + route);

  const url = `${process.env.REACT_APP_API_URL}${route}`;

  return fetch(url, options)
    .then(response => {
      if (response.status === 403) {
        window.location.assign(routes.LOGIN.path);
      }
      return response;
    });
};
