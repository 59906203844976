import { User } from "@threatminder-system/tm-core";
import { Users } from "../components/users";
import { connect, Dispatch } from "react-redux";
import { MouseEvent } from "react";
import { StoreState } from "../types";
import { UserWithEmailCampaigns } from "../types/user";
import {
  receiveUsers,
  removeUser,
  updateUser,
  startEditingUser,
  startNewUser,
  concludeEmailCampaign,
  updatePerPage,
} from "../actions/user";
import { fetchCompanies } from "../actions/company";
import { fetchApiRoute } from "../helpers/fetch";

function mapStateToProps({ auth, company, emailCampaign, location, user }: StoreState) {
  return {
    authToken: auth.authToken,
    newUser: user.newUser,
    editingUser: user.editingUser,
    users: user.forPage.map(({ id }) => user.users[id]),
    companiesById: company.companies,
    emailCampaignsById: emailCampaign.emailCampaigns,
    userId: (location.payload as any).id,
    page: user.page,
    perPage: user.perPage,
    total: user.total,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    async addUserToEmailCampaign(user: UserWithEmailCampaigns, emailCampaignId: number, authToken: string) {
      const body = {
        userId: user.id,
        campaignId: emailCampaignId,
      };
      const response = await fetchApiRoute("/api/v1/email-campaign-users", {
        method: "POST",
        authToken,
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const { emailCampaignUserRelationship } = await response.json();
        const updatedUser = {
          ...user,
          emailCampaigns: [
            ...user.emailCampaigns,
            emailCampaignUserRelationship,
          ],
        };
        dispatch(receiveUsers({ users: [updatedUser] }));

      } else {
        const obj = await response.json();
        window.alert(`Could not add user to campaign:\n ${obj.error}`);
      }
    },
    async concludeEmailCampaignForUser(campaignId: number, userId: number, authToken: string) {
      const body = {
        userId,
        campaignId,
      };
      const response = await fetchApiRoute("/api/v1/email-campaign-users/conclude", {
        method: "POST",
        authToken,
        body: JSON.stringify(body),
      });
      if (response.ok) {
        dispatch(concludeEmailCampaign(campaignId, userId));
      } else {
        const errRespBody = await response.text();
        const errRespObj = errRespBody ? JSON.parse(errRespBody) : {};
        let alertMsg = "Could not conclude email campaign for user.";
        if (errRespObj.error) {
          alertMsg += "\n  " + errRespObj.error;
        }
        window.alert(alertMsg);
      }
    },
    onUpdateUser(updatedUser: User) {
      dispatch(updateUser(updatedUser, { isNew: !updatedUser.id }));
    },
    onClickDeleteUser(event: MouseEvent<HTMLElement>, authToken: string) {
      event.preventDefault();
      event.stopPropagation();
      const yes = window.confirm("Are you sure you want to delete this user?");
      if (!yes) {
        return;
      }
      const { currentTarget: { dataset: { id } } } = event;
      fetchApiRoute(`/api/v1/users/${id}`, {
        method: "DELETE",
        authToken,
      }).then(response => {
        if (response.ok) {
          dispatch(removeUser(Number(id)));
        } else {
          response.json().then(obj => {
            window.alert(`Could not delete user:\n ${obj.error}`);
          });
        }
      });
    },
    onClickEditUser(event: MouseEvent<HTMLElement>) {
      const { currentTarget: { dataset: { id } } } = event;
      dispatch(startEditingUser(Number(id)));
    },
    onClickStartNewUser() {
      dispatch(startNewUser());
    },
    async onSubmitUser(event: MouseEvent<HTMLElement>, user: User, authToken: string) {
      event.preventDefault();
      const isNew = !user.id;
      const companyId = user.userCompanyRelationship && user.userCompanyRelationship.companyId;
      const body = { users: [user] };
      const response = await fetchApiRoute(isNew ? `/api/v1/users` : `/api/v1/users/${user.id}`, {
        method: isNew ? "POST" : "PUT",
        authToken,
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const obj = await response.json();
        window.alert("Success");
        if ((isNew && !companyId) || (user as any).newCompanyName) {
          // Backend will create new company
          // we need to fetch the new company's details (name, id, etc.)
          await fetchCompanies(dispatch, authToken);
        }
        dispatch(receiveUsers(obj));
        dispatch(updateUser(isNew ? null : {} as User, { isNew }));
      } else {
        const obj = await response.json();
        window.alert(`Could not save user:\n ${obj.error}`);
      }
    },
    async onUpdatePerPage(perPage: number, authToken: string, page: number) {
      page  =  page <= 0 ? 1 : page;
      dispatch(updatePerPage(perPage));
      const response = await fetchApiRoute(`/api/v1/users?page=${page}&perPage=${perPage}`, {authToken: authToken});
      if (response.ok) {
        dispatch(receiveUsers( await response.json()));
      }
    }
  };

}

export const UsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
