// GenerateApiKeyPopup.tsx
import React from "react";
import "./generate-api-key.css";

interface GenerateApiKeyPopupProps {
  apiKey: string;
  onClose: () => void;
}

const GenerateApiKeyPopup: React.FC<GenerateApiKeyPopupProps> = ({
  apiKey,
  onClose,
}) => {
  return (
    <div className="popup-container">
      <div className="popup">
        <div className="popup-content">
          <h2>Generated API Key</h2>
          <input className="form-control" value={apiKey} readOnly={true} />
          <button onClick={onClose} className="close-btn">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateApiKeyPopup;
