import { omit } from "lodash";
import { Company } from "@threatminder-system/tm-core";
import { Companies } from "../components/companies";
import { connect, Dispatch } from "react-redux";
import { MouseEvent, FormEvent } from "react";
import { StoreState } from "../types";
import {
  receiveCompanies,
  startEditingCompany,
  receiveNewCompanyId,
} from "../actions/company";
import { fetchApiRoute } from "../helpers/fetch";


function mapStateToProps(state: StoreState) {
  const { companies } = state.company;
  const newCompanies = state.company.newCompanyIds.map(newCompanyId => companies[newCompanyId]);
  return {
    companies: state.company.forPage.map(({id}) => companies[id]),
    editingCompany: state.company.editingCompany,
    authToken: state.auth.authToken,
    page: state.company.page,
    perPage: state.company.perPage,
    total: state.company.total,
    newCompanies,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onClickCompany(event: MouseEvent<HTMLElement>) {
      event.preventDefault();
      // TODO: find a better way to click on trash icon without triggering
      // link click.
      if ((event.target as any).id === "trashIcon" ||
        (event.target as any).id === "deleteCompanyBtn") {
        return;
      }
      dispatch(startEditingCompany(Number(event.currentTarget.dataset.id)));
    },
    onSubmitCompanyForm(
      event: FormEvent<HTMLFormElement>,
      authToken: string,
      // TODO: remove type union once tm-core has been updated
      company?: Company & {canCreateCrawls: boolean}
    ) {
      event.preventDefault();
      const form = event.currentTarget as HTMLFormElement;
      const formElements: { [k: string]: HTMLInputElement } = form.elements as any;
      const name = formElements["company-name"].value;
      const note = formElements["company-note"].value;
      const vertical = formElements["company-vertical"].value;
      const isDataCapEnabled = formElements["company-is-data-cap-enabled"].checked;
      const canCreateCrawls = formElements["company-can-create-crawls"].checked;
      const isNew = !company;
      company = { ...company, name, note, vertical, isDataCapEnabled, canCreateCrawls };
      // Omit actions array from company before sending down the wire
      // because a company can have so many actions that the api request
      // fails because the data payload is too big
      const body = { companies: [omit(company, "actions")] };
      fetchApiRoute(isNew ? "/api/v1/companies" : `/api/v1/companies/${company.id}`, {
        method: isNew ? "POST" : "PUT",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        return response.json();
      }).then(response => {
        if (response.error) {
          alert("Error. " + response.error);
          return;
        }
        dispatch(receiveCompanies(response));
        if (isNew) {
          // Reset new company form
          // Note (gab): would probably be better to have controlled form
          form.reset();
          const newCompany = response.companies[0];
          dispatch(receiveNewCompanyId(newCompany.id));
        } else {
          // Remove edit form from page
          dispatch(startEditingCompany());
        }
      });
    },
    onDeleteCompany(event: MouseEvent<HTMLElement>, authToken: string) {
      event.preventDefault();
      if (!window.confirm("Are you sure you want to delete this company?")) {
        return;
      }
      const { id } = (event.currentTarget as any).dataset;
      fetchApiRoute(`/api/v1/companies/${id}`, {
        method: "DELETE",
        authToken,
      }).then(response => {
        if (response.ok) {
          window.location.reload();
        } else {
          response.json().then(parsedResp => {
            alert("Error. " + parsedResp.error);
          });
        }
      });
    },
  };
}

export const CompaniesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Companies);
