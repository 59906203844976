import { cloneDeep, keyBy, omit } from "lodash";
import {
  Action as ReduxAction,
  RECEIVE_ACTIONS,
  REMOVE_ACTION,
  UPDATE_ACTION,
  CHANGE_DATA_PULL_STATUS,
  CHANGE_DATA_CAP,
  START_EDITING_ACTION,
} from "../actions/action";
import { StoreState } from "../types/action";
import { blankAction } from "../helpers/action";


const initialState: StoreState = {
  // place to store edits on new (unsaved) action
  newAction: blankAction(),
  // place to store edits on existing (saved) action
  editingAction: blankAction(),
  // all the actions that the application has received during its lifetime
  actions: {}, // by id
  // the actions to display on a specific page at /actions
  forPage: [],
  page: 0,
  perPage: 1,
  total: 0,
  // has the application ever fetched all actions
  // i.e.: GET /api/v1/actions?shouldLimit=false
  hasFetchedAll: false,
  // place to store status of data pull for action, by id
  dataPullStatus: {},
  // The data caps page allows editing any action's data cap
  dataCapEdits: {},
};

export default function action(state: StoreState = initialState, action: ReduxAction): StoreState {
  switch (action.type) {
    // @ts-ignore
    case "ACTIONS":
      // Reset form when navigating to page
      return {
        ...state,
        editingAction: blankAction(),
      };
    case RECEIVE_ACTIONS: {
      const responseIsPaginated = action.meta.perPage != null;
      const { page, perPage, total, hasFetchedAll } = responseIsPaginated ? action.meta : state;
      return {
        ...state,
        actions: {
          ...state.actions,
          ...keyBy(action.payload, "id"),
        },
        forPage: responseIsPaginated ? action.payload : state.forPage,
        page,
        perPage,
        total,
        // the following logic ensures that we only change this value once
        hasFetchedAll: hasFetchedAll === true || state.hasFetchedAll,
      };
    }
    case REMOVE_ACTION: {
      const removeId = action.payload;
      return {
        ...state,
        actions: omit(state.actions, removeId),
      };
    }
    case UPDATE_ACTION:
      return {
        ...state,
        [action.meta.isNew ? "newAction" : "editingAction"]: action.payload,
      };
    case CHANGE_DATA_PULL_STATUS:
      return {
        ...state,
        dataPullStatus: {
          ...state.dataPullStatus,
          [action.payload.actionId]: action.payload,
        },
      };
    case CHANGE_DATA_CAP:
      return {
        ...state,
        dataCapEdits: {
          ...state.dataCapEdits,
          [action.payload.actionId]: action.payload.dataCap,
        },
      };
    case START_EDITING_ACTION: {
      const id = action.payload;
      const actionToEdit = state.actions[id];
      if (!actionToEdit) {
        throw new Error("Tried to edit action not in store");
      }
      if (id === state.editingAction.id) {
        // No-op: already editing.
        // Use update action if you wish to reset state.editingAction
        return state;
      }
      return {
        ...state,
        editingAction: cloneDeep(actionToEdit),
      };
    }
    default:
      return state;
  }
}
