import { Action, AUTHENTICATE, UNAUTHENTICATE } from "../actions/auth";
import { StoreState } from "../types/auth";

const initialState: StoreState = {
  authToken: "",
  role: null,
};

export default function auth(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        ...action.payload,
       };
    case UNAUTHENTICATE:
      return {
        ...state,
        authToken: "",
        role: null,
      };
    default:
      return state;
  }
}
