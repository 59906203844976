import { PostActionMatches, PamPageFilters } from "../components/post-action-matches";
import { connect, Dispatch } from "react-redux";
import { omit, sortBy } from "lodash";
import { StoreState } from "../types";
import {
  change,
  PostActionMatchQueryRequest,
  fetchPostActionMatches,
  markSaving,
  unmarkSaving,
} from "../actions/post-action-match";
import { PostActionMatch } from "@threatminder-system/tm-core";
import { goToPage } from "../actions/page";
import { fetchApiRoute } from "../helpers/fetch";

function adjustPostActionMatch(postActionMatch: PostActionMatch) {
  // Optimization/bug fix: reduce PUT payload by omitting read-only post
  // info
  const mungedPostActionMatch = omit(postActionMatch, "post");

  // Hitting save without checking the confirmed threat checkbox
  // is equivalent to declaring that the post action match is NOT
  // a confirmed threat
  if (mungedPostActionMatch.isConfirmedThreat !== true) {
    mungedPostActionMatch.isConfirmedThreat = false;
  }

  return mungedPostActionMatch;
}

function mapStateToProps(state: StoreState) {
  return {
    authToken: state.auth.authToken,
    role: state.auth.role,
    posts: state.postActionMatch.postActionMatches,
    companies: state.company.companies,
    platforms: state.platform.platforms,
    actions: sortBy(Object.values(state.action.actions), "targetName"),
    perPage: state.postActionMatch.perPage,
    total: state.postActionMatch.total,
    filters: {
      page: state.postActionMatch.page,
      sortBy: state.postActionMatch.sortBy,
      sortByOrder: state.postActionMatch.sortByOrder,
      companyIds: state.postActionMatch.companyIds,
      excludeCompany: state.postActionMatch.excludeCompany,
      actionIds: state.postActionMatch.actionIds,
      excludeAction: state.postActionMatch.excludeAction,
      selectedPlatformIds: state.postActionMatch.selectedPlatformIds,
    },
    hasLoaded: state.postActionMatch.hasLoaded,
    saving: state.postActionMatch.saving,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onSubmitOne: async (postActionMatch: PostActionMatch, pq: PostActionMatchQueryRequest): Promise<void> => {
      dispatch(markSaving([postActionMatch.id]));

      const adjPostActionMatch = adjustPostActionMatch(postActionMatch);

      const response = await fetchApiRoute(`/api/v1/post-action-matches/${postActionMatch.id}`, {
        method: "PUT",
        authToken: pq.authToken,
        body: JSON.stringify(adjPostActionMatch),
      });

      if (!response.ok) {
        const errMsg = await response.text();
        window.alert(errMsg);
      }

      await fetchPostActionMatches(dispatch, pq);
      dispatch(unmarkSaving([postActionMatch.id]));
    },

    onSubmitMany: async (postActionMatches: PostActionMatch[], pq: PostActionMatchQueryRequest): Promise<void> => {
      dispatch(markSaving(postActionMatches.map(({ id }) => id)));
      const adjPostActionMatches = postActionMatches.map(adjustPostActionMatch);

      const response = await fetchApiRoute("/api/v1/post-action-matches", {
        method: "PUT",
        authToken: pq.authToken,
        body: JSON.stringify(adjPostActionMatches),
      });

      const respJson = await response.json();

      if (respJson && respJson.errors && respJson.errors.length) {
        let errMsg = "Error saving the following posts: \n";
        for (const err of respJson.errors) {
          errMsg += `PostActionMatchId ${err.postActionMatchId}, ${err.error} \n`;
        }
        window.alert(errMsg);
      }

      await fetchPostActionMatches(dispatch, pq);
      dispatch(unmarkSaving(postActionMatches.map(({ id }) => id)));
    },

    onChangeOne: (postActionMatch: PostActionMatch) => {
      dispatch(change(postActionMatch));
    },

    onChangeFilters: ({
      sortBy,
      sortByOrder,
      page,
      companyIds,
      excludeCompany,
      actionIds,
      excludeAction,
      selectedPlatformIds,
    }: PamPageFilters) => {
      const query: any = {
        sortBy: sortBy,
        sortByOrder: sortByOrder,
      };

      [
        [companyIds, "companyId", excludeCompany, "excludeCompany"],
        [actionIds, "actionId", excludeAction, "excludeAction"]
      ].forEach((
        [ids, qsIdKey, exclude, qsExcludeKey]: any
      ) => {
        // Do not put ids in the query string in the
        // address bar unless there are actually ids
        if (ids.length > 0) {
          query[qsIdKey] = ids;
          query[qsExcludeKey] = exclude;
        } else if (exclude === true) {
          // Don't put exclude in the address bar query string
          // unless the value is true (absent = false)
          query[qsExcludeKey] = exclude;
        }
      });

      if (selectedPlatformIds.length > 0) {
        query.platformId = selectedPlatformIds;
      }

      dispatch(goToPage(
        "POST_ACTION_MATCHES",
        { page: page },
        { query: query }
      ));
    }
  };
}

export const PostActionMatchesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostActionMatches);
