import { DomainCrawl } from "@threatminder-system/tm-core";
import * as React from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { fetchApiRoute } from "../helpers/fetch";
import "react-csv-importer/dist/index.css";

type ScanCsvRow = {
  userId: string,
  businessName: string,
  domain: string,
  businessStreet: string,
  businessCity: string,
  businessState: string,
  businessZip: string,
  businessCountry: string,
  badWords: string,
  firstName: string,
  middleInitial: string,
  lastName: string,
  zipCode: string,
  dateOfBirth: string,
  socialSecurityNumber: string,
};

type NewDomainCrawl = Omit<DomainCrawl, "uuid" | "id" | "isDeleted" | "status" | "complete" | "rawData" | "report">;

const timer = (ms: any) => new Promise( res => setTimeout(res, ms));

async function postCrawl(crawl: NewDomainCrawl, authToken: string) {
  const response = await fetchApiRoute(`/api/v1/domain-crawls`, {
    method: "POST",
    authToken,
    body: JSON.stringify({ domainCrawls: [crawl] }),
  });
  return response;
}

function validateCsvRow(row: any, rowIndex: number) {
  const errors = [];
  const rowNumber = rowIndex + 1;
  if (!row.userId) {
    errors.push(`Row ${rowNumber} is missing required field User Id`);
  }
  if (!row.businessName) {
    errors.push(`Row ${rowNumber} is missing required field Scan Name`);
  }
  if (!row.domain) {
    errors.push(`Row ${rowNumber} is missing required field Website Domain`);
  }
  if (!row.badWords) {
    errors.push(`Row ${rowNumber} is missing required field Badwords`);
  }
  // if any of the person data is included
  if (row.firstName || row.lastName || row.middleInitial || row.dateOfBirth || row.zipCode || row.socialSecurityNumber) {
    // then first name, last name, and either DOB or SSN is require
    if (!row.firstName) {
      errors.push(`Row ${rowNumber} is missing First Name. Either supply this value or delete all person-related values (name, date of birth, US zip, social security).`);
    }
    if (!row.lastName) {
      errors.push(`Row ${rowNumber} is missing Last Name. Either supply this value or delete all person-related values (name, date of birth, US zip, social security).`);
    }
    if (!row.dateOfBirth && !row.socialSecurityNumber) {
      errors.push(`Row ${rowNumber} needs either a Date of Birth or Social Security Number. Either supply this value or delete all person-related values (name, date of birth, US zip, social security).`);
    }
  }
  return errors;
}

type ErrorRow = {
  row: any,
  index: number,
  errors: string[],
};

function collectValidationErrors(rows: any[]) {
  const errorRows: ErrorRow[] = [];
  rows.forEach((row: any, index: number) => {
    const errors = validateCsvRow(row, index);
    if (errors.length) {
      errorRows.push({
        row,
        index,
        errors
      });
    }
  });
  return errorRows;
}

function rowToCrawl(row: ScanCsvRow): NewDomainCrawl {
  const {
    userId,
    businessName,
    domain,
    businessStreet,
    businessCity,
    businessState,
    businessZip,
    businessCountry,
    badWords,
    firstName,
    middleInitial,
    lastName,
    zipCode,
    dateOfBirth,
    socialSecurityNumber
  } = row;
  const crawl = {
    userId: Number(userId),
    isScanAndMonitoring: false,
    domain,
    expectations: {
      businessName,
      businessStreet,
      businessCity,
      businessState,
      businessZip,
      businessCountry,
      badWords: badWords.split(","),
      maxSearchPages: 25,
    },
    personCrawls: row.lastName ? [{
      expectations: {
        firstName,
        middleInitial,
        lastName,
        zipCode,
        dateOfBirth,
        socialSecurityNumber,
      }
    }]
      : [],
  };
  return crawl;
}


type Props = {
  authToken: string,
};

type State = {
  isServerError: boolean,
  errorRows: ErrorRow[]
};
export class CrawlerServiceCsvUpload extends React.Component<Props, State> {
  state: State = {
    isServerError: false,
    errorRows: [],
  };

  clearErrors() {
    this.setState({
      isServerError: false,
      errorRows: []
    });
  }

  async submit(rows: ScanCsvRow[]) {
    const { authToken } = this.props;
    const errorRows: ErrorRow[] = [];
    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      const crawl = rowToCrawl(row);
      const response = await postCrawl(crawl, authToken);
      await timer(2500);
      if (!response.ok) {
        errorRows.push({
          row,
          index,
          errors: [`Row ${index + 1}: unable to create scan. The API returned an error. See console in browser for more info.`]
        });
        console.log(`Row ${index + 1} network error`);
        console.log(await response.text());
      }
    }
    if (errorRows.length) {
      this.setState({ isServerError: true });
      this.setState({ errorRows });
    }
  }

  render() {
    const { errorRows } = this.state;
    return (
      <div>
        {Boolean(errorRows.length) ? (
          <div className="alert alert-danger">
            <ul>
              {errorRows.map(errorRow => {
                const { errors, index: rowIndex } = errorRow;
                return (
                  <li key={rowIndex}>
                    Row {rowIndex + 1} had errors
                    <ul>
                      {errors.map((errorMessage, errorMessageIndex) => {
                        return <li key={errorMessageIndex}>{errorMessage}</li>;
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
            <br />
            {this.state.isServerError ?
              <p>Look carefully at the rows that failed. If you spot an error, fix it then try uploading just the rows that failed.</p>
              : <p>Fix the errors in the csv, then upload the fixed file.</p>

            }
            <p>
              <button
                onClick={() => {
                  this.clearErrors();
                }}
              >
                Restart
              </button>
            </p>
          </div>
        )
          : (
            <Importer
              assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
              restartable={true} // optional, lets user choose to upload another file when import is complete
              processChunk={(rows: any[], { startIndex }) => {
                this.clearErrors();
                const errorRows = collectValidationErrors(rows);
                if (errorRows.length) {
                  this.setState({ errorRows });
                  return;
                }
                this.submit(rows);
              }}
              quoteChar={'"'}
            >
              <ImporterField name="userId" label="User Id" />
              <ImporterField name="businessName" label="Scan Name" />
              <ImporterField name="domain" label="Website Domain" />
              <ImporterField name="businessStreet" label="Street Address" optional={true} />
              <ImporterField name="businessCity" label="City" optional={true} />
              <ImporterField name="businessState" label="State" optional={true} />
              <ImporterField name="businessCountry" label="Country Code (2-letter)" optional={true} />
              <ImporterField name="businessZip" label="Zip Code" optional={true} />
              <ImporterField name="badWords" label="Badwords" />
              <ImporterField name="firstName" label="First Name" optional={true} />
              <ImporterField name="middleInitial" label="Middle Initial" optional={true} />
              <ImporterField name="lastName" label="Last Name" optional={true} />
              <ImporterField name="dateOfBirth" label="Date of Birth" optional={true} />
              <ImporterField name="zipCode" label="US Zip Code" optional={true} />
              <ImporterField name="socialSecurityNumber" label="Social Security Number" optional={true} />
            </Importer>
          )
        }
      </div>
    );
  }
}
