import { cloneDeep } from "lodash";
import {
  Action,
  RECEIVE_FILTERS,
  REMOVE_FILTER,
  UPDATE_FILTER,
  START_EDITING_FILTER,
} from "../actions/filter";
import { StoreState } from "../types/filter";
import { blankFilter } from "../helpers/filter";
import { receiveMerge } from "../helpers/reducers";

const initialState: StoreState = {
  // place to store edits on new (unsaved) filter
  newFilter: blankFilter(),
  // place to store edits on existing (saved) filter
  editingFilter: blankFilter(),
  filters: [],
  page: 0,
  perPage: 1,
  total: 0,
};

export default function filter(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_FILTERS: {
      const { mergeMethod, page, perPage, total } = action.meta;
      return {
        ...state,
        filters: mergeMethod === "REPLACE" ?
          action.payload :
          receiveMerge(state.filters, action),
        page,
        perPage,
        total,
      };
    }
    case REMOVE_FILTER: {
      const removeId = action.payload;
      return {
        ...state,
        filters: state.filters.filter(filter => filter.id !== removeId),
      };
    }
    case UPDATE_FILTER:
      return {
        ...state,
        [action.meta.isNew ? "newFilter" : "editingFilter"]: action.payload,
      };
    case START_EDITING_FILTER: {
      const id = action.payload;
      const filterToEdit = state.filters.find(filter => filter.id === id);
      if (!filterToEdit) {
        throw new Error("Tried to edit filter not in store");
      }
      if (id === state.editingFilter.id) {
        // No-op: already editing.
        // Use update action if you wish to reset state.editingFilter
        return state;
      }
      return {
        ...state,
        editingFilter: cloneDeep(filterToEdit),
      };
    }
    default:
      return state;
  }
}
