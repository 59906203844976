import { Action, RECEIVE_DATASETS } from "../actions/dataset";
import { StoreState } from "../types/dataset";

const initialState: StoreState = {
  datasets: []
};

export default function auth(state: StoreState = initialState, action: Action): StoreState {
  switch (action.type) {
    case RECEIVE_DATASETS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
