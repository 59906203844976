import * as React from "react";
import Link from "redux-first-router-link";

interface Props {
  authToken: string;
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    authToken: string,
    syncPath: string
  ) => Promise<void>;
}

export const SyncSheets: React.FunctionComponent<Props> = (props: Props) => {
  const { authToken, onSubmit } = props;

  return (
    <div>
      <div className="page-header">
        <h1>Sync Database with Google Spreadsheets</h1>
      </div>

      <SheetSyncForm
        title="Risk Keywords"
        slug="risk-keywords"
        defaultSpreadSheetId="1n5BHVyAMdwk56RUS3mnLcQ0hTL2D2BnRjobYIdt6sbo"
        defaultRange="A3:AA989"
        onSubmit={(event) =>
          onSubmit(event, authToken, "/api/v1/risk-keyword-sets/sync")
        }
      >
        <h4>Gabriel's slack chat with Matt about how syncing works:</h4>
        <p>
          Every keyword in common will get copied to every NON-EMPTY keyword
          set. That means, it will be copied into the default keyword set, which
          means that a keyword in common becomes a keyword for every single
          keyword set.
        </p>
        <p>
          So if, for example, you delete “assault” from default, but not from
          common, it’s as if you never deleted it. On the other hand, if you
          delete “assault” from common but leave it in default it will become a
          keyword only for those keyword sets that are empty when you sync the
          sheet.
        </p>
        <p>
          So, in short, common keywords really are “common” to all keyword sets.
          Default keywords only apply to empty sets. An empty set = common +
          default. A non-empty set = common + whatever is in the set.
        </p>
      </SheetSyncForm>

      <SheetSyncForm
        title="Local Social Accounts"
        slug="local-social-accounts"
        defaultSpreadSheetId="1_G3-ab9up_oiI7gO3WCmAczgqKSoVx0Ql1x6xAdrF5A"
        defaultRange="A2:AA999" // AA999 = room to grow
        preview="LOCAL_SOCIAL_ACCOUNTS"
        onSubmit={(event) =>
          onSubmit(event, authToken, "/api/v1/local-social-accounts/sync")
        }
      >
        <h4>Troubleshooting</h4>
        <p>If you get an error and the sync fails...</p>
        <ul>
          <li>
            Make sure there are no duplicate (city, state) entries in the
            spreadsheet. For example, if New York, New York appears twice in the
            spreadsheet, the server will reject it.
          </li>
        </ul>
      </SheetSyncForm>
    </div>
  );
};

type SheetSyncFormProps = {
  title: string;
  slug: string;
  defaultSpreadSheetId: string;
  defaultRange: string;
  preview?: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
};

const SheetSyncForm: React.StatelessComponent<SheetSyncFormProps> = (
  props: SheetSyncFormProps
) => {
  const {
    title,
    slug,
    defaultSpreadSheetId,
    defaultRange,
    preview,
    onSubmit,
    children,
  } = props;

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="list-group">
          <h2>{title}</h2>
          {preview && (
            <p>
              See what is{" "}
              <Link to={{ type: preview }}>currently in the database</Link>{" "}
              before syncing.
            </p>
          )}
          <h3>How to use this section</h3>
          <ol>
            <li>
              Prep. Go to{" "}
              <a
                href={`https://docs.google.com/spreadsheets/d/${defaultSpreadSheetId}/`}
              >
                Google spreadsheet
              </a>{" "}
              and make changes.
            </li>
            <li>
              Sync. When you're done making changes, hit the sync button below.
              If necessary, you can modify the spreadsheet id or data cell range
              before clicking the button.
            </li>
          </ol>

          {children && <div>{children}</div>}

          <br />

          <form onSubmit={(event) => onSubmit(event)}>
            <div className="form-group">
              <label className="control-label" htmlFor={`${slug}-id`}>
                Google Spreadsheet Id
              </label>
              <input
                type="text"
                className="form-control"
                id={`${slug}-id`}
                name="spreadsheetId"
                defaultValue={defaultSpreadSheetId}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor={`${slug}-range`}>
                Data cell range
              </label>
              <input
                type="text"
                className="form-control"
                id={`${slug}-range`}
                name="range"
                defaultValue={defaultRange}
              />
            </div>
            <button className="btn btn-lg btn-primary btn-submit">Sync</button>
          </form>
        </div>
      </div>
    </div>
  );
};
