// TODO: better type annotation
export function receiveMerge(list: any, action: any) {
  const newItems = action.payload.filter((received: any) =>
    !list.some((item: any) => item.id === received.id)
  );
  const updated = list.map((item: any) => {
    const match = action.payload.find(
      (received: any) => received.id === item.id
    );
    return match ? {...item, ...match} : item;
  });
  return [...newItems, ...updated];
}
