import * as React from "react";
import Link from "redux-first-router-link";
import { Action } from "@threatminder-system/tm-core";
import { Action as ActionForm } from "./action";
import { Paginator } from "./paginator";
import { DataPullStatus } from "../actions/action";
import ReactJson from "react-json-view";

import "./actions.css";

interface Props {
  authToken: string;
  actions: Action[];
  editingAction: Action;
  dataPullStatus: {
    [actionId: number]: DataPullStatus;
  };
  total: number;
  page: number;
  perPage: number;
  onUpdateAction: (updatedNewAction: Action) => void;
  onClickDeleteAction: (
    event: React.MouseEvent<HTMLElement>,
    authToken: string
  ) => void;
  onClickEditAction: (event: React.MouseEvent<HTMLElement>) => void;
  onClickPullAction: (
    event: React.MouseEvent<HTMLElement>,
    authToken: string
  ) => void;
  onSubmitAction: (
    event: React.MouseEvent<HTMLElement>,
    action: Action,
    authToken: string
  ) => void;
}

export const Actions: React.FunctionComponent<Props> = (props: Props) => {
  const {
    actions,
    editingAction,
    dataPullStatus,
    onClickDeleteAction,
    onClickEditAction,
    onClickPullAction,
    onUpdateAction,
    onSubmitAction,
    authToken,
    total,
    page,
    perPage,
  } = props;

  // Sometimes in the first render seems to return all the actions because of the async nature of the actions causing the issue of trying to render the dozens of thousands of actions crashing the page. We cap it at 5 to avoid this issue.
  const actionsToRender = actions.length > 5 ? actions.slice(0, 5) : actions;

  return (
    <div>
      <div className="page-header">
        <h1>Actions</h1>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="list-group">
            <div>Click on an action to edit it</div>
            <br />
            {actionsToRender.map((action, index) => {
              const { id, targetName, userId, companyId } = action;
              const isEditing = editingAction.id === id;
              const statusDataPull = dataPullStatus[id];
              const statusButtonClass = statusDataPull
                ? statusDataPull.ok
                  ? "btn-success"
                  : "btn-danger"
                : "btn-default";
              return (
                <div
                  key={`${id}-${index}`}
                  className={`list-group-item click-to-edit-action ${
                    isEditing && "is-editing-action"
                  }`}
                  data-id={id}
                  onClick={onClickEditAction}
                >
                  <button
                    data-id={id}
                    className="btn btn-danger pull-right"
                    onClick={(event) => onClickDeleteAction(event, authToken)}
                  >
                    <span
                      id="trashIcon"
                      className="glyphicon glyphicon-trash"
                      aria-hidden="true"
                    />
                  </button>
                  <div className="list-group-item-title">Id: {id} </div>
                  <div className="list-group-item-title">
                    Name: {targetName}
                  </div>
                  <div className="list-group-item-title">
                    <Link to={`/users/${userId}`}>User Id: {userId}</Link>
                  </div>
                  <div className="list-group-item-title">
                    <Link to={`/company/${companyId}`}>
                      Company Id: {companyId}
                    </Link>
                  </div>

                  <ReactJson src={action} />

                  <div>
                    <button
                      data-id={id}
                      className={`btn ${statusButtonClass} data-pull-button`}
                      onClick={(event) => onClickPullAction(event, authToken)}
                    >
                      Data Pull
                    </button>
                    {statusDataPull && (
                      <span className="data-pull-status-message">
                        {statusDataPull.ok
                          ? "Data pull sent!"
                          : "Could not issue data pull. Try again?"}
                      </span>
                    )}
                  </div>
                  {isEditing && (
                    <div key={id} className="row form-container">
                      <ActionForm
                        action={editingAction}
                        onUpdateAction={onUpdateAction}
                      />
                      <SaveButton
                        onClick={(event) =>
                          onSubmitAction(event, editingAction, authToken)
                        }
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          pageType="ACTIONS"
          getPageQuery={(pageNum) => ({ page: pageNum, perPage })}
        />
      </div>
    </div>
  );
};

const SaveButton = (props: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <div className="form-group">
    <button
      className="btn btn-lg btn-primary btn-submit pull-right"
      onClick={props.onClick}
    >
      Save
    </button>
  </div>
);
