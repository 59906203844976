import { NOT_FOUND } from "redux-first-router";
import { Action } from "../actions/page";
import { StoreState } from "../types/page";

const components: { [type: string]: string } = {
  LOGIN: "Login",
  UPLOAD: "Upload",
  ACTIONS: "Actions",
  FILTERS: "Filters",
  COMPANIES: "Companies",
  COMPANY: "Company",
  DATA_CAPS: "DataCaps",
  EMAIL_CAMPAIGNS: "EmailCampaigns",
  PLATFORMS: "Platforms",
  POST_ACTION_MATCHES: "PostActionMatches",
  POST_ACTION_MATCHES_HISTORY: "PostActionMatchesHistory",
  SYNC_SHEETS: "SyncSheets",
  LOCAL_SOCIAL_ACCOUNTS: "LocalSocialAccounts",
  RISK_KEYWORD_SETS_SYNCED: "RiskKeywordSets",
  USERS: "Users",
  [NOT_FOUND]: "NotFound",
};

/**
 * Reducer function for managing the state of the page in the application.
 *
 * @param state - The current state of the page.
 * @param action - The action object that describes the state change.
 * @returns The new state of the page.
 */
const pageReducer = (
  state: StoreState = "Home",
  action: Action = {}
): StoreState => components[action.type] || state;

export default pageReducer;
