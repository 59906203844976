import { Filter } from "@threatminder-system/tm-core";

export type Action =
  | ReceiveFiltersAction
  | RemoveFilterAction
  | UpdateFilterAction
  | StartEditingFilterAction;

export const RECEIVE_FILTERS = "filter/receiveMany";
export type RECEIVE_FILTERS_TYPE = typeof RECEIVE_FILTERS;

export const REMOVE_FILTER = "filter/remove";
export type REMOVE_FILTER_TYPE = typeof REMOVE_FILTER;

export const UPDATE_FILTER = "filter/update";
export type UPDATE_FILTER_TYPE = typeof UPDATE_FILTER;

export const START_EDITING_FILTER = "filter/startEditing";
export type START_EDITING_FILTER_TYPE = typeof START_EDITING_FILTER;

type MergeMethod = "MERGE" | "REPLACE";

interface ReceiveFiltersAction {
  type: RECEIVE_FILTERS_TYPE;
  payload: Filter[];
  meta: {
    mergeMethod: MergeMethod;
    page: number;
    perPage: number;
    total: number;
  };
}

interface RemoveFilterAction {
  type: REMOVE_FILTER_TYPE;
  payload: number;
}

export function receive(
  response: {
    filters: Filter[];
    page: number;
    perPage: number;
    total: number;
  },
  mergeMethod: MergeMethod = "REPLACE"
): ReceiveFiltersAction {
  return {
    type: RECEIVE_FILTERS,
    payload: response.filters,
    meta: {
      mergeMethod,
      page: response.page,
      perPage: response.perPage,
      total: response.total,
    },
  };
}

export function remove(filterId: number): RemoveFilterAction {
  return {
    type: REMOVE_FILTER,
    payload: filterId,
  };
}

interface UpdateFilterAction {
  type: UPDATE_FILTER_TYPE;
  payload: Filter;
  meta: {
    isNew: boolean;
  };
}

export function updateFilter(
  payload: Filter,
  meta?: { isNew: boolean }
): UpdateFilterAction {
  return {
    type: UPDATE_FILTER,
    payload: payload,
    meta: {
      isNew: Boolean(meta && meta.isNew),
    },
  };
}

interface StartEditingFilterAction {
  type: START_EDITING_FILTER_TYPE;
  payload: Number;
}

export function startEditingFilter(payload: Number): StartEditingFilterAction {
  return {
    type: START_EDITING_FILTER,
    payload,
  };
}
