import { EmailCampaign } from "@threatminder-system/tm-core";
import { EmailCampaigns } from "../components/email-campaigns";
import { connect, Dispatch } from "react-redux";
import { MouseEvent } from "react";
import { StoreState } from "../types";
import {
  receive as receiveEmailCampaigns,
  updateEmailCampaign,
  startEditingEmailCampaign,
  startNewEmailCampaign,
  clearEmailCampaign,
} from "../actions/email-campaign";
import { fetchApiRoute } from "../helpers/fetch";


function mapStateToProps(state: StoreState) {
  return {
    authToken: state.auth.authToken,
    newEmailCampaign: state.emailCampaign.newEmailCampaign,
    editingEmailCampaign: state.emailCampaign.editingEmailCampaign,
    emailCampaigns: Object.values(state.emailCampaign.emailCampaigns),
    companies: state.company.companies,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    onUpdateEmailCampaign(updatedEmailCampaign: EmailCampaign) {
      dispatch(updateEmailCampaign(updatedEmailCampaign, { isNew: !updatedEmailCampaign.id }));
    },

    onClickDeleteEmailCampaign(id: number, authToken: string) {
      const yes = window.confirm("Are you sure you want to delete this email campaign?");
      if (!yes) {
        return;
      }
      fetchApiRoute(`/api/v1/email-campaigns/${id}`, {
        method: "DELETE",
        authToken,
      }).then(response => {
        if (response.ok) {
          // Deleting an email campaign can cascade to deletions of other models,
          // so it's best to reload the whole application to ensure it is
          // synced with the server state
          window.location.reload();
        } else {
          response.json().then(obj => {
            window.alert(`Could not delete email campaign:\n ${obj.error}`);
          });
        }
      });
    },

    onClickEditEmailCampaign(event: MouseEvent<HTMLElement>) {
      const { currentTarget: { dataset: { id } } } = event;
      dispatch(startEditingEmailCampaign(Number(id)));
    },

    onClickResumeEmailCampaign(id: number, authToken: string) {
      const body = { id };
      return fetchApiRoute("/api/v1/email-campaigns/resume", {
        method: "POST",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
          response.json().then(updatedEmailCampaign => {
            dispatch(receiveEmailCampaigns({ emailCampaigns: [updatedEmailCampaign] }));
          });
        } else {
          response.json().then(obj => {
            window.alert(`Could not resume email campaign:\n ${obj.error}`);
          });
        }
      });
    },

    onClickSuspendEmailCampaign(id: number, authToken: string) {
      const body = { id };
      return fetchApiRoute("/api/v1/email-campaigns/suspend", {
        method: "POST",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
          response.json().then(updatedEmailCampaign => {
            dispatch(receiveEmailCampaigns({ emailCampaigns: [updatedEmailCampaign] }));
          });
        } else {
          response.json().then(obj => {
            window.alert(`Could not suspend email campaign:\n ${obj.error}`);
          });
        }
      });
    },

    onClickStartNewEmailCampaign() {
      dispatch(startNewEmailCampaign());
    },

    onSubmitEmailCampaign(event: MouseEvent<HTMLElement>, emailCampaign: Partial<EmailCampaign>, authToken: string) {
      event.preventDefault();
      const isNew = !emailCampaign.id;
      const body = emailCampaign;
      fetchApiRoute(isNew ? `/api/v1/email-campaigns` : `/api/v1/email-campaigns/${emailCampaign.id}`, {
        method: isNew ? "POST" : "PUT",
        authToken,
        body: JSON.stringify(body),
      }).then(response => {
        if (response.ok) {
          response.json().then((obj: EmailCampaign) => {
            window.alert("Success");
            dispatch(receiveEmailCampaigns({emailCampaigns: [obj]}));
            // clear form fields after save
            dispatch(clearEmailCampaign(isNew));
          });
        } else {
          response.json().then(obj => {
            window.alert(`Could not save email campaign:\n ${obj.error}`);
          });
        }
      });
    }
  };
}

export const EmailCampaignsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailCampaigns);
