import * as React from "react";
import { JWT_EXPIRES_IN } from "../../shared-constants";
import "./login.css";


interface Props {
    onSubmit: (form: HTMLFormElement) => Promise<void>;
}

export const Login: React.FunctionComponent<Props> = (props: Props) => {
    let form: HTMLFormElement;
    return (
        <div>
            <div className="page-header">
                <h1 className="text-center">ThreatMinder Portal</h1>
            </div>
            <form
                className="form-signin"
                ref={el => form = el}
                onSubmit={event => {
                    event.preventDefault();
                    props.onSubmit(form);
                }}
            >
                <input className="form-control" type="text" name="username" placeholder="Username" />
                <input className="form-control" type="password" name="password" placeholder="Password" />
                <button className="btn btn-lg btn-primary btn-block">Login</button>
            </form>
            <p className="text-center">Note: login expires in {JWT_EXPIRES_IN}, at which point you have to sign back in.</p>
        </div>
    );
};
