import { EmailCampaign } from "@threatminder-system/tm-core";

export type Action =
  | ReceiveEmailCampaignsAction
  | RemoveEmailCampaignAction
  | UpdateEmailCampaignAction
  | StartEditingEmailCampaignAction
  | StartNewEmailCampaignAction
  | ClearEmailCampaignAction;

export const RECEIVE_EMAIL_CAMPAIGNS = "emailCampaign/receiveMany";
export type RECEIVE_EMAIL_CAMPAIGNS_TYPE = typeof RECEIVE_EMAIL_CAMPAIGNS;

export const REMOVE_EMAIL_CAMPAIGN = "emailCampaign/remove";
export type REMOVE_EMAIL_CAMPAIGN_TYPE = typeof REMOVE_EMAIL_CAMPAIGN;

export const UPDATE_EMAIL_CAMPAIGN = "emailCampaign/update";
export type UPDATE_EMAIL_CAMPAIGN_TYPE = typeof UPDATE_EMAIL_CAMPAIGN;

export const START_EDITING_EMAIL_CAMPAIGN = "emailCampaign/startEditing";
export type START_EDITING_EMAIL_CAMPAIGN_TYPE =
  typeof START_EDITING_EMAIL_CAMPAIGN;

export const START_NEW_EMAIL_CAMPAIGN = "emailCampaign/startNewEmailCampaign";
export type START_NEW_EMAIL_CAMPAIGN_TYPE = typeof START_NEW_EMAIL_CAMPAIGN;

export const CLEAR_EMAIL_CAMPAIGN = "emailCampaign/clearEmailCampaign";
export type CLEAR_EMAIL_CAMPAIGN_TYPE = typeof CLEAR_EMAIL_CAMPAIGN;

interface ReceiveEmailCampaignsAction {
  type: RECEIVE_EMAIL_CAMPAIGNS_TYPE;
  payload: EmailCampaign[];
}

interface RemoveEmailCampaignAction {
  type: REMOVE_EMAIL_CAMPAIGN_TYPE;
  payload: number;
}

export function receive(response: {
  emailCampaigns: EmailCampaign[];
}): ReceiveEmailCampaignsAction {
  return {
    type: RECEIVE_EMAIL_CAMPAIGNS,
    payload: response.emailCampaigns,
  };
}

export function remove(emailCampaignId: number): RemoveEmailCampaignAction {
  return {
    type: REMOVE_EMAIL_CAMPAIGN,
    payload: emailCampaignId,
  };
}

interface UpdateEmailCampaignAction {
  type: UPDATE_EMAIL_CAMPAIGN_TYPE;
  payload: Partial<EmailCampaign>;
  meta: {
    isNew: boolean;
  };
}

export function updateEmailCampaign(
  payload: Partial<EmailCampaign>,
  meta?: { isNew: boolean }
): UpdateEmailCampaignAction {
  return {
    type: UPDATE_EMAIL_CAMPAIGN,
    payload: payload,
    meta: {
      isNew: Boolean(meta && meta.isNew),
    },
  };
}

interface StartEditingEmailCampaignAction {
  type: START_EDITING_EMAIL_CAMPAIGN_TYPE;
  payload: number;
}

export function startEditingEmailCampaign(
  payload: number
): StartEditingEmailCampaignAction {
  return {
    type: START_EDITING_EMAIL_CAMPAIGN,
    payload,
  };
}

interface StartNewEmailCampaignAction {
  type: START_NEW_EMAIL_CAMPAIGN_TYPE;
}

export function startNewEmailCampaign(): StartNewEmailCampaignAction {
  return {
    type: START_NEW_EMAIL_CAMPAIGN,
  };
}

interface ClearEmailCampaignAction {
  type: CLEAR_EMAIL_CAMPAIGN_TYPE;
  meta: {
    isNew: boolean;
  };
}

export function clearEmailCampaign(isNew?: boolean): ClearEmailCampaignAction {
  return {
    type: CLEAR_EMAIL_CAMPAIGN,
    meta: {
      isNew: Boolean(isNew),
    },
  };
}
