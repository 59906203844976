import * as React from "react";
import { Company } from "@threatminder-system/tm-core";

import "./companies.css";
import { Paginator } from "./paginator";

interface Props {
  companies: Company[];
  editingCompany: Company;
  authToken: string;
  onClickCompany: React.MouseEventHandler<HTMLElement>;
  onDeleteCompany: (
    event: React.MouseEvent<HTMLElement>, authToken: string
  ) => any;
  onSubmitCompanyForm: (
    event: React.FormEvent<HTMLFormElement>,
    authToken: string,
    editingCompany?: Company,
  ) => any;
  page: number;
  perPage: number;
  total: number;
  newCompanies: Company[];
}

export const Companies: React.FunctionComponent<Props> = (props: Props) => {
  const {
    companies, editingCompany, authToken,
    onClickCompany,
    onSubmitCompanyForm,
    page,
    perPage,
    total,
    newCompanies,
  } = props;
  return (
    <div className="companies-container">
      <div className="page-header">
        <h1>Companies</h1>
      </div>
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row form-container">
            <h4> Add Company <br /><small>Enter a company name to get started</small> </h4>
            <CompanyForm onSubmit={event => onSubmitCompanyForm(event, authToken)} />
          </div>

          {page === 1 && newCompanies.length > 0 && (
            <div className="list-group">
              <h4><small>Companies you created this session</small></h4>
              {newCompanies.map(company => (
                <CompanyListItem
                  key={company.id}
                  company={company}
                  authToken={authToken}
                  editingCompany={editingCompany}
                  onClickCompany={onClickCompany}
                  onSubmitCompanyForm={onSubmitCompanyForm}
                />
              ))}
            </div>
          )}

          <div className="list-group">
            <h4><small>Select a company name to configure</small></h4>
            {companies.map(company => (
              <CompanyListItem
                key={company.id}
                company={company}
                authToken={authToken}
                editingCompany={editingCompany}
                onClickCompany={onClickCompany}
                onSubmitCompanyForm={onSubmitCompanyForm}
              />
            ))}
          </div>
        </div>

        <Paginator
          page={page}
          perPage={perPage}
          total={total}
          pageType="COMPANIES"
          getPageQuery={pageNum => ({page: pageNum, perPage})}
        />

      </div>
    </div>
  );
};

type CompanyListItemProps = {
  company: Company,
  authToken: Props["authToken"],
  editingCompany: Props["editingCompany"],
  onClickCompany: Props["onClickCompany"],
  onSubmitCompanyForm: Props["onSubmitCompanyForm"]
};

const CompanyListItem = (props: CompanyListItemProps) => {
  const {
    authToken,
    editingCompany,
    onClickCompany,
    onSubmitCompanyForm,
    company,
  } = props;
  const { id, name, note } = company;
  const isEditing = editingCompany && editingCompany.id === id;
  return (
    <div
      key={id}
      className="list-group-item"
      data-id={id}
      onClick={isEditing ? null : onClickCompany}
    >
      <span className="list-group-item-title">
        {name}{" "}
        [<span title="company id">{id}</span>]
      </span>
      <div className="company-note">
        {note}
        </div>
      {/* <button
          id="deleteCompanyBtn"
          data-id={id}
          className="btn btn-danger pull-right"
          onClick={event => onDeleteCompany(event, authToken)}
          >
          <span id="trashIcon" className="glyphicon glyphicon-trash" aria-hidden="true" />
          </button> */}

      {isEditing && (
        <div className="row form-container">
          <CompanyForm
            company={editingCompany}
            onSubmit={event => onSubmitCompanyForm(event, authToken, editingCompany)}
          />
        </div>
      )}

    </div>
  );
};

const CompanyForm = (props: {
  company?: Company,
  onSubmit: React.FormEventHandler<HTMLFormElement>,
}) => {
  const {
    company,
    onSubmit,
  } = props;

  const submitButtonLabel = company ? "Submit" : (
      <span>
        <span className="glyphicon glyphicon-plus" aria-hidden="true" />
        "Add Company"
      </span>
    );

  const isDataCapEnabled = Boolean(company && company.isDataCapEnabled);
  // TODO: remove "as any" cast below once tm-core has been updated
  const canCreateCrawls = Boolean(company && (company as any).canCreateCrawls);

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <input
          className="form-control"
          type="text"
          name="company-name"
          placeholder="Company name"
          defaultValue={(company && company.name) || ""}
        />
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          name="company-note"
          placeholder="Notes"
          defaultValue={(company && company.note) || ""}
        />
        <p className="company-note-note form-control-note">
          Any newlines or spaces that you put in the notes field above will be preserved.
        </p>
      </div>

      {/* Vertical (industry) */}
      <div className="form-group">
        <label className="control-label" htmlFor="company-is-data-cap-enabled">Industry</label>
        <br />
        <select
          id="company-vertical"
          defaultValue={company && company.vertical}
        >
          <option>Aero/Defense</option>
          <option>Auto</option>
          <option>Chemical/Petrochemical</option>
          <option>Commercial Real Estate</option>
          <option>Cultural Institutions</option>
          <option>Education</option>
          <option>Financial Institutions</option>
          <option>Food/Beverage</option>
          <option>Government</option>
          <option>Healthcare</option>
          <option>Hotels & Resorts</option>
          <option>Logistics & Distribution</option>
          <option>Manufacturing & Industrial</option>
          <option>Residential</option>
          <option>Retail/Malls</option>
          <option>Shelter</option>
          <option>Tech/Media/Telecom</option>
          <option>Transportation</option>
          <option>Other</option>
          <option>Utilities</option>
        </select>
      </div>

      {/* Is Data Cap Enabled? */}
      <div className="form-group">
        <label className="control-label" htmlFor="company-is-data-cap-enabled">Is Data Cap Enabled?</label>
        <br />
        <input
          type="checkbox"
          className="form-control-inline"
          id="company-is-data-cap-enabled"
          defaultChecked={isDataCapEnabled}
        />
        <span className="form-control-note">Turning this on/off will only enable/disable those actions whose data cap state = <code>default</code>.</span>
      </div>

      {/* Feature flag: can company create crawls? */}
      <div className="form-group">
        <label className="control-label" htmlFor="company-can-create-crawls">Can create crawls?</label>
        <br />
        <input
          type="checkbox"
          className="form-control-inline"
          id="company-can-create-crawls"
          defaultChecked={canCreateCrawls}
        />
      </div>

      <div className="form-group">
        <button
          className="btn btn-primary btn-submit"
          type="submit"
          value="Submit"
        >
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};
